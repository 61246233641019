import { useMemo } from 'react'
import moment from 'moment-timezone'

import { MESSAGE_TIME_FORMAT } from '../../../../utils/constants'
import { Block, IconStar, Info } from '../../../../components/SvgIcon'

const PreviewChat = ({
  chatId,
  title,
  hasBlocked,
  lastMessage,
  unreadMessagesCount,
  onSelectChat,
  isAttention,
  isStared,
  customClassName = '',
}) => {
  const formattedMessageDate = useMemo(
    () => moment(lastMessage?.date).calendar(null, MESSAGE_TIME_FORMAT),
    [lastMessage?.date]
  )

  return (
    <div onClick={() => onSelectChat(chatId)} className={`innerPreviewChat ${customClassName}`}>
      <p className="userIcon userPreviewMessage">
        {title?.trim()[0]}
        <div className="iconsInfo">
          {isStared && <IconStar title="Starred" size={20} fill="var(--catalina-blue)" />}
          {isAttention && <Info title="Attention" size={20} fill="var(--catalina-blue)" />}
        </div>
      </p>
      <div className="infoPreviewChat">
        <div className="infoPreviewChatTop">
          <p className="infoPreviewChatUserName">{title}</p>
          <p className="infoPreviewChatDateMessage">{formattedMessageDate}</p>
        </div>

        <div className="innerPreviewLastMessage">
          <p className="infoPreviewChatLastMessage">{lastMessage?.text}</p>
          {unreadMessagesCount >= 1 && (
            <span className="infoPreviewChatNumberUnreadMessages">{unreadMessagesCount}</span>
          )}
        </div>
      </div>
      {hasBlocked && (
        <Block
          className="iconBlockMessages"
          size={20}
          fill="var(--persian-red)"
          title="The user has forbidden to send messages to them"
        />
      )}
    </div>
  )
}

export default PreviewChat
