import { useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { Tooltip, Whisper } from 'rsuite'

import { routes } from '../../../../router/routes'
import { ROLE_GUEST, SHOW_USER_MENU, USER_ROUTE_MENU } from '../../../../utils/constants'
import { switchAccountCompany } from '../../../../api/user/company'
import { setRoleUser } from '../../../../redux/actions/user'
import { setShowModalSupport, toggleDisplayUserMenu } from '../../../../redux/actions/ui'
import localStorage from '../../../../utils/localStorage'
import { clearSession, updateToken } from '../../../../utils/auth'
import { Exit, IconSupport } from '../../../SvgIcon'
import OrganizationSwitcher from '../../../OrganizationSwitcher'

import './styles.css'

const LeftSideBar = ({
  showMenu,
  setRoleUser,
  listCompanies,
  currentCompany,
  setShowModalSupport,
  toggleDisplayUserMenu,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const toggleMenu = useCallback(() => {
    toggleDisplayUserMenu()
    localStorage.setItem(SHOW_USER_MENU, !showMenu)
  }, [showMenu])

  const logout = useCallback(() => {
    clearSession()
    dispatch(setRoleUser(ROLE_GUEST))
    navigate(routes.userLogin)
  }, [])

  const mutationSwitchAdminCompany = useMutation({
    mutationKey: ['switchAdminCompany'],
    mutationFn: switchAccountCompany,
    onSuccess: async ({ data }) => {
      await updateToken(data?.newToken)
      toggleMenu()
      navigate(routes.userHome)
      setTimeout(() => window.location.reload(), 0)
    },
  })

  return (
    <section className={`leftSideBar ${!showMenu ? 'hide' : ''}`}>
      <div className="sideBarHeader">
        <button onClick={toggleMenu} className="btnToggleMenu cross">
          <span />
          <span />
          <span />
        </button>
        <OrganizationSwitcher
          orgList={listCompanies}
          selectedOrg={currentCompany}
          innerClassName="userOrgSwitcher"
          onSwitchOrg={mutationSwitchAdminCompany.mutate}
        />
      </div>
      {USER_ROUTE_MENU.map(({ route, text, Icon }) => (
        <Whisper
          disabled={showMenu}
          key={route}
          placement="autoHorizontal"
          trigger="hover"
          speaker={<Tooltip>{text}</Tooltip>}
        >
          <NavLink
            caseSensitive
            className={`itemMenu ${pathname === route ? 'itemMenuActive' : ''}`}
            to={route}
            onClick={() => (window.innerWidth <= 991 ? toggleMenu() : null)}
          >
            <Icon size={24} className="iconMenu" />
            <span>{text}</span>
          </NavLink>
        </Whisper>
      ))}
      <Whisper
        disabled={showMenu}
        placement="autoHorizontal"
        trigger="hover"
        speaker={<Tooltip>Support</Tooltip>}
      >
        <button
          onClick={() => {
            window.innerWidth <= 991 ? toggleMenu() : null
            setShowModalSupport(true)
          }}
          className="itemMenu"
        >
          <IconSupport size={24} className="iconMenu" />
          <span>Support</span>
        </button>
      </Whisper>
      <Whisper
        disabled={showMenu}
        placement="autoHorizontal"
        trigger="hover"
        speaker={<Tooltip>Log out</Tooltip>}
      >
        <button onClick={logout} className="itemMenu mt-auto">
          <Exit size={24} className="iconMenu" />
          <span>Log out</span>
        </button>
      </Whisper>
    </section>
  )
}
const mapStateToProps = ({ ui, currentCompany }) => ({
  showMenu: ui.showUserMenu,
  currentCompany,
})
const mapDispatchToProps = {
  setRoleUser,
  setShowModalSupport,
  toggleDisplayUserMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar)
