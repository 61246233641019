import { find } from 'lodash'
import CustomDropdown from '../../CustomDropdown'

const RatingScaleQuestionBody = ({
  nextQuestionId,
  dropdownNextMessages,
  onChangeQuestionInfo,
}) => {
  const nextQuestion = find(dropdownNextMessages, { id: nextQuestionId })

  return (
    <div className="pb-2 border-bottom">
      <p>
        This SMS message requests the recipient to rate something on a scale of 1 to 5. The specific
        subject or topic to be rated may vary in different messages.
      </p>
      <CustomDropdown
        titleClass="dropzoneTitle"
        label="Select next question:"
        innerDropdown="innerDropdownOpenSurveyQuestion mt-3"
        value={nextQuestion}
        onChange={(value) => {
          onChangeQuestionInfo({ next_question_id: value.id })
        }}
        data={dropdownNextMessages}
        title="Select next way"
      />
    </div>
  )
}

export default RatingScaleQuestionBody
