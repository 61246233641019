import { IconUsers } from '../../../../../components/SvgIcon'

import './styles.css'

const GroupPreview = ({ memberCount, name, groupId, isActive }) => {
  return (
    <div className={`groupPreview ps-4 pe-4 pt-2 pb-2 ${isActive ? 'active' : ''}`}>
      <IconUsers size={18} style={{ minWidth: 18 }} fill="#000000" />
      <p className="name">{name}</p>
      <p className="memberCount">{memberCount}</p>
    </div>
  )
}

export default GroupPreview
