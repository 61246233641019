import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { URL_PHONE_PATTERN } from '../../constants'
import { camelize, formatPhoneNumber } from '../../helpers'

const useAutoInputPhone = (formRef) => {
  const [searchParams] = useSearchParams()
  const { phone } = camelize(Object.fromEntries(searchParams))

  useEffect(() => {
    const phoneFormatted = formatPhoneNumber(`+${phone?.replace(/^\+?/, '').trim()}`)

    if (URL_PHONE_PATTERN.test(phoneFormatted)) {
      formRef.current?.setValue('phone', phoneFormatted)
      formRef?.current?.submitHandler()
    }
  }, [])
}

export default useAutoInputPhone
