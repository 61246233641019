import { useEffect, useRef } from 'react'
import { omitBy } from 'lodash'
import { Modal } from 'react-bootstrap'

import { VCF_FIELDS } from '../../../utils/constantsForms'
import { getVcfContact, updateVcfCThemeContact } from '../../../api/admin/themes'
import { formatPhoneNumber, isNullOrEmpty } from '../../../utils/helpers'
import PrimaryButton from '../../PrimaryButton'
import HookForm from '../../HookForm'

import './styles.css'

const ModalContact = ({ show, onHide, themeId }) => {
  const formRef = useRef(null)

  const handleSubmit = (submitFormData) => {
    const formData = {
      ...submitFormData,
      themeId,
      image: submitFormData.contactImg.file,
    }
    const formattedData = omitBy(formData, isNullOrEmpty)

    updateVcfCThemeContact(formattedData)
      .then(() => formRef.current.reset(submitFormData))
      .then(onHide)
      .catch((error) => {
        const dataError = error?.response?.data
        Object.keys(dataError.data).map((field) => formRef.current?.setError(field, {}))
      })
  }

  const Footer = ({ formState }) => {
    return (
      <PrimaryButton
        text="Save Contact"
        disabled={!formState.isValid || !formState.isDirty}
        customClass="buttonSaveVcf"
      />
    )
  }

  useEffect(() => {
    if (show) {
      getVcfContact(themeId).then(({ data }) => {
        const fieldPhone = data?.phone ?? ''
        const formattedPhone = formatPhoneNumber(fieldPhone)

        formRef.current?.reset({
          ...data,
          phone: formattedPhone,
          contactImg: { imgUrl: `data:image/jpg;base64,${data.image}`, file: null },
        })
      })
    }
  }, [show])

  return (
    <Modal dialogClassName="dialogModalContact" centered show={show} onHide={onHide}>
      <HookForm
        onSubmit={handleSubmit}
        ref={formRef}
        className="formVcf"
        fields={VCF_FIELDS}
        Footer={Footer}
      />
    </Modal>
  )
}

export default ModalContact
