import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { Modal } from 'rsuite'

import {
  INPUT_TYPES,
  DELIVERY_METHODS,
  DELIVERY_METHODS_INITIAL_INFO,
  PIPELINE_LABELS,
} from '../../../utils/constants'
import { getThemeDeliveryConfigs, updateDeliveryConfig } from '../../../api/admin/deliveryConfigs'
import {
  configDeliveryConfigs,
  determineDeliveryMethod,
} from '../../../utils/functions/deliveryConfigs'
import { TimeFilled } from '../../SvgIcon'
import ControllerField from '../../HookForm/components/ControllerField'
import PrimaryButton from '../../PrimaryButton'
import Button from '../../Button'

import './styles.css'

const ModalDeliveryTime = ({ show, onHide, pipelineType, themeId }) => {
  const { handleSubmit, control, watch, reset, formState, setValue } = useForm({
    defaultValues: {
      deliveryMethod: DELIVERY_METHODS.DAILY,
      configs: [],
    },
  })
  const mutationUpdateDeliveryThemeSettings = useMutation({
    mutationKey: ['updateThemeDeliverySettings'],
    mutationFn: (data) => {
      const formattedParams = configDeliveryConfigs(data.deliveryMethod, data.configs)
      return updateDeliveryConfig({
        ...formattedParams,
        themeId,
        pipelineType,
        type: formattedParams.method,
        pipelineName: PIPELINE_LABELS[pipelineType],
      })
    },
    onSuccess: onHide,
  })

  useEffect(() => {
    if (show) {
      getThemeDeliveryConfigs({ pipelineType, themeId }).then(({ data }) => {
        const { configs, method } = determineDeliveryMethod(data.type, data.configs)
        reset({ configs, deliveryMethod: method }, { keepDirty: false })
      })
    }
  }, [show])

  return (
    <Modal overflow={false} open={show} onClose={onHide} dialogClassName="modalDialogDeliveryTime">
      <form onSubmit={handleSubmit(mutationUpdateDeliveryThemeSettings.mutate)}>
        <Modal.Header>
          <Modal.Title>
            <div className="d-flex align-items-center gap-2">
              <TimeFilled size={20} fill="var(--catalina-blue)" />
              <b>Delivery Settings</b>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            As an admin, you have the ability to set default delivery times for each stage in a
            pipeline, ensuring tasks or deliveries are executed on a consistent schedule. This
            feature helps streamline workflows and maintain uniformity across your processes, making
            it easier to manage timelines.
          </p>
          <ControllerField
            control={control}
            type={INPUT_TYPES.INPUT_DROPDOWN_DELIVERY_METHOD}
            name="deliveryMethod"
            defaultValue={DELIVERY_METHODS.DAILY}
            rules={{ required: false }}
            data={DELIVERY_METHODS_INITIAL_INFO}
            title="Default delivery method"
            customDropdownMenu="w-100"
            updateFormValue={setValue}
            innerDropdown="mw-100"
            valueKey="value"
          />
          <ControllerField
            name="configs"
            control={control}
            rules={{ required: false }}
            deliveryMethod={watch('deliveryMethod')}
            defaultValue={[]}
            configDeliveryDayCard={{ fillClockIcon: 'var(--catalina-blue)' }}
            type={INPUT_TYPES.INPUT_CONFIG_DELIVERY_TIME_PICKER}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center gap-2 justify-content-end mt-1">
          <Button type="button" onClick={onHide}>
            Cancel
          </Button>
          <PrimaryButton
            disabled={!formState.isDirty || mutationUpdateDeliveryThemeSettings.isPending}
            text="Save"
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ModalDeliveryTime
