import { useState, useLayoutEffect, useCallback } from 'react'
import { isNil } from 'lodash'

import { LoadingIcon } from '../SvgIcon'
import './styles.css'

const ImageLoader = ({
  src,
  alt = '',
  loadingProps,
  innerClassName = '',
  imageClassName = '',
  LoadingComponent = LoadingIcon,
  placeholder = '/images/imagePlaceholder.png',
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)

  const handleImageLoad = useCallback(() => setIsLoaded(true), [])
  const handleImageError = useCallback(() => setHasError(true), [])

  // Reset state when src changes
  useLayoutEffect(() => {
    setIsLoaded(false)
    setHasError(false)
  }, [src])

  return (
    <div className={`imageLoader ${innerClassName}`}>
      {!isLoaded && !hasError && !isNil(src) ? (
        <LoadingComponent size={25} className="iconLoader" {...loadingProps} />
      ) : null}
      {(hasError || !isLoaded) && (
        <img
          src={placeholder}
          alt={`Placeholder ${alt}`}
          className={`${imageClassName} placeholderImage`}
        />
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        onError={handleImageError}
        className={`${imageClassName} ${isLoaded && !hasError ? 'visible' : 'hidden'}`}
      />
    </div>
  )
}

export default ImageLoader
