import { isNil } from 'lodash'
import { Link } from 'react-router-dom'

import { routes } from '../../../../router/routes'
import useTimer from '../../../../utils/hooks/useTimer'
import PrimaryButton from '../../../../components/PrimaryButton'

const Footer = ({ btnText, isFirstStep, onClickResendCode, disabledBtn }) => {
  const { timer, onChangeTimerTime } = useTimer()

  const handleClickResendCode = async () => {
    await onClickResendCode().then(() => onChangeTimerTime(60))
  }

  return (
    <div className="innerFormFooter">
      {!isFirstStep && (
        <p className="text-center mt-2">
          Didn't receive code?{' '}
          {isNil(timer) ? (
            <button
              type="button"
              onClick={handleClickResendCode}
              disabled={disabledBtn}
              className="cursorPointer fw-bold p-0 bg-transparent"
            >
              <u>Click here</u>
            </button>
          ) : (
            <b>{timer}</b>
          )}
        </p>
      )}
      {isFirstStep && (
        <Link to={routes.resetPassword} className="textForgotPassword">
          Forgot password?
        </Link>
      )}
      <PrimaryButton disabled={disabledBtn} customClass="big submitFormButton" text={btnText} />
    </div>
  )
}

export default Footer
