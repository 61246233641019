import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { routes } from '../../../router/routes'
import { FORM_LOGIN_ADMIN, OTP_FIELD } from '../../constantsForms'
import { ROLES } from '../../constants'
import { getAllCompanies } from '../../../api/superAdmin/companies'
import { confirmAdminLogin, getMe, loginAdmin } from '../../../api/auth'
import { setCurrentCompany } from '../../../redux/actions/currentCompany'
import { getInfoCurrentCompany, getMyCompanies } from '../../../api/admin/company'
import { setUser } from '../../../redux/actions/user'
import { updateToken } from '../../auth'
import { useWebsocketContext } from '../../../components/SocketProvider'
import useWebSocket from '../useWebSocket'

const useAdminLogin = () => {
  const [isFirstStep, setIsFirstStep] = useState(true)
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { connect } = useWebSocket()
  const { handleWebSocketSubscription } = useWebsocketContext()
  const { userRole, userId } = useSelector(({ user }) => ({
    userRole: user.role,
    userId: user.data.id,
  }))

  const mutationGetAdminCompanies = useMutation({
    mutationKey: ['myCompanies', userId],
    mutationFn: () => (userRole === ROLES.ROLE_SUPER_ADMIN ? getAllCompanies() : getMyCompanies()),
    onSuccess: ({ data: companies }) => {
      return companies?.length > 1
        ? navigate(routes.adminSwitchAccount)
        : navigate(routes.messaging)
    },
  })

  const authAdmin = async ({ data }) => {
    await updateToken(data.token)
    await connect()
    await handleWebSocketSubscription()
    const { data: adminInfo } = await getMe()
    const { data: companyData } = await getInfoCurrentCompany()
    dispatch(setUser(adminInfo))
    dispatch(setCurrentCompany(companyData))
    mutationGetAdminCompanies.mutate()
  }

  const resendCode = () => {
    const params = formRef.current?.getValues()

    return mutationSentVerificationCode.mutateAsync(params)
  }

  const mutationSentVerificationCode = useMutation({
    mutationKey: ['admin', 'sentVerificationCode'],
    mutationFn: loginAdmin,
    onSuccess: () => {
      if (isFirstStep) {
        formRef.current.update(0, { ...FORM_LOGIN_ADMIN[0], innerClassName: 'hidden' })
        formRef.current.update(1, { ...FORM_LOGIN_ADMIN[1], innerClassName: 'hidden' })
        formRef.current.append({
          ...OTP_FIELD,
          innerClassName: 'innerInput h-auto',
          onSuccess: () => formRef.current.submitHandler(),
        })
        setIsFirstStep(false)
      }
    },
    onError: () => formRef.current?.setError('password', {}),
  })

  const mutationConfirmLogin = useMutation({
    mutationKey: ['admin', 'confirmCode'],
    mutationFn: confirmAdminLogin,
    onSuccess: authAdmin,
    onError: () => formRef.current?.setError('code', {}),
  })

  const handleLogin = (data) => {
    if (isFirstStep) {
      return mutationSentVerificationCode.mutate(data)
    }
    return mutationConfirmLogin.mutate(data)
  }

  return {
    formRef,
    handleLogin,
    buttonText: isFirstStep ? 'Sign in' : 'Confirm sign in',
    resendCode,
    isFirstStep,
    isPending: mutationConfirmLogin.isPending || mutationSentVerificationCode.isPending,
  }
}

export default useAdminLogin
