import { useState } from 'react'

import { LIMIT_QUESTION_OPTION } from '../../../utils/constants'
import SurveyOption from '../SurveyOption'

const SingleChoiceQuestionBody = ({ itemQuestion, itemSurvey, dropdownNextMessages }) => {
  const [options, setOptions] = useState(itemQuestion.options)

  const handleAddOption = ({ data }) => {
    setOptions((oldState) => [...oldState, data])
  }

  const handleChangeOption = ({ data }) => {
    setOptions((oldState) => oldState.map((item) => (item.id === data.id ? data : item)))
  }

  const renderAddOptionButton = () => {
    if (options.length >= LIMIT_QUESTION_OPTION) {
      return null
    }

    return (
      <div className="pb-2 border-bottom">
        <button
          onClick={() => {
            setOptions((oldState) => [
              ...oldState,
              {
                optionText: '',
                isNewOption: true,
                id: `${itemSurvey.id}-${options.length + 1}`,
              },
            ])
          }}
          className="buttonAddNew"
        >
          + Add option
        </button>
      </div>
    )
  }

  return (
    <div>
      {options.map(({ id, name, nextQuestionId, isNewOption = false }, index) => (
        <SurveyOption
          key={id}
          optionId={id}
          optionText={name}
          isNewOption={isNewOption}
          numberOfOption={index + 1}
          itemQuestion={itemQuestion}
          onAddOption={handleAddOption}
          nextQuestionId={nextQuestionId}
          dropdownNextMessages={dropdownNextMessages}
          onChangeOption={handleChangeOption}
          onDeleteOption={() =>
            setOptions((oldState) => oldState.filter((option) => option.id !== id))
          }
        />
      ))}
      {renderAddOptionButton()}
    </div>
  )
}

export default SingleChoiceQuestionBody
