import { useCallback, useState } from 'react'

import { SURVEY_QUESTION_TYPES } from '../constants'

const useD3Tree = ({
  defaultTranslate = {
    x: 0,
    y: 0,
  },
}) => {
  const [translate, setTranslate] = useState(defaultTranslate)

  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect()
      setTranslate({ x: width / 2, y: height / 2 })
    }
  }, [])

  const transformSurveyData = (data, parentId = null) => {
    const question = data.find((item) => item.id === parentId)

    if (!question) {
      return null
    }

    const childData = {
      name: question.name,
      children: [],
      attributes: question,
    }

    if (question.type === SURVEY_QUESTION_TYPES.SINGlE_CHOICE_SELECT) {
      childData.children = question.options.map((item) => ({
        name: item.name,
        children: [transformSurveyData(data, item.nextQuestionId)],
        attributes: { type: 'option' },
      }))
    } else {
      const nextQuestion = data.find((item) => item.id === question.nextQuestionId)
      if (nextQuestion) {
        childData.children = [transformSurveyData(data, nextQuestion.id)]
      }
    }

    return childData
  }

  return { translate, containerRef, transformSurveyData }
}

export default useD3Tree
