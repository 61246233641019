import { URL_PATTERN } from '../../../../utils/constants'
import { formatPhoneNumber } from '../../../../utils/helpers'
import { IconPhone } from '../../../../components/SvgIcon'
import ImageLoader from '../../../../components/ImageLoader'

const ContactPreview = ({ customLinks, avatarUrl, name, phone }) => {
  return (
    <div className="previewContact position-relative">
      <ImageLoader src={avatarUrl} loadingProps={{ size: 15 }} alt="Logo" imageClassName="logo" />
      <p className="name mt-2 mb-3">{name || '---'}</p>
      <div className="blockInfo w-100">
        <p className="blockInfoTitle sm ">Contact info</p>
        <p className="blockInfoContent mw-800">
          <IconPhone size={17} fill="var(--catalina-blue)" />
          <span className="ms-2">{formatPhoneNumber(phone) || '---'}</span>
        </p>
      </div>
      <div className="blockInfo w-100 mt-3">
        <p className="blockInfoTitle sm mb-3">URLs</p>
        <p className="blockInfoContent mw-800">
          <ul className="listContactList">
            {customLinks?.map(({ label, url }, index) => (
              <li key={label + url + index}>
                <p className="labelLink">{label || '---'}</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                  href={URL_PATTERN.test(url) ? url : '#'}
                >
                  {url}
                </a>
              </li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  )
}

export default ContactPreview
