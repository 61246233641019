import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'

import { getThemeTags } from '../../../../api/admin/themeSettings'
import CustomTag from '../../../../components/CustomTag'

const ThemeTags = () => {
  const { themeId } = useParams()
  const { data: tags } = useQuery({
    queryKey: ['themeTags', themeId],
    queryFn: () => getThemeTags(themeId),
  })
  const isEmptyTagsList = useMemo(() => isEmpty(tags?.data), [tags])

  return (
    <div className="mb-5">
      <p className={`blockTitle mb-3 ${!isEmptyTagsList ? 'pb-3 underline' : ''}`}>Theme tags</p>
      {isEmptyTagsList && (
        <div className="innerEmptyBlock">
          <p className="blockDescription">No tags</p>
        </div>
      )}
      <div className="d-flex align-items-center gap-3 flex-wrap">
        {tags?.data.map((tagInfo) => (
          <CustomTag key={tagInfo.id} tagInfo={tagInfo} className="ms-0" closable={false} />
        ))}
      </div>
    </div>
  )
}

export default ThemeTags
