import PrimaryButton from '../../../../components/PrimaryButton'
import useMessages from '../../../../utils/hooks/useMessages'

const Footer = ({ message, messageConstants }) => {
  const { messageLimit, messagesCount } = useMessages(message || '', messageConstants)

  return (
    <div className="formFooter containerAdminMainInfo">
      <p className="numberMessages">
        SMS: <span style={messagesCount >= 4 ? { color: 'red' } : {}}>{messagesCount}</span>, chars
        left: {messageLimit}
      </p>
      <PrimaryButton customClass="submitSettings" text="Save" />
    </div>
  )
}

export default Footer
