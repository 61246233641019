import { find } from 'lodash'
import CustomDropdown from '../../CustomDropdown'

const OpenEndQuestionBody = ({ onChangeQuestionInfo, dropdownNextMessages, nextQuestionId }) => {
  const nextQuestion = find(dropdownNextMessages, { id: nextQuestionId })

  return (
    <div className="pb-2 border-bottom">
      <p>
        An open-ended question prompts a detailed, free-form response without predefined answer
        options, often used to gather qualitative information and opinions.
      </p>
      <CustomDropdown
        titleClass="dropzoneTitle"
        label="Select next question:"
        innerDropdown="innerDropdownOpenSurveyQuestion mt-3"
        value={nextQuestion}
        onChange={(value) => {
          onChangeQuestionInfo({ nextQuestionId: value.id })
        }}
        data={dropdownNextMessages}
        title="Select next way"
      />
    </div>
  )
}

export default OpenEndQuestionBody
