import r from '../../utils/request'

export const getThemeMessages = (params) => {
  return r.get(`/admin/themes/${params.id}/messages`, params)
}

export const getOneThemeMessage = (params) => {
  return r.get(`/admin/themes/${params.id}/messages/one`, params)
}

export const getMessageById = (messageId) => {
  return r.get(`/admin/messages/${messageId}`)
}

export const getGlobalOnboardingMessage = () => {
  return r.get('/admin/messages/onboarding')
}

export const getRepeatedContentMessagesById = (messageId) => {
  return r.get(`/admin/repeated-content-messages/${messageId}`)
}

export const getRepeatedContentMessages = ({ id, ...rest }) => {
  return r.get(`/admin/themes/${id}/repeated-content-messages`, rest)
}

export const getMessageVariables = (params) => {
  return r.get(`/admin/messages/variables`, params)
}

export const getTwilioStaticMessages = () => {
  return r.get('/admin/twilio-messages')
}

export const createMessage = (params) => {
  return r.postWithToast(`/admin/themes/${params.themeId}/messages`, params, {
    success: 'Message created',
  })
}

export const createRepeatedContentMessages = (params) => {
  return r.postWithToast(`/admin/themes/${params.themeId}/repeated-content-messages`, params, {
    success: 'Message created',
  })
}

export const changeRepeatedContentMessage = (params) => {
  return r.putWithToast(`/admin/repeated-content-messages/${params.messageId}`, params, {
    success: 'Message updated successfully',
  })
}

export const changeMessage = (params) => {
  return r.putWithToast(`/admin/messages/${params.messageId}`, params, {
    success: 'Message updated successfully',
  })
}

export const changeGlobalOnboardingMessage = (params) => {
  return r.putWithToast('/admin/messages/onboarding', params, {
    success: 'Onboarding message updated',
  })
}

export const deleteRepeatedContentMessage = (messageId) => {
  return r.deleteWithToast(
    `/admin/repeated-content-messages/${messageId}`,
    {},
    {
      success: 'Message was deleted',
    }
  )
}

export const reorderMessagePipeline = (params) => {
  return r.put(`/admin/messages/${params.id}/reorder`, params)
}

export const deleteMessage = (messageId) => {
  return r.deleteWithToast(`/admin/messages/${messageId}`, null, {
    success: 'Message successfully deleted 🗑',
  })
}

export const getMessagesCount = (params) => {
  return r.get(`/admin/themes/${params.themeId}/messages/count`, params)
}
