import { Table } from 'rsuite'
import { isEmpty } from 'lodash'
import Linkify from 'react-linkify'

import useMessages from '../../../utils/hooks/useMessages'
import Tag from '../../Tag'

const MessageCell = ({ rowData, dataKey, contentCountKey, hasContent, ...props }) => {
  const { messagesCount } = useMessages(rowData?.[dataKey])

  return (
    <Table.Cell {...props}>
      <div className="innerMessageCell">
        <div className="additionalMessageInfo">
          {hasContent && rowData?.[contentCountKey] ? (
            <span title="Number of content" className="contentCount">
              {rowData?.[contentCountKey]}
            </span>
          ) : null}
          <span>{messagesCount} SMS</span>
        </div>
        <div className="pb-2 messageContent">
          <Linkify>{rowData?.[dataKey]}</Linkify>
        </div>
        {!isEmpty(rowData.tags) && (
          <div className="tagsList">
            {rowData.tags.map((item) => (
              <Tag {...item} key={item.id} className="sm" />
            ))}
          </div>
        )}
      </div>
    </Table.Cell>
  )
}

export default MessageCell
