import { useEffect, useMemo, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { isEmpty, toNumber } from 'lodash'
import { Dropdown } from 'rsuite'

import { PIPELINE_TABLES_SETTINGS, THEME_PIPELINES_TABS } from '../../../utils/constants'
import { getThemeDetails } from '../../../api/admin/themes'
import { FillSetting, IconUsers, ThList, ThreeDots } from '../../../components/SvgIcon'
import ModalTableContent from '../../../components/Modals/ModalTableContent'
import ModalDeliveryTime from '../../../components/Modals/ModalDeliveryTime'
import Button from '../../../components/Button'
import Pipeline from './components/Pipeline'

import './styles.css'

const Theme = ({ currentCompanyPermissions }) => {
  const [selectedPipelineDeliveryTimeInfo, setSelectedPipelineDeliveryTimeInfo] = useState({})
  const [showModalMeditations, setShowModalMeditations] = useState(false)
  const [selectedVisibleTab, setSelectedVisibleTab] = useState(THEME_PIPELINES_TABS[0].title)
  const [visibleTableConfig, setVisibleTableConfig] = useState(null)
  const { themeId, programId } = useParams()
  const navigation = useNavigate()

  const messageTypeList = useMemo(() => {
    return THEME_PIPELINES_TABS.filter((item) => item.title === selectedVisibleTab)[0]
      ?.messageTypeList
  }, [selectedVisibleTab])

  const { data: theme } = useQuery({
    queryKey: ['theme', themeId],
    queryFn: () => getThemeDetails(themeId),
  })

  useEffect(() => {
    setVisibleTableConfig(messageTypeList[0])
  }, [selectedVisibleTab])

  return (
    <div className="containerAdminMainInfo">
      <div className="d-flex align-items-center justify-content-between gap-3 mb-2">
        <h1 className="titleAdminPage mb-0">{theme?.data?.name}</h1>
        <div className="d-flex align-items-center gap-2">
          <Button onClick={() => navigation(`surveys`)}>Surveys</Button>
          <Dropdown
            renderToggle={(props) => (
              <Button {...props} customClass="p-1">
                <ThreeDots
                  className="cursorPointer threeDotsHorizontal"
                  fill="var(--catalina-blue)"
                  size={18}
                />
              </Button>
            )}
            placement="bottomEnd"
            style={{ zIndex: 10 }}
          >
            <Dropdown.Item
              onClick={() => navigation(`/admin/programs/${programId}/${themeId}/settings`)}
              className="d-flex align-items-center gap-2"
              icon={<FillSetting size={17} fill="#969898" />}
            >
              Settings
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                const searchParams = {
                  filterData: JSON.stringify({ themeId: [toNumber(themeId)] }),
                }
                navigation({
                  pathname: '/admin/users',
                  search: '?' + createSearchParams(searchParams).toString(),
                })
              }}
              className="d-flex align-items-center gap-2"
              icon={<IconUsers size={17} fill="#969898" />}
            >
              Users
            </Dropdown.Item>
            {currentCompanyPermissions?.useContent && (
              <Dropdown.Item
                onClick={() => setShowModalMeditations(true)}
                className="d-flex align-items-center gap-2"
                icon={<ThList size={17} fill="#969898" />}
              >
                Contents
              </Dropdown.Item>
            )}
          </Dropdown>
        </div>
      </div>
      <div className="tabsList bottomLine">
        {THEME_PIPELINES_TABS.map(
          ({ title, hasPermissions }) =>
            hasPermissions({ ...theme?.data, ...currentCompanyPermissions }) && (
              <button
                key={title}
                onClick={() => setSelectedVisibleTab(title)}
                className={`tab ps-3 pe-3 fullLine ${
                  title === selectedVisibleTab ? 'active cursorDefault' : ''
                }`}
              >
                {title}
              </button>
            )
        )}
      </div>
      <div className="listMessagesTypes">
        {messageTypeList?.map(
          (config) =>
            config.hasPermissions({ ...theme?.data, ...currentCompanyPermissions }) && (
              <button
                key={config?.title}
                onClick={() => setVisibleTableConfig(config)}
                className={`messageType ${
                  visibleTableConfig?.title === config?.title ? 'active' : ''
                }`}
              >
                {config?.title}
              </button>
            )
        )}
      </div>
      <Pipeline
        {...PIPELINE_TABLES_SETTINGS[
          visibleTableConfig?.pipelineType + visibleTableConfig?.messageType
        ]}
        tableKey={visibleTableConfig?.pipelineType + visibleTableConfig?.messageType}
        onClickIconTime={setSelectedPipelineDeliveryTimeInfo}
      />
      <ModalTableContent
        themeId={themeId}
        show={showModalMeditations}
        onHide={() => setShowModalMeditations(false)}
      />
      <ModalDeliveryTime
        themeId={themeId}
        show={!isEmpty(selectedPipelineDeliveryTimeInfo)}
        onHide={() => setSelectedPipelineDeliveryTimeInfo({})}
        pipelineType={selectedPipelineDeliveryTimeInfo?.type}
      />
    </div>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})

export default connect(mapStateToProps)(Theme)
