import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useMutation, useQuery } from '@tanstack/react-query'
import { cloneDeep, debounce, every, findIndex, isEmpty, isNil, some, xorBy } from 'lodash'
import { Virtuoso } from 'react-virtuoso'
import { Nav } from 'rsuite'

import {
  CHAT_LIST_TABS,
  ROLES,
  SIZE_PAGINATION_CHAT_LIST,
  WEBSOCKET_CONNECTION_CHANNEL,
  WEBSOCKET_STATES,
} from '../../../utils/constants'
import {
  getChats,
  toggleStarred,
  sendAdminMultipleMessage,
  toggleAttention,
} from '../../../api/dialog'
import { getMyCompanies } from '../../../api/admin/company'
import { getCompaniesChats } from '../../../api/superAdmin/companies'
import { generateJobKey } from '../../../utils/helpers'
import useWebSocket from '../../../utils/hooks/useWebSocket'
import {
  Cross,
  IconStar,
  Messenger,
  Check2All,
  Check2Circle,
  MessageSquareEdit,
  IconStarOff,
  Info,
  SupportAgent,
  IconCompanies,
} from '../../../components/SvgIcon'
import ModalGroupMessageSender from '../../../components/Modals/ModalGroupMessageSender'
import ContextMenu from '../../../components/ContextMenu'
import CustomInput from '../../../components/CustomInput'
import ChatWindow from './components/ChatWindow'
import PreviewChat from './components/PreviewChat'

import './styles.css'

const UsersChat = ({ userRole, currentCompanyId }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [chats, setChats] = useState([])
  const [selectedChats, setSelectedChats] = useState([])
  const [lastPage, setLastPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [activeChat, setActiveChat] = useState(null)
  const [showModalGroupMessageSender, setShowModalGroupMessageSender] = useState(false)
  const searchValueRef = useRef('')

  const activeTabType =
    searchParams.get('chatType') ??
    (userRole === ROLES.ROLE_ADMIN ? CHAT_LIST_TABS.ALL : CHAT_LIST_TABS.COMPANIES)

  const navigate = useNavigate()
  const { subscribeChatList } = useWebSocket()
  const webSocketSubscribeChatList = useRef(null)
  const webSocketSubscribeSuperAdminChatList = useRef(null)

  const isSelectedOnlyStarred = useMemo(
    () => every(selectedChats, { isStared: {} }),
    [selectedChats]
  )
  const IconStarred = useCallback(
    () => (!isSelectedOnlyStarred ? IconStar : IconStarOff),
    [isSelectedOnlyStarred]
  )

  const isVisibleContextMenu = useMemo(
    () =>
      isEmpty(selectedChats) &&
      activeTabType !== CHAT_LIST_TABS.SUPPORT &&
      activeTabType !== CHAT_LIST_TABS.COMPANIES,
    [selectedChats, activeTabType]
  )

  const { data: superAdminCompanies } = useQuery({
    queryKey: ['superAdminCompanies'],
    queryFn: getMyCompanies,
    enabled: userRole === ROLES.ROLE_SUPER_ADMIN,
  })
  const adminTabs = useMemo(
    () => [
      {
        key: CHAT_LIST_TABS.ALL,
        icon: <Messenger size={17} fill="var(--catalina-blue)" />,
        label: 'Users',
      },
      {
        key: CHAT_LIST_TABS.STARRED,
        icon: <IconStar size={20} color="var(--catalina-blue)" fill="var(--catalina-blue)" />,
        label: 'Starred',
      },
      {
        key: CHAT_LIST_TABS.ATTENTION,
        icon: <Info size={20} color="var(--catalina-blue)" fill="var(--catalina-blue)" />,
        label: 'Attention',
      },
      {
        key: CHAT_LIST_TABS.SUPPORT,
        icon: <SupportAgent size={22} color="var(--catalina-blue)" fill="var(--catalina-blue)" />,
        label: 'Support',
      },
    ],
    [userRole]
  )

  const superAdminTabs = useMemo(
    () => [
      ...(superAdminCompanies?.data.some((item) => item.id === currentCompanyId)
        ? [
            {
              key: CHAT_LIST_TABS.ALL,
              icon: <Messenger size={17} fill="var(--catalina-blue)" />,
              label: 'Users',
            },
            {
              key: CHAT_LIST_TABS.STARRED,
              icon: <IconStar size={20} color="var(--catalina-blue)" fill="var(--catalina-blue)" />,
              label: 'Starred',
            },
            {
              key: CHAT_LIST_TABS.ATTENTION,
              icon: <Info size={20} color="var(--catalina-blue)" fill="var(--catalina-blue)" />,
              label: 'Attention',
            },
          ]
        : []),
      {
        key: CHAT_LIST_TABS.COMPANIES,
        icon: <IconCompanies size={20} color="var(--catalina-blue)" fill="var(--catalina-blue)" />,
        label: 'Companies',
      },
    ],
    [userRole, superAdminCompanies]
  )

  const handleSyncChatInfo = (data) => {
    setChats((oldState) => {
      const chatIndex = findIndex(oldState, ({ id }) => id === data.id)
      if (chatIndex >= 0) {
        const filterChats = oldState.filter(({ id }) => id !== data.id)
        return [data, ...filterChats]
      }

      if (currentPage < lastPage) {
        const newArrayCharts = cloneDeep(oldState)
        newArrayCharts.pop()

        return [data, ...newArrayCharts]
      }
      return oldState
    })
  }

  const handleActionToggleStarred = (data) => {
    return setChats((oldState) => {
      return oldState.reduce((res, item) => {
        if (data.id === item.id) {
          return !isEmpty(data.isStared) || activeTabType === CHAT_LIST_TABS.ALL
            ? [...res, data]
            : res
        }
        return [...res, item]
      }, [])
    })
  }

  const handleReadMessage = ({ userId }) => {
    setChats((oldState) => {
      return oldState.reduce((res, value) => {
        if (value.id === userId) {
          const unreadMessagesCount = value.unreadMessagesCount - 1
          return [...res, { ...value, unreadMessagesCount }]
        }

        return [...res, value]
      }, [])
    })
  }

  const handleChatSelect = (chat) => {
    setSelectedChats((prevSelectedChats) => xorBy(prevSelectedChats, [chat], 'id'))
  }

  const mutationAttentionUser = useMutation({
    mutationKey: ['toggleUserAttention'],
    mutationFn: toggleAttention,
    onSuccess: ({ data }) => {
      setChats((oldState) =>
        oldState.map((userChatInfo) =>
          userChatInfo.id === data.id ? { ...userChatInfo, ...data } : userChatInfo
        )
      )
    },
  })

  const selectChat = (chatId) => {
    if (isEmpty(selectedChats)) {
      setActiveChat(chatId)
      return navigate(`.?chatType=${activeTabType}`, { state: { chatId } })
    }
    handleChatSelect({ id: chatId })
  }

  const handleSearch = debounce(({ target }) => {
    setChats([])
    setCurrentPage(1)
    searchValueRef.current = target.value
  }, 500)

  const switchTab = (tabKey) => {
    setSearchParams({ chatType: tabKey })
    switch (tabKey) {
      case CHAT_LIST_TABS.ALL:
      case CHAT_LIST_TABS.STARRED:
      case CHAT_LIST_TABS.ATTENTION:
      case CHAT_LIST_TABS.COMPANIES: {
        setActiveChat(null)
        setChats([])
        setCurrentPage(1)
        break
      }

      case CHAT_LIST_TABS.SUPPORT: {
        setActiveChat('support')
        break
      }
    }
  }

  const onEndScrollChatList = () => {
    if (lastPage > currentPage) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1)
    }
  }

  const sendMultipleMessages = (data) => {
    const messageRecipients = selectedChats.map(({ id }) => id)

    return sendAdminMultipleMessage({ usersIds: messageRecipients, ...data }).then(() =>
      setSelectedChats([])
    )
  }

  const getListMessages = () => {
    if (activeTabType === CHAT_LIST_TABS.SUPPORT && !isEmpty(chats)) {
      setActiveChat('support')
      return
    }
    const requestOptions = {
      page: currentPage,
      search: searchValueRef.current,
      size: SIZE_PAGINATION_CHAT_LIST,
      ...(CHAT_LIST_TABS.STARRED === activeTabType && {
        isStared: CHAT_LIST_TABS.STARRED === activeTabType,
      }),
      ...(CHAT_LIST_TABS.ATTENTION === activeTabType && {
        needsAttention: CHAT_LIST_TABS.ATTENTION === activeTabType,
      }),
    }
    const request = activeTabType === CHAT_LIST_TABS.COMPANIES ? getCompaniesChats : getChats

    request(requestOptions).then(({ data: { data, lastPage } }) => {
      setChats((prevChats) => [...prevChats, ...data])
      setLastPage(lastPage)
      if (activeTabType === CHAT_LIST_TABS.SUPPORT) {
        setActiveChat('support')
      }
    })
  }
  const tabsList = useMemo(
    () => (userRole === ROLES.ROLE_ADMIN ? adminTabs : superAdminTabs),
    [userRole, adminTabs, superAdminTabs]
  )
  const activeTabInfo = useMemo(
    () => tabsList?.find(({ key }) => key === activeTabType),
    [tabsList, activeTabType]
  )

  useEffect(() => {
    getListMessages()
  }, [currentPage, activeTabType, searchValueRef.current])

  useEffect(() => {
    webSocketSubscribeChatList.current = subscribeChatList({
      channel: `${WEBSOCKET_CONNECTION_CHANNEL.ADMIN_CHATS}:${generateJobKey({
        companyId: currentCompanyId,
      })}`,
      handleReadMessage,
      handleSyncChatInfo,
      handleActionToggleStarred,
    })

    return () => {
      if (webSocketSubscribeChatList.current?.state === WEBSOCKET_STATES.OPEN) {
        webSocketSubscribeChatList.current.close()
      }
    }
  }, [])

  useEffect(() => {
    if (
      userRole === ROLES.ROLE_SUPER_ADMIN &&
      superAdminCompanies?.data.some((item) => item.id === currentCompanyId)
    ) {
      webSocketSubscribeSuperAdminChatList.current = subscribeChatList({
        channel: WEBSOCKET_CONNECTION_CHANNEL.ADMIN_CHATS,
        handleReadMessage,
        handleSyncChatInfo,
        handleActionToggleStarred,
      })

      return () => {
        if (webSocketSubscribeSuperAdminChatList.current?.state === WEBSOCKET_STATES.OPEN) {
          webSocketSubscribeSuperAdminChatList.current.close()
        }
      }
    }
  }, [userRole, superAdminCompanies])

  return (
    <div className="innerAdminGlobalChat">
      <div className={`innerChatList ${activeChat ? 'hide' : ''}`}>
        <div className="innerHeaderChatList">
          <CustomInput
            disabled={false}
            placeholder="Search..."
            onChange={handleSearch}
            innerClassName="inputSearchUserChat"
          />
        </div>
        <Nav
          reversed
          appearance="tabs"
          activeKey={activeTabType}
          onSelect={switchTab}
          className="innerNavigationHeader"
        >
          {tabsList.map(({ key, icon, label }) => (
            <Nav.Item disabled={key === activeTabType} key={key} eventKey={key} icon={icon}>
              {label}
            </Nav.Item>
          ))}
        </Nav>
        {!isEmpty(selectedChats) && (
          <div className="innerFunctionalityChatList">
            <IconStarred
              onClick={() => {
                toggleStarred({
                  ids: selectedChats.map(({ id }) => id),
                  to_track: !isSelectedOnlyStarred,
                }).then(() => setSelectedChats([]))
              }}
              title={!isSelectedOnlyStarred ? 'Add to starred' : 'Remove from starred'}
              size={20}
              fill="var(--catalina-blue)"
            />
            <MessageSquareEdit
              size={20}
              fill="var(--catalina-blue)"
              title="Message multiple users"
              onClick={() => setShowModalGroupMessageSender(true)}
            />
            <Check2All
              size={20}
              fill="var(--catalina-blue)"
              title="Select all"
              onClick={() => setSelectedChats(chats)}
            />
            <Cross onClick={() => setSelectedChats([])} size={20} color="var(--persian-red)" />
          </div>
        )}
        <div className="position-relative h-100">
          <Virtuoso
            data={chats}
            atBottomThreshold={100}
            endReached={onEndScrollChatList}
            itemContent={(_, chatData) => {
              const {
                id,
                firstUnreadSms,
                needsAttention,
                hasBlockedTwilio,
                isStared,
                unreadMessagesCount,
              } = chatData
              const isSelected = some(selectedChats, { id })
              const isActiveChat = id === activeChat

              return (
                <ContextMenu
                  dataItem={chatData}
                  menuList={[
                    {
                      name: !isStared ? 'Add to starred' : 'Remove from starred',
                      Icon: !isStared ? IconStar : IconStarOff,
                      onClick: ({ id }) => toggleStarred({ ids: [id], toTrack: !isStared?.userId }),
                    },
                    {
                      name: !needsAttention ? 'Needs attention' : 'Remove from attention list',
                      Icon: !needsAttention ? Info : Cross,
                      onClick: ({ id }) => mutationAttentionUser.mutate(id),
                    },
                    {
                      name: 'Select',
                      Icon: Check2Circle,
                      onClick: (data) => handleChatSelect(data),
                    },
                  ]}
                  showContextMenu={isVisibleContextMenu}
                >
                  <PreviewChat
                    chatId={chatData.id}
                    title={`${chatData?.name ?? ''} ${chatData?.firstName ?? ''} ${
                      chatData?.lastName ?? ''
                    }`}
                    customClassName={`${isSelected ? 'selectModeChat' : ''} ${
                      isActiveChat ? 'activeChat' : ''
                    }`}
                    isAttention={needsAttention}
                    isStared={isStared}
                    hasBlocked={hasBlockedTwilio}
                    lastMessage={{ text: firstUnreadSms?.message, date: firstUnreadSms?.createdAt }}
                    onSelectChat={selectChat}
                    unreadMessagesCount={unreadMessagesCount}
                  />
                </ContextMenu>
              )
            }}
          />
          {isEmpty(chats) && (
            <div className="messagePlaceholder top-50 text-center">
              No <b>{activeTabInfo?.label}</b> chats found
            </div>
          )}
        </div>
      </div>
      <div className="innerUserChat">
        <img className="bgChat" alt="bg-chat" src="/images/bg-chat.svg" />
        {!isNil(activeChat) ? (
          <ChatWindow
            chatId={activeChat}
            chatType={activeTabType}
            onHide={() => setActiveChat(null)}
          />
        ) : (
          <div className="messagePlaceholder">Select a chat to start messaging</div>
        )}
      </div>
      <ModalGroupMessageSender
        onSend={sendMultipleMessages}
        show={showModalGroupMessageSender}
        onHide={() => setShowModalGroupMessageSender(false)}
      />
    </div>
  )
}

const mapStateToProps = ({ user, currentCompany }) => ({
  userRole: user.role,
  currentCompanyId: currentCompany.id,
})

export default connect(mapStateToProps)(UsersChat)
