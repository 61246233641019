import r from '../../utils/request'

export const getMyCompanies = () => {
  return r.get('/companies/my')
}

export const getInfoCurrentCompany = () => {
  return r.get('/companies/current')
}

export const switchAccountCompany = (companyId) => {
  return r.post('/companies/switch', { newCompanyId: companyId })
}
