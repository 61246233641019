import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { routes } from '../../../router/routes'
import { FORM_LOGIN_ADMIN } from '../../../utils/constantsForms'
import useAdminLogin from '../../../utils/hooks/useAdminLogin'
import LayoutGuest from '../../../components/LayoutGuest'
import HookFormFieldsArray from '../../../components/HookForm/HookFormFieldsArray'
import Footer from './components/Footer'

import './styles.css'

const LoginAdmin = () => {
  const { formRef, handleLogin, buttonText, resendCode, isPending, isFirstStep } = useAdminLogin()

  return (
    <LayoutGuest>
      <Helmet title="Company Sign-in" />
      <div className="loginOrg">
        <div className="innerCenterForm container">
          <div className="innerForm">
            <p className="titleForm">Sign in to your account</p>
            <HookFormFieldsArray
              ref={formRef}
              onSubmit={handleLogin}
              hookFormSettings={{ reValidateMode: 'onSubmit' }}
              className="form"
              defaultFields={FORM_LOGIN_ADMIN}
              globalName="loginOrg"
              Footer={
                <Footer
                  btnText={buttonText}
                  isFirstStep={isFirstStep}
                  onClickResendCode={resendCode}
                  disabledBtn={isPending}
                />
              }
            />
            <p className="blockUnderFooter">
              New to LiveContact? <Link to={routes.orgRegistration}>Create account</Link>
            </p>
          </div>
        </div>
      </div>
    </LayoutGuest>
  )
}

export default LoginAdmin
