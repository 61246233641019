import React, { useState } from 'react'
import moment from 'moment-timezone'

import {
  SURVEY_EVENTS,
  TYPE_SURVEY_EVENTS,
  TYPE_SURVEY_RELATIVE,
  TYPE_SURVEY_SPECIFIC,
  DATE_FORMAT_WITH_TIME,
} from '../../utils/constants'
import { Plus } from '../SvgIcon'
import Button from '../Button'
import SurveyQuestion from './SurveyQuestion'
import SurveyAccordion from './SurveyAccordion'
import SurveyFinalText from './SurveyFinalText'

const SurveyItem = ({ itemSurvey, onClickEditSurvey, handleChangeSurvey, ...rest }) => {
  const { id, deliveryTriggerEvent, waitingDays, absoluteDate, name, type, finalText } = itemSurvey

  const [questions, setQuestions] = useState(itemSurvey.questions)

  const getActionTime = () => {
    switch (type) {
      case TYPE_SURVEY_RELATIVE:
        return `Days ${waitingDays}`
      case TYPE_SURVEY_SPECIFIC:
        return moment(absoluteDate).format(DATE_FORMAT_WITH_TIME)
      case TYPE_SURVEY_EVENTS:
        return {
          [SURVEY_EVENTS.CONTENT_DELIVERED]: `After ${deliveryTriggerEvent?.extra?.day}th content message`,
        }[deliveryTriggerEvent?.type]
      default:
        return ''
    }
  }

  const deleteQuestion = ({ id }) => {
    setQuestions((oldState) => oldState.filter((item) => item.id !== id))
  }

  const handleCreateQuestion = ({ data }) => {
    setQuestions((oldState) => {
      const newData = oldState.filter((item) => !item.isNewQuestion)
      return [...newData, { ...data, options: [] }]
    })
  }

  const handleChangeQuestion = ({ data }) => {
    setQuestions((oldState) =>
      oldState.map((item) => (item.id === data.id ? { ...data, options: [] } : item))
    )
  }

  const addNewQuestion = () => {
    setQuestions((oldState) => [
      ...oldState,
      {
        isNewQuestion: true,
        options: [],
        id: `${id}-${questions.length + 1}`,
      },
    ])
  }

  return (
    <SurveyAccordion
      surveyId={id}
      title={name}
      onPencilClick={(e) => {
        e.stopPropagation()
        onClickEditSurvey({ ...itemSurvey, extra: deliveryTriggerEvent })
      }}
      timeAction={getActionTime()}
      itemSurvey={itemSurvey}
      handleChangeSurvey={handleChangeSurvey}
      {...rest}
    >
      {questions?.map((itemQuestion) => (
        <SurveyQuestion
          {...itemQuestion}
          key={itemQuestion.id}
          itemSurvey={itemSurvey}
          surveyQuestions={questions}
          itemQuestion={itemQuestion}
          onChangeQuestion={handleChangeQuestion}
          onCreateQuestion={handleCreateQuestion}
          onDeleteQuestion={() => deleteQuestion(itemQuestion)}
        />
      ))}
      <Button customClass="mt-3" onClick={addNewQuestion}>
        <Plus fill="var(--catalina-blue)" size={20} />
        Add question
      </Button>
      <SurveyFinalText
        finalText={finalText}
        handleChangeSurvey={handleChangeSurvey}
        {...itemSurvey}
      />
    </SurveyAccordion>
  )
}

export default SurveyItem
