import { useCallback, useState } from 'react'
import { debounce, isEmpty } from 'lodash'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getUserSubscription } from '../../../api/user'
import { LoadingIcon } from '../../../components/SvgIcon'
import Pagination from '../../../components/Pagination'
import EmptyBlock from '../../../components/EmptyBlock'
import CustomInput from '../../../components/CustomInput'
import SubscriptionCard from '../../../components/SubscriptionCard'
import ModalSubscription from '../../../components/Modals/ModalSubscription'
import SubscriptionStatusTabs from './components/SubscriptionStatusTabs'

import './styles.css'

const Themes = () => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [filterData, setFilterData] = useState({ status: [] })
  const [showModalSubscription, setShowModalSubscription] = useState(false)
  const [selectedSubscriptionInfo, setSelectedSubscriptionInfo] = useState(null)

  const { data: userSubscriptions, isFetching } = useQuery({
    queryKey: ['userSubscriptions', filterData, selectedPage],
    queryFn: () => getUserSubscription({ ...filterData, page: selectedPage, size: 9 }),
    placeholderData: keepPreviousData,
  })

  const handleUpdateFilterData = useCallback((newFilterData) => {
    setSelectedPage(1)
    setFilterData((oldState) => ({ ...oldState, ...newFilterData }))
  }, [])

  return (
    <>
      <section className="d-none gap-3 align-items-center justify-content-between mb-3 d-md-flex">
        <h3 className="titleProfileSecond m-0">My Themes</h3>
        <CustomInput
          onChange={debounce(({ target }) => handleUpdateFilterData({ name: target.value }), 500)}
          placeholder="Search by name..."
          innerClassName="innerSearchInput userSubscriptionSearch"
        />
      </section>
      <section className="userSubscriptions">
        <SubscriptionStatusTabs filterData={filterData} onTabClick={handleUpdateFilterData} />
        <div className="position-relative">
          <div className="listUserSubscriptions">
            {userSubscriptions?.data?.data?.map((subscription) => (
              <SubscriptionCard
                key={subscription.themeId}
                {...subscription}
                price={subscription.newPrice}
                endSubscriptionAt={subscription?.details?.endSubscription}
                startSubscriptionAt={subscription?.details?.startSubscription}
                name={subscription.theme?.name}
                themeId={subscription.theme?.id}
                onClickInfoIcon={(event) => {
                  event?.preventDefault()
                  setShowModalSubscription(true)
                  setSelectedSubscriptionInfo((oldState) => ({
                    ...oldState,
                    status: subscription?.status,
                    name: subscription.theme?.name,
                    details: subscription?.details,
                    themeId: subscription.theme?.id,
                    deliveryTime: subscription?.deliveryTime,
                    startSubscriptionAt: subscription.createdAt,
                    subscriptableType: subscription?.subscriptableType,
                    price: { old: subscription.oldPrice, new: subscription.newPrice },
                    image: `data:image/png;base64, ${subscription.theme.contact.image}`,
                  }))
                }}
                imgSrc={`data:image/png;base64, ${subscription.theme.contact.image}`}
              />
            ))}
          </div>
          <Pagination
            forcePage={selectedPage - 1}
            total={userSubscriptions?.data?.total}
            lastPage={userSubscriptions?.data?.lastPage}
            show={userSubscriptions?.data?.lastPage > 1}
            onPageChange={({ selected }) => setSelectedPage(selected + 1)}
          />
          {isFetching && (
            <div className="innerLoader">
              <LoadingIcon size={60} className="loaderIcon" />
            </div>
          )}
          {isEmpty(userSubscriptions?.data?.data) ? (
            <EmptyBlock
              innerClassName="mt-3 ms-auto me-auto innerEmptySubscriptionList"
              title="No Themes Found"
              imageSrc="/images/smallLogo.png"
              supTitle="It looks like there are no themes that match your selection. Try changing your options or subscribing to a new theme to get started."
            />
          ) : null}
        </div>
      </section>
      <ModalSubscription
        show={showModalSubscription}
        changeVisibleInfo={(data) =>
          setSelectedSubscriptionInfo((oldState) => ({ ...oldState, ...data }))
        }
        subscriptionInfo={selectedSubscriptionInfo}
        removeSelectedSubscriptionInfo={() => setSelectedSubscriptionInfo(null)}
        onHide={() => setShowModalSubscription(false)}
      />
    </>
  )
}

export default Themes
