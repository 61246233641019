import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'rsuite'
import { useMutation } from '@tanstack/react-query'
import { snakeCase } from 'lodash'

import { FIELDS_DUPLICATE_THEME } from '../../../utils/constantsForms'
import { duplicateTheme } from '../../../api/admin/themes'
import { Copy } from '../../SvgIcon'
import Button from '../../Button'
import HookForm from '../../HookForm'
import PrimaryButton from '../../PrimaryButton'

const ModalDuplicateTheme = ({ show, onHide, name }) => {
  const formRef = useRef(null)
  const { themeId } = useParams()

  const mutationDuplicateTheme = useMutation({
    mutationKey: ['duplicateTheme', themeId],
    mutationFn: ({ content, admin, abandonedCart, ...restFormData }) => {
      const pipelineTypes = Object.entries({ content, admin, abandonedCart })
        .filter(([, value]) => value)
        .map(([key]) => snakeCase(key))

      return duplicateTheme({ themeId, pipelineTypes, ...restFormData })
    },
    onSuccess: onHide,
  })

  return (
    <Modal open={show} onClose={onHide}>
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex align-items-center gap-2">
            <Copy size={20} fill="var(--catalina-blue)" />
            Duplicate Theme
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your pipelines, delivery time, contents, and tags will be copied to the new site. You can
          edit the pipelines and the name of your new theme after it has been created. After
          creating a duplicate, you need to activate it. <br />
          <br /> Select the settings you want to copy:
        </p>
        <HookForm
          ref={formRef}
          onSubmit={mutationDuplicateTheme.mutate}
          className="mt-2 ps-2 pe-2"
          defaultValues={{ themeName: `Copy of ${name}` }}
          fields={FIELDS_DUPLICATE_THEME}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center gap-2 justify-content-end mt-1">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton
          text="Duplicate theme"
          onClick={() => formRef.current?.submitHandler()}
          disabled={mutationDuplicateTheme.isPending}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalDuplicateTheme
