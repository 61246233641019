import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { INPUT_TYPES, ROLE_GUEST, ROLES } from '../../../../utils/constants'
import {
  getInfoCurrentCompany,
  getMyCompanies,
  removeCurrentCompany,
  switchAccountCompany,
} from '../../../../api/admin/company'
import { routes } from '../../../../router/routes'
import { setRoleUser } from '../../../../redux/actions/user'
import { setCurrentCompany } from '../../../../redux/actions/currentCompany'
import { clearSession, updateToken } from '../../../../utils/auth'
import { Bin } from '../../../../components/SvgIcon'
import HookForm from '../../../../components/HookForm'
import PrimaryButton from '../../../../components/PrimaryButton'
import ModalConfirm from '../../../../components/Modals/ModalConfirm'
import TableCompanyAdmins from '../components/TableCompanyAdmins'
import { getAllCompanies } from '../../../../api/superAdmin/companies'

const Settings = ({
  userId,
  userRole,
  isActiveTab,
  setRoleUser,
  currentCompanyId,
  currentCompanyName,
  setCurrentCompany,
}) => {
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const navigation = useNavigate()

  const mutationSwitchAdminCompany = useMutation({
    mutationKey: ['switchAdminCompany'],
    mutationFn: ({ companyId }) => switchAccountCompany(companyId),
    onSuccess: async ({ data }, { companiesLength }) => {
      await updateToken(data?.newToken)
      const { data: newCurrentCompanyData } = await getInfoCurrentCompany()
      setCurrentCompany(newCurrentCompanyData)

      if (companiesLength > 1) {
        navigation(routes.adminSwitchAccount)
      } else {
        toast.info(`You have switched to "${newCurrentCompanyData.name}" company`)
        navigation(routes.dashboard)
      }
    },
  })

  const mutationDeleteCurrentCompany = useMutation({
    mutationKey: ['deleteCurrentCompany'],
    mutationFn: () => removeCurrentCompany({ name: currentCompanyName }),
    onSuccess: () => setShowModalConfirmDelete(false),
  })

  const mutationGetAdminCompanies = useMutation({
    mutationKey: ['myCompanies', userId, currentCompanyId],
    mutationFn: () => (userRole === ROLES.ROLE_SUPER_ADMIN ? getAllCompanies() : getMyCompanies()),
    onSuccess: async ({ data: companies }) => {
      try {
        await mutationDeleteCurrentCompany.mutateAsync()
        const availableCompanies = companies.filter((company) => company.id !== currentCompanyId)

        if (availableCompanies?.length <= 0) {
          navigation(routes.adminLogin)
          clearSession()
          setRoleUser(ROLE_GUEST)
          return
        }
        return mutationSwitchAdminCompany.mutateAsync({
          companyId: availableCompanies[0].id,
          companiesLength: availableCompanies.length,
        })
      } catch (error) {
        console.log('ERROR DELETE COMPANY: ', error)
      }
    },
  })

  return (
    <div className={`tabBlock ${isActiveTab ? 'visibleTabBlock' : ''}`}>
      <div className="blockInfo mb-5">
        <p className="blockInfoTitle">Organization Settings</p>
        <p className="blockInfoContent mw-900">
          Manage your organization settings and administrators. You can currently delete the
          organization or manage admin details. Additional features will be available here soon.
        </p>
      </div>
      <TableCompanyAdmins />
      <p className="blockTitle underline mb-3">Delete organization</p>
      <p className="blockDescription">
        Permanently delete this organization and all its associated data. This action cannot be
        undone.
      </p>
      <HookForm
        className="d-flex align-items-center gap-4"
        onSubmit={() => setShowModalConfirmDelete(true)}
        fields={[
          {
            name: 'deleteWord',
            type: INPUT_TYPES.TEXT,
            placeholder: `DELETE ${currentCompanyName.toUpperCase()}`,
            defaultValue: '',
            innerClassName: 'inputDeleteOrganization',
            rules: {
              required: true,
              validate: (value) => value === `DELETE ${currentCompanyName.toUpperCase()}`,
            },
          },
        ]}
        Footer={() => (
          <PrimaryButton
            Icon={Bin}
            iconProps={{ size: 20 }}
            text="Delete"
            customClass="big delete"
          />
        )}
      />
      <ModalConfirm
        size="md"
        customClassName="md"
        show={showModalConfirmDelete}
        textButton="Confirm delete"
        onHide={() => setShowModalConfirmDelete(false)}
        title="Confirm Organization Deletion"
        handleClickButton={() => mutationGetAdminCompanies.mutate()}
        text="Are you sure you want to permanently delete this organization? All associated data will be lost, and this action cannot be undone."
      />
    </div>
  )
}

const mapStateToProps = ({ currentCompany, user }) => ({
  currentCompanyId: currentCompany.id,
  currentCompanyName: currentCompany.name,
  userRole: user.role,
  userId: user.data.id,
})
const mapDispatchToProps = { setRoleUser, setCurrentCompany }

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
