import r from '../../utils/request'

export const updateThemeDeliveryConfigs = (params) => {
  return r.put(`/users/subscriptions/${params.themeId}/delivery-config`, params)
}

export const updateThemeDeliveryConfigsWithToast = (params, toastMessages) => {
  return r.putWithToast(`/users/subscriptions/${params.themeId}/delivery-config`, params, {
    success: 'Delivery configs was saved!',
    ...toastMessages,
  })
}

export const getThemeDeliveryConfigs = (themeId) => {
  return r.get(`/users/subscriptions/${themeId}/delivery-config`)
}

export const getThemeDeliveredMessages = (params) => {
  return r.get('/themes/delivered-messages', params)
}
