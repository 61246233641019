import Ws from '@adonisjs/websocket-client'
import { delay } from 'bluebird'

import { AUTH_TOKEN, WEBSOCKET_EVENTS } from '../../constants'
import { camelize } from '../../helpers'
import localStorage from '../../localStorage'

let websocket = null
let websocketConnected = false

const useWebSocket = () => {
  const getSocketProtocol = () => {
    return window.location.hostname.match(/^((localhost)|((\d{1,3}\.){3}\d{1,3}))$/)
      ? 'wss://'
      : 'wss://'
  }

  const subscribe = async (channel) => {
    await delay(500)
    if (!websocket || !websocketConnected) {
      return await subscribe(channel)
    } else {
      const subscription = websocket.getSubscription(channel)
      return subscription ?? websocket.subscribe(channel)
    }
  }

  const connect = async () => {
    const token = localStorage.getItem(AUTH_TOKEN)

    websocket = await Ws(`${getSocketProtocol()}${process.env.REACT_APP_DOMAIN}`, {
      path: 'ws',
      reconnectionAttempts: Infinity,
      reconnection: true,
      reconnectionDelay: 1000,
    })
      .withJwtToken(token)
      .connect()

    websocket.on('open', () => {
      websocketConnected = true
    })

    websocket.on('close', () => {
      websocketConnected = false
    })
    websocket.on('error', () => {
      websocketConnected = false
    })
  }

  const subscribeAdminChat = async ({
    channel,
    handlerReceivedMessage,
    handlerChangeTwilioStatus,
    onReady = () => {},
    onClose = () => {},
  }) => {
    const subscription = await subscribe(channel)
    subscription?.on(WEBSOCKET_EVENTS.READY, onReady)
    subscription?.on(WEBSOCKET_EVENTS.CLOSE, onClose)

    subscription?.on(WEBSOCKET_EVENTS.RECEIVED_MESSAGE, (message) => {
      const newMessage = camelize(message)
      handlerReceivedMessage(newMessage)
    })

    subscription?.on(WEBSOCKET_EVENTS.SYNC_TWILIO_STATUS, handlerChangeTwilioStatus)

    return subscription
  }

  const subscribeChatList = async ({
    channel,
    handleReadMessage,
    handleSyncChatInfo,
    handleActionToggleStarred,
  }) => {
    const subscription = await subscribe(channel)

    subscription?.on(WEBSOCKET_EVENTS.SYNC_CHAT_INFO, (data) => {
      const formattedData = camelize(data)
      handleSyncChatInfo(formattedData)
    })

    subscription?.on(WEBSOCKET_EVENTS.READ_MESSAGE, (data) => {
      const formattedData = camelize(data)
      handleReadMessage(formattedData)
    })

    subscription?.on(WEBSOCKET_EVENTS.TOGGLE_IS_STARRED, (data) => {
      const formattedData = camelize(data)
      handleActionToggleStarred(formattedData)
    })

    return subscription
  }

  return {
    websocket,
    connect,
    subscribe,
    subscribeChatList,
    subscribeAdminChat,
  }
}

export default useWebSocket
