import { Controller } from 'react-hook-form'
import { isEmpty, isNil } from 'lodash/lang'

import { SURVEY_EVENTS } from '../../utils/constants'
import CustomDropdown from '../CustomDropdown'
import CustomInput from '../CustomInput'

const EXTRA_DATA_COMPONENT = {
  [SURVEY_EVENTS.CONTENT_DELIVERED]: CustomInput,
}
const SelectEvents = ({ eventsData = [], control, value, onChange, error }) => {
  const Component = EXTRA_DATA_COMPONENT[value.value] || null

  return (
    <div>
      <CustomDropdown
        title="Select event"
        innerDropdown="mw-100"
        data={eventsData}
        value={value}
        error={error}
        onChange={onChange}
      />
      {!isNil(Component) && (
        <Controller
          name="extra"
          control={control}
          defaultValue={{ day: '' }}
          rules={{
            required: true,
            validate: ({ day }) => !isEmpty(day.toString()),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Component
                inputType="number"
                innerClassName="mt-3 mb-2"
                value={value?.day}
                label="Write number after which message send survey"
                onChange={(e) => {
                  onChange({ day: e.target.value })
                }}
                error={error}
              />
            )
          }}
        />
      )}
    </div>
  )
}

export default SelectEvents
