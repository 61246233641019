import { connect } from 'react-redux'
import { useEffect, useMemo, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import moment from 'moment-timezone'

import { MESSAGE_TIME_FORMAT, ROLES } from '../../utils/constants'
import './styles.css'

const ChatMessage = ({
  sentBy,
  onRead = () => {},
  externalMessageId,
  message = '',
  isRead = true,
  createdAt,
  userName = '',
  adminName = '',
  userRole = '',
  type,
}) => {
  const messageRef = useRef()
  const { inViewport } = useInViewport(messageRef)
  const time = useMemo(() => moment(createdAt).calendar(null, MESSAGE_TIME_FORMAT), [createdAt])
  const isCurrentUserMessage = useMemo(() => {
    switch (type) {
      case 'support':
        return userRole === sentBy
      case 'company':
        return [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPER_ADMIN].includes(sentBy)
    }
  }, [type, sentBy, userRole])
  const senderName = useMemo(
    () => (isCurrentUserMessage ? adminName : userName),
    [isCurrentUserMessage]
  )

  useEffect(() => {
    if (inViewport && !isRead && !isCurrentUserMessage) {
      onRead(externalMessageId)
    }
  }, [inViewport])

  return (
    <div
      ref={messageRef}
      className={`innerChatMessage ${isCurrentUserMessage ? 'userCurrentMessage' : ''}`}
    >
      <p className="chatMessage">
        {message}
        <span className="time">
          <b>{senderName}</b> | {time}
        </span>
      </p>
    </div>
  )
}

export default connect(({ user }) => ({
  userRole: user.role,
}))(ChatMessage)
