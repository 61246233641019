const IconPeopleGroups = ({ size, fill, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 96 96"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_299_34)">
        <path d="M41.08 48H54.92C58.64 48 61.44 44.68 60.84 41L59.64 33.84C58.68 28.16 53.76 24 48 24C42.24 24 37.32 28.16 36.36 33.88L35.16 41.04C34.56 44.68 37.36 48 41.08 48Z" />
        <path d="M6.64 44.44C6.12 45.48 5.92 46.72 6.24 47.96C6.88 50.72 9.28 52.08 12.36 51.96C12.36 51.96 18.32 51.96 20.16 51.96C23.48 51.96 26.2 49.64 26.2 46.8C26.2 46.24 26.08 45.72 25.92 45.2C25.88 45.08 25.88 45 25.96 44.88C26.32 44.24 26.52 43.52 26.52 42.76C26.52 41.52 25.96 40.36 25.08 39.48C24.96 39.36 24.96 39.24 25 39.08C25.28 38.28 25.28 37.36 25.04 36.48C24.4 34.76 22.84 33.6 21.08 33.52C20.96 33.52 20.88 33.48 20.8 33.4C20.12 32.56 18.88 32 17.48 32C16.28 32 15.2 32.4 14.48 33.04C14.36 33.16 14.24 33.16 14.12 33.12C13.56 32.88 12.92 32.76 12.28 32.76C9.68 32.76 7.56 34.72 7.32 37.24C7.32 37.32 7.28 37.4 7.2 37.48C6.04 38.52 5.36 40.08 5.56 41.68C5.68 42.56 6.04 43.4 6.56 44.08C6.68 44.16 6.68 44.32 6.64 44.44Z" />
        <path d="M64.96 54.6C60.28 52.52 54.52 51 48 51C41.48 51 35.72 52.56 31.04 54.6C26.72 56.52 24 60.84 24 65.56V68C24 70.2 25.8 72 28 72H68C70.2 72 72 70.2 72 68V65.56C72 60.84 69.28 56.52 64.96 54.6Z" />
        <path d="M4.88 58.32C1.92 59.6 0 62.48 0 65.72V68C0 70.2 1.8 72 4 72H18V65.56C18 62.24 18.92 59.12 20.52 56.4C19.04 56.16 17.56 56 16 56C12.04 56 8.28 56.84 4.88 58.32Z" />
        <path d="M91.12 58.32C87.72 56.84 83.96 56 80 56C78.44 56 76.96 56.16 75.48 56.4C77.08 59.12 78 62.24 78 65.56V72H92C94.2 72 96 70.2 96 68V65.72C96 62.48 94.08 59.6 91.12 58.32Z" />
        <path d="M88 44V42C88 37.6 84.4 34 80 34H72C70.32 34 69.4 35.92 70.44 37.24L73.24 39.76C72.48 41 72 42.44 72 44C72 48.4 75.6 52 80 52C84.4 52 88 48.4 88 44Z" />
      </g>
    </svg>
  )
}

export default IconPeopleGroups
