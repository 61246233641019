import { toast } from 'react-toastify'

import r from '../../utils/request'
import { snakeize } from '../../utils/helpers'

export const sendRecoveryLink = (params) => {
  return r.post('/admin/account/reset-password', params)
}

export const confirmResetPassword = (params) => {
  const formatParams = snakeize(params)
  return toast.promise(
    r.rawRequest(
      '/admin/account/reset-password/confirm',
      'put',
      { data: formatParams },
      null,
      null,
      {
        Authorization: `bearer ${params.token}`,
      }
    ),
    {
      pending: 'Loading...',
      success: 'Password reset successfully.',
      error: 'Failed to reset password.',
    }
  )
}

export const getRecoveryTokenStatus = (token) => {
  return r.rawRequest('/admin/account/reset-password/status', 'get', {}, null, null, {
    Authorization: `bearer ${token}`,
  })
}
