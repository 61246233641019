import { useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { getThemeDeliveredMessages } from '../../../api/user/theme'
import { getUserContents, toggleFavoriteContent } from '../../../api/user'
import { LoadingIcon } from '../../../components/SvgIcon'
import Content from '../../../components/Content'
import Pagination from '../../../components/Pagination'
import EmptyBlock from '../../../components/EmptyBlock'
import SubscriptionList from './components/SubscriptionList'

import './styles.css'

const Home = ({ currentCompanyPermissions }) => {
  const [selectedFeedPage, setSelectedFeedPage] = useState(1)
  const queryClient = useQueryClient()

  const mutationToggleFavorite = useMutation({
    mutationFn: toggleFavoriteContent,
    onSuccess: (_, contentId) => {
      queryClient.setQueryData(['feed', selectedFeedPage], (oldData) => {
        const listOfContents = oldData?.data?.data?.reduce((acc, item) => {
          if (item.id === contentId) {
            return [...acc, { ...item, isFavorite: !item.isFavorite }]
          }
          return [...acc, item]
        }, [])

        return { data: { ...oldData?.data, data: listOfContents } }
      })
    },
  })

  const { data: messagesInfo, isFetching: isFetchingContent } = useQuery({
    queryKey: ['feed', selectedFeedPage],
    queryFn: () => {
      const routeRequest = currentCompanyPermissions?.useContent
        ? getUserContents
        : getThemeDeliveredMessages

      return routeRequest({
        size: 10,
        page: selectedFeedPage,
      })
    },
    placeholderData: keepPreviousData,
  })

  return (
    <div className="innerHomeContent">
      <div className="innerHomeFeed">
        <h3 className="titleProfileSecond m-0">My Feed</h3>
        <div className="position-relative">
          <div className={`contentList mt-3 ${isFetchingContent ? 'minHeightContentList' : ''}`}>
            {messagesInfo?.data?.data?.map(
              ({ content, createdAt, isFavorite, id, contentLink, body }, index) => (
                <Content
                  key={index}
                  link={contentLink}
                  image={content?.imageIcon}
                  isFavorite={isFavorite}
                  deliveryDate={createdAt}
                  title={content?.subtitle}
                  artist={content?.voiceArtist}
                  message={body}
                  onClickHeart={() => mutationToggleFavorite.mutate(id)}
                />
              )
            )}
          </div>
          <Pagination
            forcePage={selectedFeedPage - 1}
            total={messagesInfo?.data?.total}
            lastPage={messagesInfo?.data?.lastPage}
            show={messagesInfo?.data?.lastPage > 1}
            innerClassName="mt-sm-2"
            onPageChange={({ selected }) => setSelectedFeedPage(selected + 1)}
          />
          {!isFetchingContent && isEmpty(messagesInfo?.data?.data) ? (
            <EmptyBlock
              innerClassName="mt-3"
              title="No Content Yet"
              imageSrc="/images/smallLogo.png"
              supTitle="Your feed is currently empty, but new content will be here soon!"
            />
          ) : null}
          {isFetchingContent && (
            <div className="innerLoader">
              <LoadingIcon size={60} className="loaderIcon" />
            </div>
          )}
        </div>
      </div>
      <SubscriptionList />
    </div>
  )
}

const maStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})

export default connect(maStateToProps)(Home)
