import { Table } from 'rsuite'

import { STATUS_CELL_TYPES } from '../../../utils/constants'
import { ThemeStatus, SubscriptionStatus, ContentStatus, Status } from '../../Statuses'

const STATUS_COMPONENTS = {
  [STATUS_CELL_TYPES.DEFAULT]: Status,
  [STATUS_CELL_TYPES.THEME]: ThemeStatus,
  [STATUS_CELL_TYPES.CONTENT]: ContentStatus,
  [STATUS_CELL_TYPES.SUBSCRIPTION]: SubscriptionStatus,
}

const StatusCell = ({
  rowData,
  dataKey,
  statusSettings = {},
  additionalTagProps,
  statusType = STATUS_CELL_TYPES.DEFAULT,
  ...props
}) => {
  const Component = STATUS_COMPONENTS[statusType]

  return (
    <Table.Cell {...props} style={{ padding: 0 }}>
      <Component
        status={rowData[dataKey]}
        {...statusSettings[rowData[dataKey]]}
        {...additionalTagProps}
        {...rowData}
      />
    </Table.Cell>
  )
}

export default StatusCell
