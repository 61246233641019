import { formatPhoneNumber } from '../../../../utils/helpers'
import CustomCheckbox from '../../../CustomCheckbox'

const UserRow = ({ userId, phone, email, toggleUserSelect, isSelected }) => {
  return (
    <div onClick={() => toggleUserSelect(userId)} className="innerUserRow">
      <CustomCheckbox value={isSelected} label="Test User" className="checkboxSelectUserGroup" />
      <div className="d-flex align-items-center gap-4 ps-2 mb-1">
        <p className="blockDescription sm mb-0">Phone</p>
        <p className="blockDescription sm mb-0">{formatPhoneNumber(phone) ?? '-'}</p>
      </div>
      <div className="d-flex align-items-center gap-4 ps-2">
        <p className="blockDescription sm mb-0">Email</p>
        <p className="blockDescription sm mb-0">{email ?? '-'}</p>
      </div>
    </div>
  )
}

export default UserRow
