import { DatePicker } from 'rsuite'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment-timezone'

import { isNullOrEmpty } from '../../utils/helpers'
import './styles.css'

const DateTimePicker = ({
  onChange,
  innerClassName = '',
  label = '',
  inputClassName = '',
  error,
  displayFormat,
  valueFormat,
  value,
  ...rest
}) => {
  return (
    <div className={innerClassName}>
      {!isEmpty(label) && <label className="inputLabel">{label}</label>}
      <DatePicker
        onChange={(value) => {
          if (isNil(value)) return onChange(value)
          onChange(moment.utc(value).format(valueFormat))
        }}
        format={displayFormat}
        className={`dateTimePickerInput ${inputClassName} ${
          error ? 'errorDateTimePickerInput' : ''
        }`}
        value={isNullOrEmpty(value) ? null : new Date(value)}
        showMeridian
        {...rest}
      />
    </div>
  )
}

export default DateTimePicker
