import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { MESSAGE_TYPES, PIPELINE_TYPES } from '../../../../utils/constants'
import { changeMessage, createMessage, getOneThemeMessage } from '../../../../api/admin/messages'
import { isNullOrEmpty } from '../../../../utils/helpers'
import { Contact as ContactIcon } from '../../../../components/SvgIcon'
import ControllerMessageInput from '../../Message/components/FormFields/ControllerMessageInput'
import PrimaryButton from '../../../../components/PrimaryButton'

const Contact = ({ isActiveTab }) => {
  const { control, formState, reset, handleSubmit } = useForm()
  const { themeId } = useParams()

  const { data: contactMessage, isLoading } = useQuery({
    queryKey: ['themeContactMessage', themeId],
    retry: 2,
    queryFn: () =>
      getOneThemeMessage({
        type: PIPELINE_TYPES.ADMIN,
        messageType: MESSAGE_TYPES.CONTACT,
        id: themeId,
        order: 1,
      }),
    enabled: isActiveTab,
  })

  const mutationUpdateThemeContactMessage = useMutation({
    mutationKey: ['updateThemeContactMessage', themeId],
    mutationFn: (formData) => {
      return !isNullOrEmpty(formData?.messageId)
        ? changeMessage({
            ...formData,
            type: PIPELINE_TYPES.ADMIN,
            messageType: MESSAGE_TYPES.CONTACT,
          })
        : createMessage({
            ...formData,
            themeId,
            type: PIPELINE_TYPES.ADMIN,
            messageType: MESSAGE_TYPES.CONTACT,
          })
    },
    onSuccess: ({ data }, formData) => {
      reset({ ...formData, messageId: data?.messageId })
    },
  })

  useEffect(() => {
    if (!isNullOrEmpty(contactMessage?.data) && isActiveTab) {
      reset({ messageId: contactMessage?.data?.messageId, body: contactMessage?.data?.body })
    }
  }, [contactMessage])

  return (
    <div className={`tabBlock ${isActiveTab ? 'visibleTabBlock' : ''}`}>
      <p className="blockTitle mb-2">Contact message</p>
      <p className="blockDescription mb-3 w -75">
        This message will be sent to the user after subscribing to the application, asking them to
        save the contact file. If they do not save it immediately, a second reminder will be sent in
        3 days. If no action is taken, another reminder will be sent in 7 days.
      </p>
      <form onSubmit={handleSubmit(mutationUpdateThemeContactMessage.mutate)}>
        <ControllerMessageInput
          name="body"
          control={control}
          showMessageCounter
          required={false}
          formState={formState}
          defaultValue=""
          messageType={MESSAGE_TYPES.CONTACT}
          pipelineType={PIPELINE_TYPES.ADMIN}
          messageConstants={{ contactUrl: '', firstName: 'User' }}
          innerClassName="innerContactMessage"
        />
        <PrimaryButton
          text="Save"
          disabled={
            !formState.isValid ||
            !formState.isDirty ||
            mutationUpdateThemeContactMessage.isPending ||
            isLoading
          }
          Icon={ContactIcon}
          customClass="big mt-2 ms-auto"
        />
      </form>
    </div>
  )
}

export default Contact
