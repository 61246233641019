import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { isNil } from 'lodash/lang'

import { ROLES } from '../../constants'
import { OTP_FIELD } from '../../constantsForms'
import { confirmLogin, loginUser } from '../../../api/auth'
import useUserAuth from '../useUserAuth'
import { camelize } from '../../helpers'

import { loginToastMessages } from './toastMessages'
import useAutoInputPhone from './useAutoInputPhone'
import useAutoLoginWithToken from './useAutoLoginWithToken'

const useUserLogin = (formRef) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const navigate = useNavigate()
  const { role = ROLES.ROLE_GUEST } = useSelector(({ user }) => ({
    role: user.role,
  }))
  const { authUser } = useUserAuth()

  const [searchParams] = useSearchParams()
  const formattedSearchParams = camelize(Object.fromEntries(searchParams))

  const redirectUser = (redirectRoute) => {
    try {
      const urlObject = new URL(formattedSearchParams?.redirectUrl)
      const { pathname, search, hash } = urlObject
      const redirectRoute = pathname + search + hash
      navigate(redirectRoute, { replace: true })
    } catch (e) {
      navigate(redirectRoute)
    }
  }

  const mutationSendConfirmCode = useMutation({
    mutationKey: ['sendConfirmCode'],
    mutationFn: (formData) => loginUser(formData, loginToastMessages),
    onSuccess: () => {
      setShowConfirm(true)
      formRef.current.updateField(0, { disabled: true })
      formRef.current.update(1, OTP_FIELD)
    },
    onError: () => {
      formRef.current?.setError('phone', {})
    },
  })

  const mutationConfirmLogin = useMutation({
    mutationKey: ['confirmLogin'],
    mutationFn: confirmLogin,
    onSuccess: ({ data }) =>
      authUser({ userData: data.user, token: data.token, withRedirect: true }),
    onError: () => {
      formRef.current?.setError('code', {})
    },
  })

  const handleLogin = (data) => {
    if (!showConfirm) {
      return mutationSendConfirmCode.mutate(data)
    }
    return mutationConfirmLogin.mutate(data)
  }

  useEffect(() => {
    if (role === ROLES.ROLE_USER && !isNil(formattedSearchParams?.redirectUrl)) {
      redirectUser()
    }
  }, [])

  useAutoLoginWithToken()
  useAutoInputPhone(formRef)

  return {
    formRef,
    handleLogin,
    mutationSendConfirmCode,
    isConfirmStep: showConfirm,
    isLoading: mutationSendConfirmCode.isPending || mutationConfirmLogin.isPending,
  }
}

export default useUserLogin
