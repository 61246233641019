import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { isNil, toNumber } from 'lodash'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Progress, Table, Tooltip, Whisper } from 'rsuite'

import {
  ALL_ACTIVE_SUBSCRIPTION_STATUSES,
  ALL_NON_RENEW_SUBSCRIPTION_STATUSES,
  DATE_FORMAT_MD,
  DATE_FORMAT_MDY,
  SETTINGS_PAGINATION,
  STATUS_CELL_TYPES,
  SUBSCRIPTABLE_TYPES,
  SUBSCRIPTION_STATUSES,
} from '../../../utils/constants'
import {
  getUserInfoById,
  getUserThemeContents,
  getUserThemeSubscription,
} from '../../../api/admin/user'
import useContent from '../../../utils/hooks/useContent'
import { FillHeart, OutlineHeart, ExternalLinkAlt } from '../../../components/SvgIcon'
import { DateCell, StarRatingCell, StatusCell } from '../../../components/Table'
import { SubscriptionStatus } from '../../../components/Statuses'
import Calendar from '../../../components/Calendar'
import Price from '../../../components/Price'
import TableUserActivity from '../../../components/TableUserActivity'
import DropdownAction from './components/DropdownAction'

import './styles.css'

const UserThemeAnalytic = ({ currentCompanyPermissions }) => {
  const [selectedReceivedContentPage, setSelectedReceivedContentPage] = useState(1)
  const { themeId, userId } = useParams()
  const { state: defaultUserInfo } = useLocation()
  const { redirectToContentByContentId } = useContent({ themeId })

  const { data: userTheme } = useQuery({
    queryKey: ['themeDetails', themeId, userId],
    queryFn: () => getUserThemeSubscription({ themeId, userId }),
  })
  const { data: userInfo } = useQuery({
    queryKey: ['userInfo', userId],
    queryFn: () => getUserInfoById(userId),
    initialData: { data: defaultUserInfo },
    enabled: isNil(defaultUserInfo),
  })

  const { data: userContents, isLoading: loadingUserContents } = useQuery({
    queryKey: ['userThemeContents', userId, themeId, selectedReceivedContentPage],
    queryFn: () =>
      getUserThemeContents({ userId, themeId, size: 10, page: selectedReceivedContentPage }),
    placeholderData: keepPreviousData,
    enabled: currentCompanyPermissions?.useContent,
  })

  return (
    <div className="innerUserThemeAnalytic">
      <p className="highlightSmallTitle">Subscriptions</p>
      <div className="d-flex align-items-center gap-2 justify-content-between border-bottom pb-4 mb-3">
        <div>
          <h1 className="sortSubscriptionInfoTitle">
            <span className="userName">{userInfo?.data?.firstName}</span> <span>on</span>{' '}
            <span className="themeName">{userTheme?.data?.theme?.name}</span>
            <SubscriptionStatus
              customClass="ms-2"
              status={userTheme?.data?.status}
              subscriptableType={userTheme?.data?.subscriptableType}
            />
          </h1>
        </div>
        <DropdownAction
          ropdown
          status={userTheme?.data?.status}
          themeName={userTheme?.data?.theme?.name}
          subscriptableType={userTheme?.data?.subscriptableType}
          endSubscription={userTheme?.data?.details?.endSubscription}
        />
      </div>
      <div className="d-flex align-items-start mb-3">
        <div className="verticalBlockSubscriptionInfo">
          <p className="label">Started</p>
          <p className="value">{moment(userTheme?.data?.createdAt).format(DATE_FORMAT_MDY)}</p>
        </div>
        {ALL_ACTIVE_SUBSCRIPTION_STATUSES.includes(userTheme?.data?.status) &&
        SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION === userTheme?.data?.subscriptableType &&
        currentCompanyPermissions?.setupPaidPrograms ? (
          <div className="verticalBlockSubscriptionInfo">
            <p className="label">Next invoice</p>
            <div className="value">
              <b>
                <Price
                  newPrice={userTheme?.data?.details?.newPrice}
                  oldPrice={userTheme?.data?.details?.oldPrice}
                  innerClassName="d-inline-block"
                  classCurrentPrice="value"
                  classOldPrice="oldPriceAdminUserTheme"
                />
              </b>
              , on {moment(userTheme?.data?.details?.endSubscription).format(DATE_FORMAT_MDY)}
            </div>
          </div>
        ) : null}
        {SUBSCRIPTABLE_TYPES.PAID_CONTENTS_SUBSCRIPTION === userTheme?.data?.subscriptableType && (
          <div className="verticalBlockSubscriptionInfo">
            <p className="label">Price</p>
            <div className="value">
              <b>
                <Price
                  newPrice={userTheme?.data?.details?.newPrice}
                  oldPrice={userTheme?.data?.details?.oldPrice}
                  innerClassName="d-inline-block"
                  classCurrentPrice="value"
                  classOldPrice="oldPriceAdminUserTheme"
                />
              </b>
            </div>
          </div>
        )}
        {ALL_NON_RENEW_SUBSCRIPTION_STATUSES.includes(userTheme?.data?.status) && (
          <div className="verticalBlockSubscriptionInfo">
            <p className="label">Ends at</p>
            <p className="value">
              {moment(userTheme?.data?.details?.endSubscription).format(DATE_FORMAT_MDY)}
            </p>
          </div>
        )}
        {SUBSCRIPTABLE_TYPES.PAID_CONTENTS_SUBSCRIPTION === userTheme?.data?.subscriptableType && (
          <div className="verticalBlockSubscriptionInfo">
            <p className="label">Remaining content</p>
            <p className="value">
              {userTheme?.data?.details?.leftAmount}/{userTheme?.data?.details?.paidAmount}
            </p>
          </div>
        )}
        {SUBSCRIPTION_STATUSES.CANCELED === userTheme?.data?.status && (
          <div className="verticalBlockSubscriptionInfo">
            <p className="label">Cancel at</p>
            <p className="value">
              {moment(userTheme?.data?.details?.endSusbcription).format(DATE_FORMAT_MDY)}
            </p>
          </div>
        )}
      </div>
      {currentCompanyPermissions?.useContent && (
        <div className="mt-4">
          <p className="blockTitle mb-0">Received content</p>
          <Table
            loading={loadingUserContents}
            data={userContents?.data?.data}
            minHeight={140}
            autoHeight
            hover={false}
            rowHeight={70}
            sortColumn="createdAt"
            sortType="desc"
            id="table-user-contents"
          >
            <Table.Column width={40} align="center">
              <Table.HeaderCell className="headColumn"></Table.HeaderCell>
              <Table.Cell verticalAlign="middle">
                {({ externalContentId }) => (
                  <div title="Open content in new tab">
                    <ExternalLinkAlt
                      size={15}
                      className="cursorPointer"
                      fill="var(--catalina-blue)"
                      onClick={() => redirectToContentByContentId(externalContentId)}
                    />
                  </div>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column minWidth={300} flexGrow={1} align="left">
              <Table.HeaderCell className="headColumn">Name</Table.HeaderCell>
              <Table.Cell verticalAlign="middle" fullText>
                {({ externalContentId, content, progress }) => {
                  const formattedProgress = isNil(progress) ? 0 : toNumber(progress)
                  return (
                    <>
                      <p className="text-truncate overflow-hidden">
                        {externalContentId} | {content?.subtitle}
                      </p>
                      <Whisper
                        followCursor
                        placement="top"
                        speaker={<Tooltip>{formattedProgress}%</Tooltip>}
                      >
                        <Progress.Line
                          className="progressContent p-0"
                          showInfo={false}
                          strokeColor="var(--catalina-blue)"
                          percent={formattedProgress}
                        />
                      </Whisper>
                    </>
                  )
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column sortable width={150} align="center">
              <Table.HeaderCell className="headColumn">Received at</Table.HeaderCell>
              <DateCell verticalAlign="middle" dataKey="createdAt" dateFormat={DATE_FORMAT_MD} />
            </Table.Column>
            <Table.Column width={150} align="center" verticalAlign="middle">
              <Table.HeaderCell className="headColumn">Status</Table.HeaderCell>
              <StatusCell statusType={STATUS_CELL_TYPES.CONTENT} dataKey="lastStatus" />
            </Table.Column>
            <Table.Column width={180} align="center" verticalAlign="middle">
              <Table.HeaderCell className="headColumn">Rating</Table.HeaderCell>
              <StarRatingCell dataKey="rating" />
            </Table.Column>
            <Table.Column width={70} align="center">
              <Table.HeaderCell className="headColumn"></Table.HeaderCell>
              <Table.Cell verticalAlign="middle">
                {({ isFavorite }) => {
                  const IconHeart = isFavorite ? FillHeart : OutlineHeart
                  return <IconHeart size={25} color="var(--catalina-blue)" />
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
          <div className="d-flex justify-content-between align-items-center mt-0">
            <p className="totalResult">{userContents?.data?.total} Result</p>
            <ReactPaginate
              {...SETTINGS_PAGINATION}
              pageCount={userContents?.data?.lastPage || 1}
              marginPagesDisplayed={-1}
              pageRangeDisplayed={-1}
              containerClassName="d-flex align-items-stretch gap-2"
              onPageChange={({ selected }) => setSelectedReceivedContentPage(selected + 1)}
            />
          </div>
        </div>
      )}

      <Calendar innerClassName="mt-4" themeId={themeId} userId={userId} />
      <div className="mt-4">
        <p className="blockTitle">Recent Activity</p>
        <TableUserActivity />
      </div>
    </div>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})

export default connect(mapStateToProps)(UserThemeAnalytic)
