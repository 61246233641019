import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty, isNil } from 'lodash'
import { connect } from 'react-redux'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { routes } from '../../../../router/routes'
import {
  getUserContents,
  getUserThemeProgress,
  toggleFavoriteContent,
  getAchievementsCounter,
} from '../../../../api/user'
import { AchievementsCrown, Fire, LoadingIcon } from '../../../../components/SvgIcon'
import Content from '../../../../components/Content'
import Pagination from '../../../../components/Pagination'
import EmptyBlock from '../../../../components/EmptyBlock'
import { getThemeDeliveredMessages } from '../../../../api/user/theme'

const ContentBlock = ({ isActiveBlock, themeId, currentCompanyPermissions }) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const enableFetchRequest = useMemo(
    () => !isNil(themeId) && isActiveBlock,
    [isActiveBlock, themeId]
  )
  const queryClient = useQueryClient()

  const mutationToggleFavorite = useMutation({
    mutationFn: toggleFavoriteContent,
    onSuccess: (_, contentId) => {
      queryClient.setQueryData(['messagesInfo', themeId, selectedPage], (oldData) => {
        const listOfMessagesInfo = oldData?.data?.data?.reduce((acc, item) => {
          if (item.id === contentId) {
            return [...acc, { ...item, isFavorite: !item.isFavorite }]
          }
          return [...acc, item]
        }, [])

        return { data: { ...oldData?.data, data: listOfMessagesInfo } }
      })
    },
  })

  const { data: { data: achievementsCounter } = {} } = useQuery({
    queryKey: ['userAchievements'],
    queryFn: () => getAchievementsCounter(themeId),
    enabled: enableFetchRequest && currentCompanyPermissions?.useContent,
  })
  const { data: { data: streakInfo } = {} } = useQuery({
    queryKey: ['userProgramProgress', themeId],
    queryFn: () => getUserThemeProgress(themeId),
    enabled: enableFetchRequest && currentCompanyPermissions?.useContent,
  })

  const { data: messagesInfo, isFetching: isFetchingContent } = useQuery({
    queryKey: ['messagesInfo', themeId, selectedPage],
    queryFn: () => {
      const routeRequest = currentCompanyPermissions?.useContent
        ? getUserContents
        : getThemeDeliveredMessages
      return routeRequest({
        themeId,
        size: 10,
        page: selectedPage,
      })
    },
    enabled: enableFetchRequest,
    placeholderData: keepPreviousData,
  })

  return (
    <section className={`tabBlock ${isActiveBlock ? 'visibleTabBlock' : ''}`}>
      {currentCompanyPermissions?.useContent && (
        <div className="d-flex flex-column flex-md-row align-items-center gap-3">
          <Link
            to={routes.userAchievements}
            className="userBlockInfo w-100 cursorPointer hover d-flex align-items-center gap-4 justify-content-between"
          >
            <div>
              <p className="userBlockInfoTitle mb-1">Achievements</p>
              <p className="userBlockSupText">
                Number of achievements:{' '}
                <b>
                  {achievementsCounter?.earned}/{achievementsCounter?.totalAchievements}
                </b>
              </p>
            </div>
            <AchievementsCrown size={50} />
          </Link>
          <div className="userBlockInfo w-100 d-flex align-items-center gap-4">
            <Fire size={50} />
            <p className="userBlockInfoTitle">
              <b>{streakInfo?.streak}</b>{' '}
              <span className="userBlockSupText d-block">day streak</span>
            </p>
          </div>
        </div>
      )}
      <div className="position-relative">
        <div className={`contentList ${isFetchingContent ? 'minHeightContentList' : ''}`}>
          {messagesInfo?.data?.data?.map(
            ({ content, createdAt, isFavorite, id, contentLink, body }, index) => (
              <Content
                key={index}
                link={contentLink}
                image={content?.imageIcon}
                isFavorite={isFavorite}
                deliveryDate={createdAt}
                title={content?.subtitle}
                artist={content?.voiceArtist}
                message={body}
                onClickHeart={() => mutationToggleFavorite.mutate(id)}
              />
            )
          )}
        </div>
        <Pagination
          forcePage={selectedPage - 1}
          total={messagesInfo?.data?.total}
          lastPage={messagesInfo?.data?.lastPage}
          show={messagesInfo?.data?.lastPage > 1}
          onPageChange={({ selected }) => setSelectedPage(selected + 1)}
        />
        {!isFetchingContent && isEmpty(messagesInfo?.data?.data) ? (
          <EmptyBlock
            innerClassName="mt-3"
            title="Content Coming Soon"
            imageSrc="/images/smallLogo.png"
            supTitle="Stay tuned—something great is about to arrive!"
          />
        ) : null}
        {isFetchingContent && (
          <div className="innerLoader">
            <LoadingIcon size={60} className="loaderIcon" />
          </div>
        )}
      </div>
    </section>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})

export default connect(mapStateToProps)(ContentBlock)
