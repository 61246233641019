import { handleActions } from 'redux-actions'
import { camelCase } from 'lodash'

import { setCurrentCompany, updateCurrentCompany } from '../actions/currentCompany'

const initialState = {
  bigLogo: null,
  createdAt: null,
  email: null,
  id: null,
  landline: null,
  name: null,
  phone: null,
  smallLogo: null,
  updatedAt: null,
  profileProgress: null,
  customLinks: [{ label: '', url: '' }],
  permissions: null,
  deliveryPhone: null,
}

const requiredFields = ['bigLogo', 'name', 'deliveryPhone', 'smallLogo']
const calculateProfileProgress = (state) => {
  if (!state) return 0

  const completedFields = requiredFields.filter(
    (field) => state[field] !== null && state[field] !== undefined
  )
  return Math.round((completedFields.length / requiredFields.length) * 100)
}
const formatPermissions = (permissionsArray) => {
  return permissionsArray?.reduce((acc, { name }) => {
    const camelizeKey = camelCase(name)
    acc[camelizeKey] = true
    return acc
  }, {})
}

const actions = {
  [setCurrentCompany]: (state, { payload }) => {
    return {
      ...payload,
      profileProgress: calculateProfileProgress(payload),
      permissions: formatPermissions(payload.permissions),
    }
  },
  [updateCurrentCompany]: (state, { payload }) => {
    const newData = { ...state, ...payload }
    return { ...newData, profileProgress: calculateProfileProgress(newData) }
  },
}

const createReducer = (defaultCurrentCompany) => {
  const data = { ...initialState, ...defaultCurrentCompany }
  return handleActions(actions, {
    ...data,
    profileProgress: calculateProfileProgress(data),
    permissions: formatPermissions(data.permissions),
  })
}

export default createReducer
