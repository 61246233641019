import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Table } from 'rsuite'

import { routes } from '../../../../router/routes'
import { ADMIN_INVITATION_STATUSES_SETTINGS, ROLE_GUEST } from '../../../../utils/constants'
import { deleteCurrentCompanyAdmin, getCurrentCompanyAdmins } from '../../../../api/admin/company'
import { clearSession } from '../../../../utils/auth'
import { setRoleUser } from '../../../../redux/actions/user'
import { setLoadingApp } from '../../../../redux/actions/ui'
import { Plus } from '../../../../components/SvgIcon'
import { ActionCell, StatusCell } from '../../../../components/Table'
import Button from '../../../../components/Button'
import Pagination from '../../../../components/Pagination'
import ModalConfirm from '../../../../components/Modals/ModalConfirm'
import ModalInviteAdmin from '../../../../components/Modals/ModalInviteAdmin'

const TableCompanyAdmins = ({ currentCompanyId, userId }) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [showModalInviteAdmin, setShowModalInviteAdmin] = useState(false)
  const [showModalConfirmDeleteAdmin, setShowModalConfirmDeleteAdmin] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigation = useNavigate()
  const queryClient = useQueryClient()

  const mutationDeleteAdmin = useMutation({
    mutationKey: ['deleteCompanyAdmin'],
    mutationFn: deleteCurrentCompanyAdmin,
    onSuccess: (_, { adminId }) => {
      setSearchParams({})
      setShowModalConfirmDeleteAdmin(false)
      queryClient.invalidateQueries({ queryKey: ['companyAdmins', currentCompanyId, selectedPage] })

      if (userId === +adminId) {
        toast.error(
          'You have been removed from the company. You are being redirected to the login page.'
        )
        clearSession()
        setRoleUser(ROLE_GUEST)
        navigation(routes.adminLogin)
      }
    },
  })

  const { data: companyAdmins, isPending } = useQuery({
    queryKey: ['companyAdmins', currentCompanyId, selectedPage],
    queryFn: () => getCurrentCompanyAdmins({ page: selectedPage, size: 10 }),
    placeholderData: keepPreviousData,
  })

  return (
    <div className="mb-5">
      <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
        <p className="blockTitle mb-0">Company Admin Management</p>
        <Button
          onClick={() => setShowModalInviteAdmin(true)}
          customClass="d-flex align-items-center gap-2"
        >
          <Plus size={15} /> New member
        </Button>
      </div>
      <p className="blockDescription underline">
        Here you can view and manage the list of administrators in your company. Use the table below
        to review admin details or perform actions such as deleting or inviting an admin.
      </p>
      <Table
        loading={isPending}
        data={companyAdmins?.data?.data}
        autoHeight
        wordWrap="break-word"
        id="table-company-admins"
      >
        <Table.Column verticalAlign="middle" width={370}>
          <Table.HeaderCell className="headColumn">Name</Table.HeaderCell>
          <Table.Cell>
            {({ firstName, lastName }) => `${firstName || ''} ${lastName || ''}`}
          </Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" minWidth={200} flexGrow={1}>
          <Table.HeaderCell className="headColumn">Email</Table.HeaderCell>
          <Table.Cell dataKey="email" />
        </Table.Column>
        <Table.Column verticalAlign="middle" align="center" width={150}>
          <Table.HeaderCell className="headColumn">Status</Table.HeaderCell>
          <StatusCell
            dataKey="status"
            additionalTagProps={{ settingsStatus: ADMIN_INVITATION_STATUSES_SETTINGS }}
          />
        </Table.Column>
        {companyAdmins?.data?.total > 1 && (
          <Table.Column verticalAlign="middle" width={50}>
            <Table.HeaderCell className="headColumn" />
            <ActionCell
              actions={[
                {
                  label: 'Delete',
                  key: 'delete',
                  function: ({ firstName, lastName, id: adminId }) => {
                    setShowModalConfirmDeleteAdmin(true)
                    setSearchParams({ adminName: `${firstName || ''} ${lastName || ''}`, adminId })
                  },
                },
              ]}
            />
          </Table.Column>
        )}
      </Table>
      <Pagination
        forcePage={selectedPage - 1}
        total={companyAdmins?.data?.total}
        lastPage={companyAdmins?.data?.lastPage}
        show={companyAdmins?.data?.lastPage > 1}
        onPageChange={({ selected }) => setSelectedPage(selected + 1)}
      />

      <ModalConfirm
        size="md"
        customClassName="md"
        show={showModalConfirmDeleteAdmin}
        textButton="Confirm delete"
        onHide={() => setShowModalConfirmDeleteAdmin(false)}
        onExited={() => setSearchParams({})}
        title="Confirm Admin Deletion"
        handleClickButton={() =>
          mutationDeleteAdmin.mutate({
            adminId: searchParams.get('adminId'),
            adminName: searchParams.get('adminName'),
          })
        }
        text={
          <>
            Are you sure you want to permanently delete admin <b>{searchParams.get('adminName')}</b>{' '}
            from this company?
          </>
        }
      />
      <ModalInviteAdmin show={showModalInviteAdmin} onHide={() => setShowModalInviteAdmin(false)} />
    </div>
  )
}

const mapStateToProps = ({ currentCompany, user }) => ({
  currentCompanyId: currentCompany.id,
  userId: user.data.id,
})

const mapDispatchToProps = { setLoadingApp }

export default connect(mapStateToProps, mapDispatchToProps)(TableCompanyAdmins)
