import { isEmpty, startCase, toNumber } from 'lodash'

import { getAllTags as getAllTagsAdmin } from '../api/admin/tags'
import { getUtmParams } from '../api/common'
import { getAllThemes } from '../api/admin/themes'
import { getAllGiftPricing } from '../api/giftCoupons'
import {
  DROPDOWN_QUESTION_TYPES,
  EMAIL_PATTERN,
  INPUT_TYPES,
  DEFAULT_SETTINGS_DROPDOWN_PICKER,
  URL_PATTERN,
  DROPDOWN_GENDERS,
  FILTER_BY_RECEIVE_STATUS,
  DROPDOWN_DATA_GIFT_STATUSES,
  ISO_DATE_FORMAT,
  DROPDOWN_DATA_THEME_ACTIVE_STATUS,
  FILTER_BY_SUBSCRIPTION_STATUSES,
  PHONE_PATTERN_STR,
  EMAIL_PATTERN_STR,
} from './constants'

export const FORM_LOGIN_ADMIN = [
  {
    name: 'email',
    innerClassName: 'innerInput',
    inputType: 'email',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Email',
    placeholder: '',
    rules: {
      required: true,
      pattern: { value: EMAIL_PATTERN_STR },
    },
    step: 1,
  },
  {
    name: 'password',
    innerClassName: 'innerInput',
    inputType: 'password',
    label: 'Password',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    placeholder: '',
    rules: {
      required: true,
    },
  },
]

export const RESET_PASSWORD_EMAIL_FIELD = [
  {
    name: 'email',
    innerClassName: 'innerInput',
    inputType: 'email',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Your email',
    placeholder: '',
    rules: {
      required: true,
      pattern: { value: EMAIL_PATTERN_STR },
    },
  },
]

export const FORM_LOGIN_USER = [
  {
    name: 'phone',
    type: INPUT_TYPES.INPUT_MASK,
    inputType: 'tel',
    defaultValue: '',
    placeholder: '+1 (999) 999-9999',
    rules: {
      required: true,
      pattern: {
        value: PHONE_PATTERN_STR,
        message: 'Not valid number',
      },
    },
  },
]

export const OTP_FIELD = {
  name: 'code',
  type: INPUT_TYPES.OTP,
  defaultValue: '',
  rules: {
    required: true,
  },
  isInputNum: true,
  maxCountAutoSubmit: 1,
  autocomplete: 'one-time-code',
  shouldAutoFocus: true,
}

export const MANAGE_PROGRAM_FIELDS = [
  {
    name: 'name',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Program name',
    placeholder: 'Write new program name',
    rules: {
      required: true,
    },
  },
]

export const MANAGE_GROUP_FIELDS = [
  {
    name: 'name',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Group name',
    placeholder: 'Create new group name',
    rules: {
      required: true,
    },
  },
]

export const FORM_CHANGE_USER_DATA = [
  {
    name: 'phone',
    type: INPUT_TYPES.INPUT_MASK,
    label: 'Phone',
    defaultValue: '',
    innerClassName: 'innerInputDetails',
    rules: {
      required: false,
    },
  },
  {
    name: 'timezone',
    type: INPUT_TYPES.INPUT_SELECT_TIMEZONE,
    title: 'Select timezone',
    label: 'Timezone',
    defaultValue: '',
    innerDropdown: 'innerInputDetails underline',
    rules: {
      required: false,
    },
  },
  {
    name: 'firstName',
    innerClassName: 'innerInputDetails',
    label: 'First name',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    placeholder: 'Enter first name',
    rules: {
      required: false,
    },
  },
  {
    name: 'lastName',
    innerClassName: 'innerInputDetails',
    type: INPUT_TYPES.TEXT,
    label: 'Last name',
    defaultValue: '',
    placeholder: 'Enter last name',
    rules: {
      required: false,
    },
  },
  {
    name: 'email',
    innerClassName: 'innerInputDetails underline',
    inputType: 'email',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Email',
    placeholder: 'Enter email',
    rules: {
      required: false,
      pattern: {
        value: EMAIL_PATTERN,
      },
    },
  },
  {
    name: 'gender',
    type: INPUT_TYPES.INPUT_DROPDOWN,
    title: 'Select sex',
    defaultValue: '',
    label: 'Sex',
    data: DROPDOWN_GENDERS,
    innerDropdown: 'innerInputDetails',
    rules: {
      required: false,
    },
  },
  {
    name: 'familyStatus',
    type: INPUT_TYPES.INPUT_DROPDOWN,
    title: 'Select family status',
    label: 'Family status',
    defaultValue: '',
    data: [{ title: 'Single' }, { title: 'Married' }],
    innerDropdown: 'innerInputDetails underline',
    rules: {
      required: false,
    },
  },
  {
    name: 'birthdate',
    innerClassName: 'innerInputDetails',
    type: INPUT_TYPES.INPUT_DATE_TIME,
    label: 'Date of birth',
    defaultValue: '',
    valueFormat: 'YYYY-MM-DD',
    placement: 'autoVertical',
    displayFormat: 'MMM dd, yyyy',
    shouldDisableDate: (date) => date > new Date(),
    rules: {
      required: false,
    },
  },
]

export const TAG_FIELD = {
  name: 'tagInfo',
  type: INPUT_TYPES.INPUT_TAG,
  placeholder: '',
  defaultValue: { name: '', color: '#000000', isVisibleToUser: false },
  showPreview: true,
  rules: {
    required: true,
    validate: (value) => !isEmpty(value),
  },
}

export const FIELD_CANCEL_ACCOUNT = [
  {
    name: 'reason',
    as: 'textarea',
    innerClassName: 'inputReasonCancelSubscription',
    placeholder: 'Tell us more...',
    defaultValue: '',
    rules: {
      required: false,
    },
  },
]

export const FORM_FIELD_CHAT = {
  name: 'message',
  innerClassName: 'inputChat',
  type: INPUT_TYPES.TEXT,
  as: 'textarea',
  defaultValue: '',
  placeholder: 'Write a message...',
  rules: {
    required: true,
  },
}

export const FIELD_USER_NOTES = [
  {
    name: 'note',
    as: 'textarea',
    innerClassName: 'textareaMessage',
    placeholder: 'Notes...',
    defaultValue: '',
    rules: {
      required: false,
    },
  },
]

export const FIELDS_FILTER_ALL_USERS = [
  {
    name: 'registerRange',
    type: INPUT_TYPES.INPUT_DATE_TIME_RANGE,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Range of subscription date',
    cleanable: true,
    pickerProps: { editable: false, shouldDisableDate: ({ afterToday }) => afterToday() },
  },
  {
    name: 'themeId',
    type: INPUT_TYPES.INPUT_DROPDOWN_PICKER,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Themes',
    placeholder: 'Choose themes',
    getData: getAllThemes,
    ...DEFAULT_SETTINGS_DROPDOWN_PICKER,
    cleanable: true,
  },
  {
    name: 'status',
    type: INPUT_TYPES.INPUT_DROPDOWN_PICKER,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Subscription statuses',
    placeholder: 'Choose subscription statuses',
    getData: async () => ({ data: FILTER_BY_SUBSCRIPTION_STATUSES }),
    ...DEFAULT_SETTINGS_DROPDOWN_PICKER,
    labelKey: 'title',
    valueKey: 'value',
    cleanable: true,
  },
  {
    name: 'canSendMessage',
    type: INPUT_TYPES.INPUT_DROPDOWN,
    rules: {
      required: false,
    },
    label: 'Receiving status',
    title: 'Choose receiving statuses',
    innerDropdown: 'mw-100',
    data: FILTER_BY_RECEIVE_STATUS,
    valueKey: 'value',
  },
  {
    name: 'contentDateDeliveryRange',
    type: INPUT_TYPES.INPUT_DATE_TIME_RANGE,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Range of content delivery date',
    permissions: ['useContent'],
    cleanable: true,
    pickerProps: {
      editable: false,
      placement: 'autoVerticalEnd',
      shouldDisableDate: ({ afterToday }) => afterToday(),
    },
  },
  {
    name: 'finishedContentRange',
    type: INPUT_TYPES.INPUT_RANGE,
    rules: {
      required: false,
      validate: (value) => {
        const firstItemRange = toNumber(value[0])
        const secondItemRange = toNumber(value[1])
        return isEmpty(value) || (firstItemRange >= 0 && secondItemRange >= firstItemRange)
      },
    },
    permissions: ['useContent'],
    defaultValue: [],
    inputSettings: {
      inputType: 'number',
    },
    firstInputSettings: {
      placeholder: '0',
    },
    secondInputSettings: {
      placeholder: '100',
    },
    label: 'Range of finished contents',
  },
  {
    name: 'averageCompletionRange',
    type: INPUT_TYPES.INPUT_RANGE,
    rules: {
      required: false,
      validate: (value) => {
        const firstItemRange = toNumber(value[0])
        const secondItemRange = toNumber(value[1])
        return isEmpty(value) || (firstItemRange >= 0 && secondItemRange >= firstItemRange)
      },
    },
    permissions: ['useContent'],
    defaultValue: [],
    inputSettings: {
      inputType: 'number',
    },
    firstInputSettings: {
      placeholder: '0',
    },
    secondInputSettings: {
      placeholder: '100',
    },
    label: 'Range of avg. completion',
  },
  {
    name: 'utm',
    type: INPUT_TYPES.INPUT_DROPDOWN_PICKER,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Utm params',
    placeholder: 'Choose utm params',
    getData: async () =>
      await getUtmParams().then(({ data }) => {
        const transformedUtm = []

        for (const [type, values] of Object.entries(data)) {
          values.forEach((value) => {
            transformedUtm.push({ type: startCase(type), value })
          })
        }

        return { data: transformedUtm }
      }),
    ...DEFAULT_SETTINGS_DROPDOWN_PICKER,
    labelKey: 'value',
    valueKey: 'value',
    groupBy: 'type',
    cleanable: true,
  },
]

export const FIELDS_FILTER_GIFT_ANALYTIC = [
  {
    name: 'redeemedAt',
    type: INPUT_TYPES.INPUT_DATE_TIME_RANGE,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Select the date range for coupon activation',
    cleanable: true,
    valueFormat: ISO_DATE_FORMAT,
    pickerProps: { editable: false, shouldDisableDate: ({ afterToday }) => afterToday() },
  },
  {
    name: 'purchasedAt',
    type: INPUT_TYPES.INPUT_DATE_TIME_RANGE,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Select the date range of coupon purchases',
    cleanable: true,
    valueFormat: ISO_DATE_FORMAT,
    pickerProps: { editable: false, shouldDisableDate: ({ afterToday }) => afterToday() },
  },
  {
    name: 'priceIds',
    type: INPUT_TYPES.INPUT_DROPDOWN_PICKER,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Gifts',
    placeholder: 'Choose gifts',
    getData: getAllGiftPricing,
    ...DEFAULT_SETTINGS_DROPDOWN_PICKER,
    cleanable: true,
  },
  {
    name: 'statuses',
    type: INPUT_TYPES.INPUT_DROPDOWN_PICKER,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Coupon statuses',
    placeholder: 'Choose coupon statuses',
    getData: async () => ({ data: DROPDOWN_DATA_GIFT_STATUSES }),
    ...DEFAULT_SETTINGS_DROPDOWN_PICKER,
    labelKey: 'title',
    valueKey: 'value',
    cleanable: true,
  },
]

export const FIELD_CREATE_QUESTION = [
  {
    name: 'name',
    defaultValue: '',
    innerClassName: 'flex-grow-1',
    placeholder: 'Question...',
    rules: {
      required: true,
    },
  },
  {
    name: 'type',
    type: INPUT_TYPES.INPUT_DROPDOWN,
    title: 'Select type',
    defaultValue: DROPDOWN_QUESTION_TYPES[0],
    data: DROPDOWN_QUESTION_TYPES,
    titleClass: 'dropzoneTitle',
    innerDropdown: 'dropdownQuestionTypes',
    rules: {
      required: true,
    },
  },
]

export const FIELDS_GIFT_COUPONS = [
  {
    name: 'image',
    type: INPUT_TYPES.INPUT_FILE,
    defaultValue: '',
    rules: {
      required: false,
    },
  },
  {
    name: 'name',
    type: INPUT_TYPES.TEXT,
    label: 'Name',
    defaultValue: '',
    placeholder: 'Premium plan, sunglasses, etc.',
    rules: {
      required: true,
    },
  },
  {
    name: 'description',
    type: INPUT_TYPES.TEXT,
    as: 'textarea',
    rows: 4,
    label: 'Description',
    defaultValue: '',
    rules: {
      required: false,
    },
  },
  {
    name: 'price',
    type: INPUT_TYPES.INPUT_MASK,
    label: 'Price per month',
    innerClassName: 'innerPriceInput',
    placeholder: '0.00',
    showIconInfo: true,
    infoText:
      'Every gift coupon may extend the subscription period active period. Currently, a user can buy gift coupons only with an extension period of 12 months.  The price represents a price for 1 month.',
    defaultValue: '',
    isNumberMask: true,
    defaultMaskOptions: {
      prefix: '',
      suffix: '',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ' ',
      allowDecimal: true,
      decimalSymbol: '.',
      decimalLimit: 2,
      integerLimit: 7,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
    rules: {
      required: true,
    },
  },
  {
    name: 'stripeCouponId',
    type: INPUT_TYPES.INPUT_DROPDOWN,
    label: 'Discount',
    title: '0%',
    showIconInfo: true,
    infoText:
      'Select the discount to apply to the gift coupon price. Discounts may be presented as percentages (e.g., 10%) or fixed amounts (e.g., $5). Choose the desired discount from the list to adjust the final price of the gift coupon.',
    defaultValue: '',
    data: [],
    titleKey: 'discountLabel',
    valueKey: 'id',
    innerDropdown: 'mw-100',
    rules: {
      required: false,
    },
  },
  {
    name: 'landingUrl',
    type: INPUT_TYPES.TEXT,
    label: 'URL to registration form',
    placeholder: 'https://www.daydrop.me',
    defaultValue: '',
    inputType: 'url',
    showBottomDescription: true,
    bottomDescription:
      'URL to the registration form where the gift coupon will be automatically applied. The URL is used for gift coupon redemption, allowing the recipient to access the form with the coupon pre-applied.',
    rules: {
      required: true,
      pattern: { value: URL_PATTERN },
    },
  },
]

export const FIELDS_DUPLICATE_THEME = [
  {
    name: 'content',
    type: INPUT_TYPES.CHECKBOX,
    label: 'Copy Content pipeline',
    className: 'd-block',
    defaultValue: true,
    rules: { required: false },
  },
  {
    name: 'admin',
    type: INPUT_TYPES.CHECKBOX,
    defaultValue: false,
    rules: { required: false },
    label: 'Copy Admin pipeline',
    className: 'd-block',
  },
  {
    name: 'abandonedCart',
    type: INPUT_TYPES.CHECKBOX,
    defaultValue: false,
    rules: { required: false },
    label: 'Copy Abandoned pipeline',
    className: 'd-block',
  },
  {
    name: 'includeTags',
    type: INPUT_TYPES.CHECKBOX,
    defaultValue: false,
    rules: { required: false },
    label: 'Copy theme Tags',
    className: 'd-block',
  },
  {
    name: 'themeName',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    rules: { required: true },
    label: 'Theme Name',
    placeholder: 'Write new theme name',
    innerClassName: 'mt-2',
    className: 'd-block',
  },
]

export const FIELDS_FILTER_PROGRAM_THEMES = [
  {
    name: 'dateCollectionRange',
    type: INPUT_TYPES.INPUT_DATE_TIME_RANGE,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Select date range',
    cleanable: true,
    valueFormat: ISO_DATE_FORMAT,
    pickerProps: { editable: false, shouldDisableDate: ({ afterToday }) => afterToday() },
  },
  {
    name: 'isPublished',
    type: INPUT_TYPES.INPUT_DROPDOWN_PICKER,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Theme statuses',
    placeholder: 'Choose theme statuses',
    getData: async () => ({ data: DROPDOWN_DATA_THEME_ACTIVE_STATUS }),
    ...DEFAULT_SETTINGS_DROPDOWN_PICKER,
    labelKey: 'title',
    valueKey: 'value',
    cleanable: true,
  },
  {
    name: 'tags',
    type: INPUT_TYPES.INPUT_DROPDOWN_PICKER,
    defaultValue: [],
    rules: {
      required: false,
    },
    placeholder: 'Select program tags',
    label: 'Tags',
    getData: () => getAllTagsAdmin({ withPagination: false }),
    ...DEFAULT_SETTINGS_DROPDOWN_PICKER,
  },
]

export const PURCHASE_GIFT_COUPON_FIELDS = [
  {
    name: 'name',
    type: INPUT_TYPES.TEXT,
    label: 'Name',
    innerClassName: 'innerInputNameReceiver',
    defaultValue: '',
    rules: {
      required: true,
    },
  },
  {
    name: 'email',
    type: INPUT_TYPES.TEXT,
    label: 'Email',
    innerClassName: 'innerInputEmailReceiver',
    defaultValue: '',
    rules: {
      required: true,
      pattern: {
        value: EMAIL_PATTERN,
        message: 'Not valid email',
      },
    },
  },
  {
    name: 'message',
    type: INPUT_TYPES.TEXT,
    innerClassName: 'innerInputMessage',
    label: 'Message',
    defaultValue: '',
    as: 'textarea',
    rows: 6,
    rules: {
      required: true,
    },
  },
  {
    name: 'senderName',
    type: INPUT_TYPES.TEXT,
    label: 'Your name',
    innerClassName: 'innerInputSenderName',
    defaultValue: 'Friend',
    rules: {
      required: true,
    },
  },
  {
    name: 'deliverAt',
    type: INPUT_TYPES.INPUT_DATE_TIME,
    label: 'Delivery date',
    innerClassName: 'innerInputDeliveryTime',
    defaultValue: null,
    valueFormat: 'yyyy-MM-DD HH:mm',
    placement: 'auto',
    format: 'yyyy-MM-dd HH:mm',
    showMeridian: true,
    shouldDisableDate: (date) => {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    rules: {
      required: true,
    },
  },
]

export const FIELDS_FILTER_DASHBOARD = [
  {
    name: 'themeId',
    type: INPUT_TYPES.INPUT_DROPDOWN,
    rules: {
      required: false,
    },
    label: 'Theme',
    title: 'Select a theme',
    innerDropdown: 'mw-100',
    getDropdownData: getAllThemes,
    valueKey: 'id',
    titleKey: 'name',
    customDropdownMenu: 'w-100',
  },
  {
    name: 'dateCollectionRange',
    type: INPUT_TYPES.INPUT_DATE_TIME_RANGE,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Select the date range',
    cleanable: true,
    valueFormat: ISO_DATE_FORMAT,
    pickerProps: {
      editable: false,
      shouldDisableDate: ({ afterToday, allowedMaxDays, combine }) =>
        combine(allowedMaxDays(31), afterToday()),
    },
  },
]

export const FIELDS_FILTER_TAGS = [
  {
    name: 'themeIds',
    type: INPUT_TYPES.INPUT_DROPDOWN_PICKER,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Themes',
    placeholder: 'Choose themes',
    getData: getAllThemes,
    ...DEFAULT_SETTINGS_DROPDOWN_PICKER,
    cleanable: true,
  },
  {
    name: 'isVisibleToUser',
    type: INPUT_TYPES.INPUT_DROPDOWN,
    defaultValue: [],
    rules: {
      required: false,
    },
    label: 'Visible status for users',
    placeholder: 'Choose visible status',
    innerDropdown: 'mw-100',
    customDropdownMenu: 'w-100',
    data: [
      { title: 'Visible for users', value: true },
      { title: 'Not visible for users', value: false },
      { title: 'All', value: null },
    ],
    title: 'All',
    valueKey: 'value',
    cleanable: true,
  },
]

export const FIELDS_INVITE_ADMIN = [
  {
    name: 'email',
    inputType: 'email',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    placeholder: 'andy@example.com, etc.',
    label: 'Enter team member email address',
    innerLabelClassName: 'mb-2',
    rules: {
      required: true,
      pattern: {
        value: EMAIL_PATTERN_STR,
      },
    },
  },
]

export const VCF_FIELDS = [
  {
    name: 'contactImg',
    dropzoneClassName: 'imageContact',
    type: INPUT_TYPES.INPUT_FILE,
    defaultValue: '',
    rules: {
      required: true,
    },
  },
  {
    name: 'name',
    innerClassName: 'inputVcf',
    label: 'Contact name',
    defaultValue: '',
    placeholder: '',
    rules: {
      required: true,
    },
  },
]
