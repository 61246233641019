import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { connect } from 'react-redux'

import { routes } from '../../router/routes'
import { ADMIN_INVITATION_STATUSES, INPUT_TYPES } from '../../utils/constants'
import {
  adminAcceptInvitation,
  getAdminInvitationStatus,
  setUpAdminOnInvite,
} from '../../api/admin/company'
import { getMe } from '../../api/auth'
import { setLoadingApp } from '../../redux/actions/ui'
import { updateToken } from '../../utils/auth'
import useUserAuth from '../../utils/hooks/useUserAuth'
import HookForm from '../../components/HookForm'
import LayoutGuest from '../../components/LayoutGuest'
import PrimaryButton from '../../components/PrimaryButton'

import { generateFormFields } from './generateFormFields'
import './styles.css'

const ConfirmInvitationAdmin = ({ setLoadingApp }) => {
  const formRef = useRef(null)
  const formFields = useMemo(() => generateFormFields(formRef), [])

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const { authUser } = useUserAuth()

  const authAdmin = useCallback(
    async (token) => {
      try {
        setLoadingApp(true)
        updateToken(token)
        const { data: userData } = await getMe()
        await authUser({ userData, token, withRedirect: false })
        navigate(routes.accountSettings)
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => setLoadingApp(false), 1000)
      }
    },
    [authUser, navigate, setLoadingApp]
  )

  const mutationAdminAcceptInvitation = useMutation({
    mutationKey: ['adminAcceptInvitation'],
    mutationFn: (formData) => adminAcceptInvitation({ ...formData, token }),
    onSuccess: ({ data }) => authAdmin(data?.token),
    onError: () => formRef.current.setError('password', 'Invalid password'),
  })

  const mutationSetUpAdminOnInvite = useMutation({
    mutationKey: ['setUpAdminOnInvite'],
    mutationFn: (formData) => setUpAdminOnInvite({ ...formData, token }),
    onSuccess: ({ data }) => authAdmin(data.token),
  })

  const {
    data: adminInvitationStatus,
    isLoading: isLoadingAdminInvitationStatus,
    isError: isErrorAdminInvitationStatus,
  } = useQuery({
    queryKey: ['adminInvitationStatus'],
    queryFn: () => getAdminInvitationStatus(token),
    enabled: Boolean(token),
  })

  useEffect(() => {
    if (
      !token ||
      isErrorAdminInvitationStatus ||
      adminInvitationStatus?.data?.status === ADMIN_INVITATION_STATUSES.CONFIRMED
    ) {
      navigate(routes.home)
    }
    setLoadingApp(isLoadingAdminInvitationStatus)
  }, [
    token,
    isLoadingAdminInvitationStatus,
    isErrorAdminInvitationStatus,
    adminInvitationStatus,
    navigate,
    setLoadingApp,
  ])

  const hasPassword = useMemo(
    () => adminInvitationStatus?.data?.hasPassword,
    [adminInvitationStatus]
  )
  const formFieldsToUse = useMemo(
    () =>
      hasPassword
        ? [
            {
              name: 'password',
              innerClassName: 'innerInput',
              inputType: 'password',
              type: INPUT_TYPES.TEXT,
              defaultValue: '',
              label: 'Password*',
              placeholder: '',
              rules: { required: true },
            },
          ]
        : formFields,
    [hasPassword]
  )

  return (
    <LayoutGuest>
      <div className="innerCenterForm container">
        <div className="innerForm">
          <h1 className="blockTitle mb-2">
            {hasPassword ? 'Confirm Invitation' : 'Create account'}
          </h1>
          <p className="blockDescription mb-0">
            {hasPassword
              ? 'To confirm invite please enter your password.'
              : 'To accept the invitation, please create an account first, then you will be automatically added to the company.'}
          </p>
          <HookForm
            className="confirmInvitationForm"
            ref={formRef}
            onSubmit={
              hasPassword ? mutationAdminAcceptInvitation.mutate : mutationSetUpAdminOnInvite.mutate
            }
            fields={formFieldsToUse}
            Footer={({ formState }) => (
              <PrimaryButton
                disabled={
                  !formState.isValid ||
                  mutationAdminAcceptInvitation.isPending ||
                  mutationSetUpAdminOnInvite.isPending
                }
                customClass="big buttonSubmit"
                text="Confirm"
              />
            )}
          />
        </div>
      </div>
    </LayoutGuest>
  )
}

const mapDispatchToProps = { setLoadingApp }

export default connect(null, mapDispatchToProps)(ConfirmInvitationAdmin)
