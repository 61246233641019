import { useState } from 'react'

import { changeSurveyInfo } from '../../api/survey'
import { Pencil } from '../SvgIcon'
import HookForm from '../HookForm'
import PrimaryButton from '../PrimaryButton'

const SurveyFinalText = ({ finalText, handleChangeSurvey, id }) => {
  const [isEditingMode, setIsEditingMode] = useState(false)

  const handleSubmit = (data) => {
    changeSurveyInfo({ id, ...data }, { success: 'Final text was changed' })
      .then(handleChangeSurvey)
      .then(() => setIsEditingMode(false))
  }

  if (isEditingMode) {
    return (
      <HookForm
        onSubmit={handleSubmit}
        fields={[
          {
            name: 'final_text',
            innerClassName: 'surveyOptionInput flex-grow-1',
            defaultValue: finalText,
            placeholder: 'Thanks for completing survey...',
            rules: {
              required: true,
            },
          },
        ]}
        className="mt-3 d-flex gap-2 align-items-stretch"
        Footer={() => <PrimaryButton customClass="p-2 ps-4 pe-4" text="Save" />}
      />
    )
  }

  return (
    <div className="d-flex align-items-center gap-3 mt-3 pe-3">
      <h5 className="questionOptionCircle flex-grow-1">{finalText}</h5>
      <Pencil onClick={() => setIsEditingMode(true)} className="pencilSurvey" size={20} />
    </div>
  )
}

export default SurveyFinalText
