import r from '../utils/request'

export const sendAdminMessage = (params) => {
  return r.post(`/admin/users/ids/send-message`, params)
}

export const sendAdminMultipleMessage = (params) => {
  return r.postWithToast(`/admin/users/ids/send-message`, params, {
    success: 'Successful send multiple message',
  })
}
export const sendCompanySupportMessage = (params) => {
  return r.post('/admin/companies/support/send-message', params)
}
export const sendSuperAdminMessage = (params) => {
  return r.post(`/admin/companies/${params.companyId}/send-message`, params)
}

export const getDialogMessages = (params) => {
  return r.get(`/admin/users/${params.userId}/dialog`, params)
}
export const getOldestDateMessage = (userId) => {
  return r.get(`/admin/users/${userId}/sms/oldest`)
}

export const getDialogWithSupport = (params) => {
  return r.get(`/admin/companies/support`, params)
}
export const getOldestDateSupportMessage = () => {
  return r.get('/admin/companies/support/sms/oldest')
}

export const getDialogMessagesByCompanyId = (params) => {
  return r.get(`/admin/companies/${params.companyId}/dialog`, params)
}
export const getOldestDateCompanyMessage = (companyId) => {
  return r.get(`/admin/companies/${companyId}/sms/oldest`)
}

export const getChats = (params) => {
  return r.get('/admin/users/chats', params)
}

export const toggleStarred = (params) => {
  return r.put('/admin/users/toggle-staring', params)
}

export const toggleAttention = (userId) => {
  return r.put(`/admin/users/${userId}/needs-attention/toggle`)
}
