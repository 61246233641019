import { useState } from 'react'

import AccountDetails from './tabs/AccountDetails'
import Settings from './tabs/Settings'
import GeneralMessages from './tabs/GeneralMessages'

import './styles.css'

const OrganizationSettings = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="containerAdminMainInfo">
      <h1 className="titleAdminPage">Business</h1>
      <div className="d-flex align-items-center tabsList bottomLine mb-4">
        {['Account details', 'General messages', 'Settings'].map((tabName, index) => (
          <button
            key={tabName}
            onClick={() => setActiveTab(index)}
            className={`tab fullLine ${index === activeTab ? 'active cursorDefault' : ''}`}
          >
            {tabName}
          </button>
        ))}
      </div>
      <AccountDetails isActiveTab={activeTab === 0} />
      <GeneralMessages isActiveTab={activeTab === 1} />
      <Settings isActiveTab={activeTab === 2} />
    </div>
  )
}

export default OrganizationSettings
