import { useState } from 'react'

import { ArrowDown, Plus } from '../SvgIcon'
import ImageLoader from '../ImageLoader'

import './styles.css'

const OrganizationSwitcher = ({
  orgList,
  onSwitchOrg,
  selectedOrg,
  innerClassName = '',
  showBtnCreateCompany = false,
  onClickBtnCreateCompany = () => {},
}) => {
  const [showOrganizationList, setShowOrganizationList] = useState(false)

  return (
    <div className={`innerOrganizationSwitcher ${innerClassName}`}>
      <button
        className="btnSwitcher"
        onClick={() => setShowOrganizationList((oldState) => !oldState)}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowOrganizationList(false)
          }
        }}
      >
        <ImageLoader
          src={selectedOrg?.smallLogo}
          loadingProps={{ size: 15 }}
          alt={`Logo ${selectedOrg?.name}`}
          imageClassName="smallLogo"
        />
        <span className="organizationName">{selectedOrg?.name}</span>
        {orgList?.length > 1 && <ArrowDown className="arrow" size={22} style={{ minWidth: 22 }} />}
      </button>
      <div className="innerOrganizationList" data-show-list={showOrganizationList}>
        <ul className="organizationList">
          {orgList?.map(({ id, name, smallLogo }) => (
            <li
              onClick={() => {
                if (selectedOrg?.id !== id) {
                  onSwitchOrg(id)
                  setShowOrganizationList(false)
                }
              }}
              onMouseDown={(e) => e.preventDefault()}
              key={id}
              aria-checked={selectedOrg?.id === id}
              className="organizationListItem"
            >
              <ImageLoader
                src={smallLogo}
                loadingProps={{ size: 15 }}
                alt={`Logo ${name}`}
                imageClassName="smallLogo"
              />
              <span className="organizationName">{name}</span>
            </li>
          ))}
        </ul>
        {showBtnCreateCompany && (
          <div className="innerBtnCreateCompany">
            <button
              onClick={onClickBtnCreateCompany}
              className="organizationListItem btnCreateCompany"
              onMouseDown={(e) => e.preventDefault()}
            >
              Create new company <Plus size={17} fill="var(--catalina-blue)" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default OrganizationSwitcher
