import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toggle, Tooltip, Whisper } from 'rsuite'
import { Accordion, AccordionContext, Card, useAccordionButton } from 'react-bootstrap'

import { activateSurvey, deactivateSurvey } from '../../api/survey'
import { ArrowDown, Pencil, Analytics, Info, BinaryTree } from '../SvgIcon'

const SurveyContextAwareToggle = ({
  eventKey,
  callback,
  title,
  timeAction,
  onPencilClick,
  surveyId,
  itemSurvey,
  handleChangeSurvey,
  selectShowSurveyTree,
}) => {
  const [updatingSurveyStatus, setUpdatingSurveyStatus] = useState(false)
  const navigation = useNavigate()
  const { activeEventKey } = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey))

  const isCurrentEventKey = activeEventKey === eventKey

  const redirectToSurveyAnalytic = useCallback((e) => {
    e.stopPropagation()
    navigation(`/admin/survey/${surveyId}`)
  }, [])

  const toggleStatusSurvey = useCallback(async (checked) => {
    setUpdatingSurveyStatus(true)

    try {
      if (checked) {
        await activateSurvey(surveyId)
      } else {
        await deactivateSurvey(surveyId)
      }

      handleChangeSurvey({ data: { isPublished: checked, id: surveyId } })
    } catch (error) {
      console.error('Error toggling survey status:', error)
    } finally {
      setUpdatingSurveyStatus(false)
    }
  }, [])

  const handleClickIconTreeSurvey = useCallback((e) => {
    e.stopPropagation()
    selectShowSurveyTree(surveyId)
  }, [])

  return (
    <Card.Header
      onClick={decoratedOnClick}
      className="d-flex align-items-center justify-content-between gap-2 cursorPointer"
    >
      <h5 className="surveyTitleAccordion">{title}</h5>
      <div className="d-flex align-items-center gap-2">
        <Toggle
          onChange={toggleStatusSurvey}
          size="lg"
          loading={updatingSurveyStatus}
          checked={itemSurvey.isPublished}
          checkedChildren="Deactivate"
          unCheckedChildren="Activate"
        />
        <BinaryTree onClick={handleClickIconTreeSurvey} size={30} />
        <Whisper placement="top" trigger="hover" speaker={<Tooltip>{timeAction}</Tooltip>}>
          <div>
            <Info size={30} className="cursorDefault" />
          </div>
        </Whisper>
        <Analytics title="Open Survey Analytics" onClick={redirectToSurveyAnalytic} size={30} />
        <Pencil onClick={onPencilClick} size={20} />
        <ArrowDown
          size={30}
          className={`arrowButtonAccordion ${
            isCurrentEventKey ? 'arrowButtonAccordionActive' : ''
          }`}
        />
      </div>
    </Card.Header>
  )
}

const SurveyAccordion = ({ children, title, ...rest }) => {
  return (
    <Accordion className="innerAccordion mb-3" defaultActiveKey={null}>
      <SurveyContextAwareToggle title={title} eventKey="0" {...rest} />
      <Accordion.Collapse eventKey="0">
        <Card.Body className="accordionChildren">{children}</Card.Body>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default SurveyAccordion
