import r from '../../utils/request'

export const getCompanyInfoById = (companyId) => {
  return r.get(`/admin/companies/${companyId}`)
}

export const getAvailablePhones = () => {
  return r.get('/admin/available-phones')
}

export const getAllCompanies = () => {
  return r.get('/admin/companies')
}

export const getCompaniesChats = () => {
  return r.get('/admin/companies/chats')
}
