import { useCallback, useMemo, useRef, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { forEach, omitBy } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'

import { routes } from '../../../router/routes'
import { ROLES } from '../../../utils/constants'
import { getInfoCurrentCompany } from '../../../api/admin/company'
import { confirmRegistrationCompany, registrationCompany } from '../../../api/registration'
import { setRoleUser } from '../../../redux/actions/user'
import { setCurrentCompany } from '../../../redux/actions/currentCompany'
import { updateToken } from '../../../utils/auth'
import useWebSocket from '../../../utils/hooks/useWebSocket'
import { useWebsocketContext } from '../../../components/SocketProvider'
import { isNullOrEmpty } from '../../../utils/helpers'
import LayoutGuest from '../../../components/LayoutGuest'
import HookFormFieldsArray from '../../../components/HookForm/HookFormFieldsArray'
import { createFormFields } from './createFormFields'
import Footer from './component/Footer'

import './styles.css'

const Organization = ({ setRoleUser, setCurrentCompany }) => {
  const [formStep, setFormStep] = useState(1)
  const formRef = useRef(null)
  const registrationFields = useMemo(() => createFormFields(formRef), [formRef])
  const navigation = useNavigate()

  const { connect } = useWebSocket()
  const { handleWebSocketSubscription } = useWebsocketContext()

  const mutationRegistrationCompany = useMutation({
    mutationKey: ['registrationCompany'],
    mutationFn: (formData) => registrationCompany(formData),
    onSuccess: () => {
      registrationFields.forEach((field, index) => {
        const isFirstStep = field?.step === 1
        const isSecondStep = field?.step === 2

        if (isFirstStep) {
          formRef.current.updateField(index, { innerClassName: 'innerInputRegistration hide' })
        }
        if (isSecondStep) {
          formRef.current.updateField(index, {
            innerClassName: 'innerInputRegistration otpCode',
            rules: { required: true },
          })
        }
      })
      formRef.current.reset({}, { keepValues: true })
      setFormStep(2)
    },
    onError: ({ data }) => {
      forEach(data, (value, key) => {
        formRef.current.setError(key, { message: value })
      })
      formRef.current.reset({}, { keepValues: true })
    },
  })

  const mutationConfirmCompanyRegistration = useMutation({
    mutationKey: ['confirmCompanyRegistration'],
    mutationFn: confirmRegistrationCompany,
    onSuccess: async ({ data: companyToken }) => {
      await updateToken(companyToken)
      await connect()
      await handleWebSocketSubscription()
      const { data: companyData } = await getInfoCurrentCompany()
      setRoleUser(ROLES.ROLE_ADMIN)
      setCurrentCompany(companyData)
      navigation(routes.accountSettings)
    },
    onError: () => {
      formRef.current.setError('code', { message: 'Invalid code' })
    },
  })
  const handleClickResendCode = useCallback(async () => {
    const formattedPhone = omitBy(formRef.current.getValues(), isNullOrEmpty)
    await mutationRegistrationCompany.mutate(formattedPhone)
  }, [])

  const handleSubmit = useCallback(
    (data) => {
      const formattedData = omitBy(data, isNullOrEmpty)
      switch (formStep) {
        case 1:
          mutationRegistrationCompany.mutate(formattedData)
          return
        case 2: {
          mutationConfirmCompanyRegistration.mutate(formattedData)
          return
        }
      }
    },
    [formStep]
  )

  const formStepDetails = useMemo(() => {
    switch (formStep) {
      case 1:
        return { title: 'Create your account', btnText: 'Create an account' }
      case 2:
        return { title: 'Check your email', btnText: 'Confirm' }
      default:
        return { title: '', btnText: '' }
    }
  }, [formStep])

  return (
    <LayoutGuest>
      <div className="registrationOrg">
        <div className="innerCenterForm container">
          <div className="innerForm">
            <p className="titleForm">{formStepDetails.title}</p>
            {formStep === 2 && (
              <p className="inputLabel mb-2">
                We sent a code to <u>{formRef.current.watch('email')}</u>
              </p>
            )}
            <HookFormFieldsArray
              ref={formRef}
              onSubmit={handleSubmit}
              hookFormSettings={{ reValidateMode: 'onSubmit' }}
              className="form"
              defaultFields={registrationFields}
              globalName="registrationOrg"
              Footer={
                <Footer
                  step={formStep}
                  btnText={formStepDetails.btnText}
                  onClickResendCode={handleClickResendCode}
                  btnDisabled={
                    mutationRegistrationCompany.isPending ||
                    mutationConfirmCompanyRegistration.isPending
                  }
                />
              }
            />
            <p className="blockUnderFooter">
              Already have an account? <Link to={routes.adminLogin}>Sign In</Link>
            </p>
          </div>
        </div>
      </div>
    </LayoutGuest>
  )
}

const mapDispatchToProps = {
  setRoleUser,
  setCurrentCompany,
}

export default connect(null, mapDispatchToProps)(Organization)
