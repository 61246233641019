import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'

import { routes } from '../../../router/routes'
import { INPUT_TYPES } from '../../../utils/constants'
import { createCompany, getInfoCurrentCompany } from '../../../api/admin/company'
import { updateToken } from '../../../utils/auth'
import { setCurrentCompany } from '../../../redux/actions/currentCompany'
import { setLoadingApp } from '../../../redux/actions/ui'
import ControllerField from '../../HookForm/components/ControllerField'
import PrimaryButton from '../../PrimaryButton'
import Button from '../../Button'

import './styles.css'

const ModalCreateCompany = ({ show, onHide, setLoadingApp, setCurrentCompany }) => {
  const navigation = useNavigate()
  const { control, handleSubmit, reset } = useForm()

  const mutationGetCompanyInfo = useMutation({
    mutationKey: ['getInfoCurrentCompany'],
    mutationFn: getInfoCurrentCompany,
    onMutate: () => {
      setLoadingApp(true)
    },
    onSuccess: ({ data }) => {
      setCurrentCompany(data)
      toast.info(`Switched to ${data?.name}`)
      navigation(routes.accountSettings)
      reset({ name: '', bigLogo: null, smallLogo: null })
    },
    onSettled: () => {
      setLoadingApp(false)
    },
  })

  const mutationCreateCompany = useMutation({
    mutationKey: ['createCompany'],
    mutationFn: (submitFormData) => {
      const formData = {
        ...submitFormData,
        bigLogo: submitFormData.bigLogo.file,
        smallLogo: submitFormData.smallLogo.file,
      }
      return createCompany(formData)
    },
    onSuccess: ({ data }) => {
      onHide()
      const { token: newCompanyToken } = data
      updateToken(newCompanyToken)
      mutationGetCompanyInfo.mutate()
    },
  })

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={onHide}
      contentClassName="modalCotnentCreateComapny"
      dialogClassName="modalDialogCreateComapny"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="blockTitle mb-0" title="Create a new company">
          Create a new company
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(mutationCreateCompany.mutate)}>
          <ControllerField
            name="name"
            control={control}
            placeholder="Company"
            rules={{ required: true }}
            defaultValue=""
            label="Organization name*"
            innerClassName="mb-3"
          />
          <div className="d-flex align-items-end gap-4">
            <ControllerField
              name="bigLogo"
              control={control}
              rules={{ required: true, validate: (value) => !isEmpty(value.imgUrl) }}
              label="Company logo*"
              innerClassName="w-auto"
              dropzoneClassName="dropzoneBigLogo"
              type={INPUT_TYPES.INPUT_FILE}
            />
            <ControllerField
              name="smallLogo"
              control={control}
              rules={{ required: true, validate: (value) => !isEmpty(value.imgUrl) }}
              label="Contact logo*"
              innerClassName="w-auto"
              dropzoneClassName="dropzoneSmallLogo"
              type={INPUT_TYPES.INPUT_FILE}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center gap-2 justify-content-end border-0 mt-1">
        <Button type="button" onClick={onHide}>
          Cancel
        </Button>
        <PrimaryButton onClick={handleSubmit(mutationCreateCompany.mutate)} text="Create" />
      </Modal.Footer>
    </Modal>
  )
}

const mapDispatchToProps = {
  setLoadingApp,
  setCurrentCompany,
}

export default connect(null, mapDispatchToProps)(ModalCreateCompany)
