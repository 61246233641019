import { useEffect, useRef, useState } from 'react'
import { omitBy } from 'lodash'

import { SURVEY_QUESTION_TYPES, DROPDOWN_QUESTION_TYPES } from '../../utils/constants'
import { FIELD_CREATE_QUESTION } from '../../utils/constantsForms'
import { changeSurveyQuestion, createSurveyQuestion, deleteSurveyQuestion } from '../../api/survey'
import { isNullOrEmpty } from '../../utils/helpers'
import { Bin } from '../SvgIcon'
import HookForm from '../HookForm'
import PrimaryButton from '../PrimaryButton'
import OpenEndQuestionBody from './QuestionBody/OpenEndQuestionBody'
import RatingScaleQuestionBody from './QuestionBody/RatingScaleQuestionBody'
import SingleChoiceQuestionBody from './QuestionBody/SingleChoiceQuestionBody'
import QuestionAccordion from './QuestionAccordion'

const SurveyQuestion = ({
  type = SURVEY_QUESTION_TYPES.SINGlE_CHOICE_SELECT,
  itemSurvey,
  itemQuestion,
  surveyQuestions,
  onCreateQuestion,
  onDeleteQuestion,
  onChangeQuestion,
  nextQuestionId = null,
}) => {
  const [isEditingMode, setIsEditingMode] = useState(false)
  const formRef = useRef(null)

  const formattedSurveyQuestion = surveyQuestions.reduce(
    (acc, item) => (item.id !== itemQuestion.id ? [...acc, { ...item, title: item.name }] : acc),
    []
  )
  const dropdownNextMessages = [
    { title: itemSurvey.finalText, id: null },
    ...formattedSurveyQuestion,
  ]

  const handleChangeSurveyQuestion = (data) => {
    const filteredData = omitBy(
      { ...data, type: data?.type?.type, id: itemQuestion.id },
      isNullOrEmpty
    )

    changeSurveyQuestion(filteredData)
      .then(onChangeQuestion)
      .then(() => setIsEditingMode(false))
  }

  const handleCreateSurveyQuestion = (data) => {
    createSurveyQuestion({ ...data, type: data?.type?.type, surveyId: itemSurvey.id }).then(
      onCreateQuestion
    )
  }

  const handleDeleteQuestion = () => {
    if (itemQuestion.isNewQuestion) {
      onDeleteQuestion()
      return
    }

    deleteSurveyQuestion(itemQuestion).then(onDeleteQuestion)
  }

  useEffect(() => {
    if (isEditingMode) {
      const defaultType = DROPDOWN_QUESTION_TYPES.find((item) => item.type === type)

      formRef.current.reset({ name: itemQuestion.name, type: defaultType })
    }
  }, [isEditingMode])

  const renderEditForm = () => (
    <HookForm
      ref={formRef}
      fields={FIELD_CREATE_QUESTION}
      onSubmit={isEditingMode ? handleChangeSurveyQuestion : handleCreateSurveyQuestion}
      className="mt-3 d-flex gap-2 align-items-stretch"
      Footer={() => (
        <div className="d-flex align-items-center gap-2 ms-auto">
          <Bin
            size={25}
            className="cursorPointer"
            fill="var(--persian-red)"
            onClick={handleDeleteQuestion}
          />
          <PrimaryButton customClass="p-2 ps-4 pe-4" text="Save" />
        </div>
      )}
    />
  )

  if (itemQuestion.isNewQuestion || isEditingMode) {
    return renderEditForm()
  }

  return (
    <QuestionAccordion onClickPencil={() => setIsEditingMode(true)} title={itemQuestion.name}>
      {type === SURVEY_QUESTION_TYPES.SINGlE_CHOICE_SELECT && (
        <SingleChoiceQuestionBody
          itemSurvey={itemSurvey}
          itemQuestion={itemQuestion}
          dropdownNextMessages={dropdownNextMessages}
        />
      )}
      {type === SURVEY_QUESTION_TYPES.OPEN_END && (
        <OpenEndQuestionBody
          nextQuestionId={nextQuestionId}
          dropdownNextMessages={dropdownNextMessages}
          onChangeQuestionInfo={handleChangeSurveyQuestion}
        />
      )}
      {type === SURVEY_QUESTION_TYPES.RATING_SCALE && (
        <RatingScaleQuestionBody
          nextQuestionId={nextQuestionId}
          dropdownNextMessages={dropdownNextMessages}
          onChangeQuestionInfo={handleChangeSurveyQuestion}
        />
      )}
    </QuestionAccordion>
  )
}

export default SurveyQuestion
