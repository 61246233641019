import { Tooltip, Whisper } from 'rsuite'
import { Form } from 'react-bootstrap'
import { useCallback, useRef } from 'react'

import { ThList } from '../SvgIcon'
import EmojiPicker from '../EmojiPicker'
import MessageConstants from '../MessageConstants'

import './styles.css'

const MessageInput = ({
  value,
  error,
  onChange,
  messageConstants = {},
  innerClassName = '',
  inputRef = null,
}) => {
  const textareaRef = useRef()

  const onEmojiSelect = useCallback(
    (emoji) => {
      const lastCursorPosition = textareaRef.current.selectionStart
      onChange(
        []
          .concat(
            textareaRef.current?.value.slice(0, lastCursorPosition),
            emoji,
            textareaRef.current?.value.slice(lastCursorPosition)
          )
          .join('')
      )

      textareaRef.current.focus()
      setTimeout(() => {
        const a = lastCursorPosition + emoji.length

        textareaRef.current.setSelectionRange(a, a)
      }, 0)
    },
    [onChange]
  )

  return (
    <div
      className={`innerMessageInput inputStyle ${innerClassName} ${error ? 'errorInputText' : ''}`}
    >
      <div className="d-flex gap-2 position-absolute">
        <Whisper
          trigger={['hover', 'click']}
          placement="autoVerticalStart"
          controlId="control-id-focus"
          enterable
          speaker={
            <Tooltip className="constantTooltip">
              <MessageConstants constants={messageConstants} />
            </Tooltip>
          }
        >
          <button type="button" className="rs-btn buttonConstants">
            <ThList size={16} />
          </button>
        </Whisper>
        <EmojiPicker onEmojiClick={onEmojiSelect} />
      </div>

      <Form.Control
        as="textarea"
        type="text"
        value={value}
        ref={(el) => {
          inputRef(el)
          textareaRef.current = el
        }}
        autoFocus
        onChange={onChange}
        className="inputStyle"
      />
    </div>
  )
}

export default MessageInput
