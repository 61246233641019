import { connect, useDispatch } from 'react-redux'
import { useMutation, useQuery } from '@tanstack/react-query'

import { routes } from '../../router/routes'
import { ADMIN_ROUTE_MENU, CHAT_LIST_TABS, ROLE_GUEST, ROLES } from '../../utils/constants'
import { getAllCompanies } from '../../api/superAdmin/companies'
import { getMyCompanies, switchAccountCompany } from '../../api/admin/company'
import { setRoleUser } from '../../redux/actions/user'
import { setShowModalCreateCompany, toggleDisplayAdminMenu } from '../../redux/actions/ui'
import { clearSession, updateToken } from '../../utils/auth'
import { Exit, IconSupport } from '../SvgIcon'
import OrganizationSwitcher from '../OrganizationSwitcher'
import NavigateLink from './components/NavigateLink'

import './styles.css'

const AdminMenu = ({
  userId,
  showMenu,
  userRole,
  currentCompany,
  toggleDisplayAdminMenu,
  setShowModalCreateCompany,
}) => {
  const dispatch = useDispatch()

  const logoutAdmin = () => {
    clearSession()
    dispatch(setRoleUser(ROLE_GUEST))
  }

  const { data: companies } = useQuery({
    queryKey: ['myCompanies', userRole, userId, currentCompany.id],
    queryFn: () => (userRole === ROLES.ROLE_SUPER_ADMIN ? getAllCompanies() : getMyCompanies()),
  })
  const mutationSwitchAdminCompany = useMutation({
    mutationKey: ['switchAdminCompany'],
    mutationFn: switchAccountCompany,
    onSuccess: async ({ data }) => {
      await updateToken(data?.newToken)
      window.location.reload()
    },
  })

  return (
    <div className={`navigationAdmin ${!showMenu ? 'hide' : ''}`}>
      <OrganizationSwitcher
        showBtnCreateCompany
        orgList={companies?.data}
        onSwitchOrg={mutationSwitchAdminCompany.mutate}
        selectedOrg={currentCompany}
        onClickBtnCreateCompany={() => setShowModalCreateCompany(true)}
        innerClassName="adminOrgSwitcher"
      />
      <div className="innerMenuAdmin">
        {ADMIN_ROUTE_MENU.map((item, index) => (
          <NavigateLink
            {...item}
            companiesLength={companies?.data?.length}
            hideTooltip={showMenu}
            showText
            key={index}
          />
        ))}
      </div>
      {userRole === ROLES.ROLE_ADMIN && (
        <div className="additionalBottomBtns">
          <NavigateLink
            Icon={IconSupport}
            text="Support"
            className="w-auto m-0 gap-0 p-2"
            route={routes.messaging + `?chatType=${CHAT_LIST_TABS.SUPPORT}`}
          />
        </div>
      )}
      <NavigateLink
        Icon={Exit}
        hideTooltip={showMenu}
        onClick={logoutAdmin}
        route={routes.adminLogin}
        showText
        text="Logout"
      />
      <button onClick={() => toggleDisplayAdminMenu()} className="btnCollapseAdminMenu">
        <div />
        <div />
      </button>
    </div>
  )
}

const mapStateToProps = ({ ui, user, currentCompany }) => ({
  currentCompany,
  userId: user.data.id,
  userRole: user.role,
  showMenu: ui.showAdminMenu,
})
const mapDispatchToProps = { toggleDisplayAdminMenu, setShowModalCreateCompany }

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu)
