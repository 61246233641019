import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash/lang'
import { Drawer } from 'rsuite'

import {
  DROPDOWN_DATA_SURVEY_EVENTS,
  TITLES_SURVEY_EVENTS,
  TYPE_SURVEY_EVENTS,
  TYPE_SURVEY_SPECIFIC,
} from '../../../utils/constants'
import { changeSurveyInfo, createSurvey } from '../../../api/survey'
import Button from '../../Button'
import CustomInput from '../../CustomInput'
import CustomRadio from '../../CustomRadio'
import SelectEvents from '../../SelectEvents'
import PrimaryButton from '../../PrimaryButton'
import DateTimePicker from '../../DateTimePicker'

import './styles.css'

const ModalSurvey = ({
  themeId,
  show,
  onHide,
  onCreateSurvey,
  onChangeSurvey,
  defaultDataSurvey,
}) => {
  const { handleSubmit, control, watch, resetField, reset } = useForm()
  const typeAction = watch('type') ?? TYPE_SURVEY_SPECIFIC
  const isEditableMode = !isEmpty(defaultDataSurvey)

  const handleManageSurvey = (data) => {
    const extraData = {
      [TYPE_SURVEY_SPECIFIC]: { absolute_date: data.timeAction },
      [TYPE_SURVEY_EVENTS]: { event: { type: data.timeAction.value, extra: data.extra } },
    }

    if (isEditableMode) {
      return changeSurveyInfo({
        ...defaultDataSurvey,
        ...data,
        ...extraData[data.type],
      })
        .then(onChangeSurvey)
        .then(onHide)
    }

    createSurvey({ ...data, ...extraData[data.type], themeId })
      .then(onCreateSurvey)
      .then(onHide)
  }

  useEffect(() => {
    if (show && isEditableMode) {
      const eventType = defaultDataSurvey?.deliveryTriggerEvent?.type

      const timeAction = {
        [TYPE_SURVEY_SPECIFIC]: defaultDataSurvey.absoluteDate,
        [TYPE_SURVEY_EVENTS]: {
          title: TITLES_SURVEY_EVENTS[eventType],
          value: eventType,
        },
      }[defaultDataSurvey.type]

      reset({
        ...defaultDataSurvey,
        timeAction,
        extra: defaultDataSurvey?.deliveryTriggerEvent?.extra,
      })
    }

    return () => reset({ name: '', type: TYPE_SURVEY_SPECIFIC, timeAction: '' })
  }, [show])

  return (
    <Drawer placement="right" open={show} size="xs" onClose={onHide}>
      <Drawer.Header closeButton>
        <Drawer.Title className="blockTitle mb-0 mt-1">Survey</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="p-3">
        <form className="formManageSurvey" onSubmit={handleSubmit(handleManageSurvey)}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <CustomInput
                label="Survey name"
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={error}
              />
            )}
          />
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            defaultValue={TYPE_SURVEY_SPECIFIC}
            render={({ field: { onChange, value } }) => (
              <CustomRadio
                value={value}
                onChange={(e) => {
                  resetField('timeAction', { defaultValue: '' })
                  onChange(e)
                }}
                label="Type pipeline"
                buttons={[
                  { label: 'Specific date', value: TYPE_SURVEY_SPECIFIC },
                  { label: 'Events', value: TYPE_SURVEY_EVENTS },
                ]}
              />
            )}
          />
          <Controller
            name="timeAction"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const SwitchItems = {
                [TYPE_SURVEY_SPECIFIC]: DateTimePicker,
                [TYPE_SURVEY_EVENTS]: SelectEvents,
              }
              const InputSelectDate = SwitchItems[typeAction]

              return (
                <div className="mt-2 mb-2">
                  <InputSelectDate
                    eventsData={DROPDOWN_DATA_SURVEY_EVENTS}
                    control={control}
                    onChange={onChange}
                    value={value}
                    valueFormat={'YYYY-MM-DDTHH:mm:ss.SSS[Z]'}
                    displayFormat="MMM dd, yyyy hh:mm aa"
                    error={error}
                  />
                </div>
              )
            }}
          />
        </form>
      </Drawer.Body>
      <Drawer.Actions className="drawerActionModalFooter">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton
          onClick={handleSubmit(handleManageSurvey)}
          text={isEditableMode ? 'Update survey' : 'Create survey'}
        />
      </Drawer.Actions>
    </Drawer>
  )
}

export default ModalSurvey
