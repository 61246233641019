import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { isEmpty, omitBy } from 'lodash'
import { Link } from 'react-router-dom'

import { routes } from '../../../../router/routes'
import { CHAT_LIST_TABS, INPUT_TYPES, ROLES, URL_PATTERN } from '../../../../utils/constants'
import { getAvailablePhones } from '../../../../api/superAdmin/companies'
import { changeInfoCurrentCompany } from '../../../../api/admin/company'
import { updateCurrentCompany } from '../../../../redux/actions/currentCompany'
import { formatPhoneNumber, isNullOrEmpty } from '../../../../utils/helpers'
import useValidatingForm from '../../../../utils/hooks/useValidatingForm'
import { Cross, FillSetting, Plus } from '../../../../components/SvgIcon'
import ControllerField from '../../../../components/HookForm/components/ControllerField'
import PrimaryButton from '../../../../components/PrimaryButton'
import ContactPreview from '../components/ContactPreview'

const AccountDetails = ({
  isActiveTab,
  currentCompanyInfo,
  updateCurrentCompany,
  currentUserId,
  userRole,
}) => {
  const queryClient = useQueryClient()
  const { handleSubmit, control, formState, watch, reset } = useForm({
    defaultValues: {
      ...currentCompanyInfo,
      bigLogo: { imgUrl: currentCompanyInfo?.bigLogo, file: null },
      smallLogo: { imgUrl: currentCompanyInfo?.smallLogo, file: null },
      customLinks: !isEmpty(currentCompanyInfo.customLinks)
        ? currentCompanyInfo.customLinks
        : [{ label: '', url: '' }],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customLinks',
  })
  const formIsValid = useValidatingForm(formState)

  const mutationUpdateCompanyInfo = useMutation({
    mutationKey: ['updateCompanyInfo', currentCompanyInfo.id],
    mutationFn: (submitFormData) => {
      const formDirtyFields = Object.keys(formState.dirtyFields)

      const customLinks = submitFormData.customLinks.filter(({ label, url }) => label && url)

      // Create a new object with only dirty fields
      const filteredData = formDirtyFields.reduce((acc, field) => {
        acc[field] = submitFormData[field]
        return acc
      }, {})

      const formData = {
        ...filteredData,
        customLinks,
        bigLogo: submitFormData.bigLogo?.file,
        smallLogo: submitFormData.smallLogo?.file,
      }

      const formattedData = omitBy(formData, isNullOrEmpty)

      return changeInfoCurrentCompany(formattedData)
    },
    onSuccess: ({ data: responseData }, formData) => {
      reset({}, { keepValues: true, keepDirty: false })
      updateCurrentCompany({ deliveryPhone: formData?.phone, ...formData, ...responseData })
      queryClient.setQueryData(
        ['myCompanies', userRole, currentUserId, currentCompanyInfo?.id],
        (oldInfo) => {
          const newData = oldInfo.data?.map((companyInfo) =>
            currentCompanyInfo?.id === companyInfo.id
              ? { ...companyInfo, ...responseData }
              : companyInfo
          )

          return { ...oldInfo, data: newData }
        }
      )
    },
  })

  useEffect(() => {
    if (!isEmpty(currentCompanyInfo)) {
      reset({
        ...currentCompanyInfo,
        phone: currentCompanyInfo?.deliveryPhone,
        bigLogo: { imgUrl: currentCompanyInfo?.bigLogo, file: null },
        smallLogo: { imgUrl: currentCompanyInfo?.smallLogo, file: null },
        customLinks: !isEmpty(currentCompanyInfo.customLinks)
          ? currentCompanyInfo.customLinks
          : [{ label: '', url: '' }],
      })
    }
  }, [currentCompanyInfo])

  const { data: twilioPhones } = useQuery({
    queryKey: ['twilioPhones'],
    queryFn: getAvailablePhones,
    select: ({ data }) => {
      return data.map((phone) => ({ phone, phoneTitle: formatPhoneNumber(phone) }))
    },
    enabled: userRole === ROLES.ROLE_SUPER_ADMIN,
  })

  return (
    <div className={`tabBlock ${isActiveTab ? 'visibleTabBlock' : ''}`}>
      <div className="blockInfo mb-5">
        <p className="blockInfoTitle">Set Up Your Organization</p>
        <p className="blockInfoContent mw-800">
          Provide key details about your organization, including its name, logo, and branding
          elements, to ensure a consistent experience across the platform.
        </p>
      </div>
      <div className="d-flex align-items-start ">
        <form
          className="formOrganizationInfo"
          onSubmit={handleSubmit(mutationUpdateCompanyInfo.mutate)}
        >
          <div className="d-flex align-items-end gap-4 mb-4">
            <ControllerField
              name="bigLogo"
              control={control}
              rules={{ required: false, validate: (value) => !isEmpty(value.imgUrl) }}
              label="Company logo*"
              innerClassName="w-auto"
              dropzoneClassName="dropzoneBigLogo"
              type={INPUT_TYPES.INPUT_FILE}
            />
            <ControllerField
              name="smallLogo"
              control={control}
              rules={{ required: false, validate: (value) => !isEmpty(value.imgUrl) }}
              label="Contact avatar*"
              innerClassName="w-auto"
              dropzoneClassName="dropzoneSmallLogo"
              type={INPUT_TYPES.INPUT_FILE}
            />
          </div>
          <ControllerField
            name="name"
            control={control}
            placeholder="Company"
            defaultValue=""
            rules={{ required: false }}
            label="Organization name*"
          />
          <ControllerField
            name="phone"
            control={control}
            disabled={isNullOrEmpty(twilioPhones) || userRole !== ROLES.ROLE_SUPER_ADMIN}
            customDropdownMenu="mw-100 w-100"
            data={twilioPhones}
            titleKey="phoneTitle"
            title={formatPhoneNumber(currentCompanyInfo?.deliveryPhone) ?? 'Select phone'}
            valueKey="phone"
            showIconInfo={userRole !== ROLES.ROLE_SUPER_ADMIN}
            infoText={
              <p>
                Only the super admin can change the company's phone number. Use the{' '}
                <Link
                  className="cursorPointer highLightLink light fw-bold"
                  to={`${routes.messaging}?chatType=${CHAT_LIST_TABS.SUPPORT}`}
                >
                  support chat
                </Link>{' '}
                for assistance.
              </p>
            }
            innerDropdown="mt-3 mb-4 mw-100"
            rules={{ required: userRole === ROLES.ROLE_SUPER_ADMIN }}
            label="Phone number*"
            placeholder="+1 (999) 999-9999"
            type={INPUT_TYPES.INPUT_DROPDOWN}
          />
          <div>
            <label className="inputLabel mb-2 ms-0">Contact URLs</label>
            {fields.map((field, index) => (
              <div key={field.id}>
                <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
                  <ControllerField
                    name={`customLinks[${index}].label`}
                    control={control}
                    innerClassName="innerInputLabelContactLink"
                    defaultValue=""
                    placeholder="Label"
                    rules={{ required: false }}
                  />
                  {fields.length > 1 && (
                    <Cross
                      onClick={() => {
                        remove(index)
                      }}
                      className="cursorPointer"
                      fill="var(--persian-red)"
                      size={17}
                    />
                  )}
                </div>
                <ControllerField
                  name={`customLinks[${index}].url`}
                  control={control}
                  innerClassName="mb-2"
                  placeholder="https://www.example.com"
                  rules={{ required: false, pattern: URL_PATTERN }}
                />
              </div>
            ))}
            {fields.length < 5 && (
              <button
                type="button"
                onClick={() => append({ label: '', url: '' })}
                className="defaultButton ms-auto"
              >
                <Plus size={15} /> Add URL
              </button>
            )}
          </div>
          <PrimaryButton
            disabled={!formIsValid || mutationUpdateCompanyInfo.isPending}
            customClass="big mw-100 mt-4 w-100"
            text="Save"
          />
        </form>
        <ContactPreview
          name={watch('name')}
          phone={watch('phone')}
          customLinks={watch('customLinks')}
          avatarUrl={watch('smallLogo')?.imgUrl}
        />
      </div>
      <FillSetting className="businessSettingsIconBG" />
    </div>
  )
}

const mapStateToProps = ({ currentCompany, user }) => ({
  currentCompanyInfo: currentCompany,
  currentUserId: user.data.id,
  userRole: user.role,
})
const mapDispatchToProps = {
  updateCurrentCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
