import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { isNil } from 'lodash'

import { MESSAGE_TYPES, PIPELINE_TYPES } from '../../../../utils/constants'
import { getContactMessage, updateContactMessage } from '../../../../api/admin/companyMessages'
import { Contact } from '../../../../components/SvgIcon'
import ControllerMessageInput from '../../Message/components/FormFields/ControllerMessageInput'
import PrimaryButton from '../../../../components/PrimaryButton'

const GeneralMessages = ({ isActiveTab, currentCompanyId }) => {
  const { control, formState, reset, handleSubmit } = useForm()
  const mutationUpdateContactMessage = useMutation({
    mutationKey: ['updateContactMessage'],
    mutationFn: updateContactMessage,
    onSuccess: (_, formData) => {
      reset(formData)
    },
  })

  const { data: contactMessage, isLoading: isLoadingContactMessage } = useQuery({
    queryKey: ['getContactMessage', currentCompanyId],
    queryFn: getContactMessage,
    enabled: isActiveTab,
  })

  useEffect(() => {
    if (!isNil(contactMessage?.data)) {
      reset({ body: contactMessage?.data?.body })
    }
  }, [contactMessage])

  return (
    <section className={`tabBlock ${isActiveTab ? 'visibleTabBlock' : ''}`}>
      <div>
        <p className="blockTitle mb-2">Contact message</p>
        <p className="blockDescription mb-3 w -75">
          This message will be sent to the user after subscribing to the application, asking them to
          save the contact file. If they do not save it immediately, a second reminder will be sent
          in 3 days. If no action is taken, another reminder will be sent in 7 days.
        </p>
        <form onSubmit={handleSubmit(mutationUpdateContactMessage.mutate)}>
          <ControllerMessageInput
            name="body"
            control={control}
            showMessageCounter
            required={false}
            formState={formState}
            defaultValue=""
            messageType={MESSAGE_TYPES.CONTACT}
            pipelineType={PIPELINE_TYPES.ADMIN}
            messageConstants={{ contactUrl: '', firstName: 'User' }}
            innerClassName="innerContactMessage"
          />
          <PrimaryButton
            text="Save"
            disabled={
              !formState.isValid ||
              !formState.isDirty ||
              mutationUpdateContactMessage.isPending ||
              isLoadingContactMessage
            }
            Icon={Contact}
            customClass="big mt-2 ms-auto"
          />
        </form>
      </div>
    </section>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyId: currentCompany.id,
})

export default connect(mapStateToProps)(GeneralMessages)
