import zxcvbn from 'zxcvbn'
import { INPUT_TYPES } from '../../utils/constants'

export const generateFormFields = (formRef) => [
  {
    name: 'firstName',
    innerClassName: 'innerInput firstOneLine',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'First name*',
    placeholder: '',
    rules: {
      required: true,
    },
  },
  {
    name: 'lastName',
    innerClassName: 'innerInput secondOneLine',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Last name*',
    placeholder: '',
    rules: {
      required: true,
    },
  },
  {
    name: 'password',
    innerClassName: 'innerInput password',
    inputType: 'password',
    showPasswordStrength: true,
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Password*',
    placeholder: '',
    rules: {
      required: true,
      validate: (value) => zxcvbn(value).score >= 2,
    },
  },
  {
    name: 'confirmPassword',
    innerClassName: 'innerInput',
    inputType: 'password',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Confirm password',
    placeholder: '',
    rules: {
      required: true,
      validate: (value) => value === formRef.current.getValue('password'),
    },
  },
]
