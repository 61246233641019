import { useState } from 'react'
import { Drawer, Table } from 'rsuite'

import Button from '../../Button'
import Pagination from '../../Pagination'
import CustomInput from '../../CustomInput'
import PrimaryButton from '../../PrimaryButton'
import CustomCheckbox from '../../CustomCheckbox'

import './styles.css'

const ModalSelectThemes = ({ title = 'Themes', show, onHide }) => {
  const [selectedThemes, setSelectedThemes] = useState([])

  const toggleThemeSelect = (themeId) => {
    if (selectedThemes.includes(themeId)) {
      setSelectedThemes(selectedThemes.filter((id) => id !== themeId))
    } else {
      setSelectedThemes([...selectedThemes, themeId])
    }
  }

  return (
    <Drawer placement="right" className="modalSelectThemes" open={show} onClose={onHide}>
      <Drawer.Header closeButton>
        <Drawer.Title className="blockTitle mb-0 mt-1">{title}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="p-3 position-relative">
        <CustomInput placeholder="Search by theme name" />

        <div className="themesList">
          <Table
            // loading={isLoading || isFetching}
            data={[
              { name: 'Travelling', id: 1 },
              { name: 'Music', id: 2 },
            ]}
            shouldUpdateScroll={false}
            onRowClick={({ id }) => {
              toggleThemeSelect(id)
            }}
            rowClassName="cursorPointer"
            autoHeight
          >
            <Table.Column width={50} align="center">
              <Table.HeaderCell style={{ padding: 0 }} />
              <Table.Cell style={{ padding: 0 }}>
                {({ id }) => (
                  <div style={{ lineHeight: '46px' }}>
                    <CustomCheckbox
                      onClick={(e) => e.stopPropagation()}
                      inline
                      value={selectedThemes.includes(id)}
                      onChange={() => toggleThemeSelect(id)}
                      className="me-0"
                    />
                  </div>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column sortable verticalAlign="middle" width={240} maxWidth={300}>
              <Table.HeaderCell dataKey="name" className="headColumn">
                Name
              </Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
          </Table>
        </div>

        <Pagination
          innerClassName="themesPagination"
          show
          total={120}
          lastPage={10}
          forcePage={1}
        />
      </Drawer.Body>
      <Drawer.Actions className="drawerActionModalFooter">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton text="Add Themes" />
      </Drawer.Actions>
    </Drawer>
  )
}

export default ModalSelectThemes
