import { useMemo } from 'react'
import { connect } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

import { ROLES } from '../../utils/constants'
import { getAllCompanies } from '../../api/superAdmin/companies'
import { getMyCompanies as getMyCompaniesAdmin } from '../../api/admin/company'
import { getMyCompanies as getMyCompaniesUser } from '../../api/user/company'
import OrgCard from './components/OrgCard'

import './styles.css'

const SwitchOrganization = ({ userId, userRole, currentCompanyId }) => {
  const { data: adminCompanies } = useQuery({
    queryKey: ['myCompanies', userId],
    queryFn: () => (userRole === ROLES.ROLE_SUPER_ADMIN ? getAllCompanies() : getMyCompaniesAdmin),
    enabled: [ROLES.ROLE_ADMIN, ROLES.ROLE_SUPER_ADMIN].includes(userRole),
  })

  const { data: userCompanies } = useQuery({
    queryKey: ['myCompanies', userId],
    queryFn: getMyCompaniesUser,
    enabled: userRole === ROLES.ROLE_USER,
  })

  const viewData = useMemo(() => {
    return userRole === ROLES.ROLE_USER ? userCompanies?.data : adminCompanies?.data
  }, [userRole, adminCompanies, userCompanies])

  return (
    <div className="switchOrganizationList">
      {viewData?.map(({ id, name, smallLogo, phone }) => (
        <OrgCard
          key={id}
          companyId={id}
          name={name}
          logo={smallLogo}
          twilioPhoneNumber={phone}
          isCurrent={id === currentCompanyId}
        />
      ))}
    </div>
  )
}

const mapStateToProps = ({ user, currentCompany }) => ({
  userRole: user.role,
  userId: user.data.id,
  currentCompanyId: currentCompany.id,
})

export default connect(mapStateToProps)(SwitchOrganization)
