import { toast } from 'react-toastify'

import r from '../../utils/request'
import { snakeize } from '../../utils/helpers'

export const getMyCompanies = () => {
  return r.get('/admin/companies/my')
}

export const getInfoCurrentCompany = () => {
  return r.get('/admin/companies/current')
}

export const changeInfoCurrentCompany = (params) => {
  return r.uploadWithToast('/admin/companies/current', params, 'put', {
    success: 'Company information updated successfully',
    pending: 'Updating company information, please wait...',
  })
}

export const switchAccountCompany = (companyId) => {
  return r.post('/admin/companies/switch', { newCompanyId: companyId })
}

export const removeCurrentCompany = (params) => {
  return r.deleteWithToast('/admin/companies/current', null, {
    pending: 'Removing the company...',
    success: `Company "${params?.name}" removed successfully.`,
    error: 'Failed to remove the company.',
  })
}

export const createCompany = (params) => {
  return r.uploadWithToast('/admin/companies', params, 'post', {
    success: `Company "${params?.name}" created successfully.`,
    pending: 'Creating the company...',
    error: 'Failed to create the company.',
  })
}

export const getCurrentCompanyAdmins = () => {
  return r.get('/admin/companies/current/admins')
}

export const deleteCurrentCompanyAdmin = (params) => {
  return r.deleteWithToast(`/admin/companies/current/admins/${params.adminId}`, null, {
    pending: 'Removing the admin...',
    success: `Admin "${params.adminName}" removed successfully.`,
    error: 'Failed to remove the admin.',
  })
}

export const sentAdminInvite = (params) => {
  return r.postWithToast('/admin/companies/current/admins', params, {
    success: `Admin "${params?.email}" invited successfully.`,
    pending: 'Sending the invitation...',
    error: 'Failed to send the invitation.',
  })
}

export const setUpAdminOnInvite = (params) => {
  const formatParams = snakeize(params)
  return toast.promise(
    r.rawRequest('/admin/setup', 'put', { data: formatParams }, null, null, {
      Authorization: `bearer ${params.token}`,
    }),
    {
      pending: 'Setting up the admin...',
      success: 'Admin set up successfully.',
      error: 'Failed to set up the admin.',
    }
  )
}

export const adminAcceptInvitation = (params) => {
  const formatParams = snakeize(params)
  return toast.promise(
    r.rawRequest('/admin/accept-invitation', 'post', { data: formatParams }, null, null, {
      Authorization: `bearer ${params.token}`,
    }),
    {
      success: 'Invitation accepted successfully.',
      error: 'Failed to accept the invitation.',
      pending: 'Accepting the invitation...',
    }
  )
}

export const getAdminInvitationStatus = (token) => {
  return r.rawRequest('/admin/invitation/status', 'get', {}, null, null, {
    Authorization: `bearer ${token}`,
  })
}
