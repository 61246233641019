import { useEffect, useMemo, useState } from 'react'

const useTimer = () => {
  const [seconds, setSeconds] = useState(0)

  const timer = useMemo(
    () => (seconds === 0 ? null : `0:${seconds < 10 ? `0${seconds}` : seconds}`),
    [seconds]
  )

  useEffect(() => {
    if (seconds <= 0) return

    const interval = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(interval)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds])

  return { seconds, timer, onChangeTimerTime: setSeconds }
}

export default useTimer
