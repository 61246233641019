import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { Tooltip, Uploader, Whisper } from 'rsuite'

import { getSampleImportUsersFile } from '../../../../api/admin/themeSettings'
import { CloudUpload, Info } from '../../../../components/SvgIcon'
import ModalPreviewNewUsers from './ModalPreviewNewUsers'

const UploadUserFile = ({ isPublished }) => {
  const [uploadedUserFile, setUploadedUserFile] = useState(null)
  const { themeId } = useParams()
  const popUpDescription = useMemo(
    () =>
      isPublished
        ? 'Upload an .XLSX file with user information to add them to the program. Users will be subscribed to this theme with a free coupon.'
        : 'Upload an .XLSX file with user information to prepare for adding users to the program. After publishing the program, users will be automatically subscribed to the theme with a free coupon and will receive a free subscription.',
    [isPublished]
  )
  const supTitle = useMemo(
    () =>
      isPublished
        ? 'Easily upload a list of users, and our system will automatically create free subscriptions for each user on the list. This simplifies the onboarding process and ensures all users are subscribed without any initial cost.'
        : 'Prepare your user list for upload. Once the program is published, our system will automatically subscribe users to the theme with a free coupon, granting them a free subscription.',
    [isPublished]
  )

  const uploadUsers = useCallback(
    (filesArray) => {
      const userFile = filesArray[filesArray.length - 1].blobFile
      setUploadedUserFile(userFile)
    },
    [themeId]
  )

  const mutationSampleImportUserFile = useMutation({
    mutationKey: ['sampleImportUserFile'],
    mutationFn: getSampleImportUsersFile,
  })

  return (
    <section>
      <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
        <div className="d-flex align-items-end gap-2">
          <p className="blockTitle mb-0">User import</p>
          <Whisper
            placement="top"
            trigger="hover"
            enterable
            speaker={
              <Tooltip>
                <p>
                  {popUpDescription}{' '}
                  <span
                    onClick={mutationSampleImportUserFile.mutate}
                    className="cursorPointer highLightLink light"
                  >
                    Download sample XLSX
                  </span>
                  .
                </p>
              </Tooltip>
            }
          >
            <span className="d-flex">
              <Info fill="var(--darkest-gray)" size={20} />
            </span>
          </Whisper>
        </div>
        <p
          onClick={mutationSampleImportUserFile.mutate}
          className="cursorPointer highLightLink fw-bolder"
        >
          Sample .xlsx file
        </p>
      </div>
      <p className="blockDescription mb-3">{supTitle}</p>
      <Uploader
        onChange={uploadUsers}
        draggable
        action=""
        disabledFileItem
        accept=".xlsx, .xls"
        autoUpload={false}
        className="position-relative cursorPointer"
        multiple={false}
      >
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
            justifyContent: 'center',
            height: 200,
            borderRadius: 'var(--border-radius)',
          }}
        >
          <CloudUpload size={35} style={{ marginRight: 10 }} />
          <span>
            Upload <b>Users</b> .xlsx file
          </span>
        </div>
      </Uploader>
      <ModalPreviewNewUsers
        themeId={themeId}
        previewUserFile={uploadedUserFile}
        updatePreviewFile={setUploadedUserFile}
      />
    </section>
  )
}

export default UploadUserFile
