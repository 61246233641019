import { Controller } from 'react-hook-form'
import { isEmpty, isNil } from 'lodash'

import {
  MESSAGE_TYPES,
  PIPELINE_TYPES,
  WARNING_MESSAGE_VALIDATE_VARIABLES,
} from '../../../../../utils/constants'
import useMessages from '../../../../../utils/hooks/useMessages'
import MessageInput from '../../../../../components/MessageInput'

const ControllerMessageInput = ({
  name,
  control,
  messageConstants,
  formState: { dirtyFields },
  defaultValue,
  messageType,
  pipelineType,
  ...rest
}) => {
  const { checkClosingVariables } = useMessages()

  const validateMessageBody = (value) => {
    if (
      messageType === MESSAGE_TYPES.CONTACT &&
      pipelineType === PIPELINE_TYPES.ADMIN &&
      !value.includes(`{{contact_url}}`)
    ) {
      return false
    }
    if (isEmpty(value.trim())) {
      return false
    }

    if (checkClosingVariables(value) && !isNil(dirtyFields.body)) {
      return WARNING_MESSAGE_VALIDATE_VARIABLES
    }

    return true
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true, validate: validateMessageBody }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { error } }) => (
        <MessageInput
          {...rest}
          value={value}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          placeholder="Type message here..."
          messageConstants={messageConstants}
          inputRef={ref}
        />
      )}
    />
  )
}

export default ControllerMessageInput
