import { useCallback } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { SHOW_USER_MENU } from '../../utils/constants'
import { getMyCompanies } from '../../api/user/company'
import { setShowModalCancelSubscription, toggleDisplayUserMenu } from '../../redux/actions/ui'
import localStorage from '../../utils/localStorage'
import ModalSupport from '../Modals/ModalSupport'
import ModalCancelSubscription from '../Modals/ModalCancelSubscription'
import Header from './components/Header'
import LeftSideBar from './components/LeftSideBar'

import './styles.css'

const LayoutUser = ({
  userId,
  showMenu,
  children,
  currentCompanyId,
  toggleDisplayUserMenu,
  showModalCancelSubscription,
  setShowModalCancelSubscription,
}) => {
  const toggleMenu = useCallback(() => {
    toggleDisplayUserMenu()
    localStorage.setItem(SHOW_USER_MENU, !showMenu)
  }, [showMenu])

  const { data: companies } = useQuery({
    queryKey: ['myCompanies', userId, currentCompanyId],
    queryFn: getMyCompanies,
  })

  return (
    <div className="userPages">
      <Header listCompanies={companies?.data} />
      <div className="innerUserPages">
        <LeftSideBar listCompanies={companies?.data} />
        <div className="userContent">{children ?? <Outlet />}</div>
      </div>
      <ModalSupport />
      <ModalCancelSubscription
        show={showModalCancelSubscription}
        onHide={() => setShowModalCancelSubscription(false)}
      />
      <div onClick={toggleMenu} className={`menuBackdrop ${!showMenu ? 'hide' : ''}`} />
    </div>
  )
}

const mapStateToProps = ({ ui, user, currentCompany }) => ({
  showModalCancelSubscription: ui.showModalCancelSubscription,
  showMenu: ui.showUserMenu,
  userId: user.id,
  currentCompanyId: currentCompany.id,
})

const mapDispatchToProps = {
  toggleDisplayUserMenu,
  setShowModalCancelSubscription,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutUser)
