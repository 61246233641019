import { useState } from 'react'
import { Dropdown } from 'rsuite'

import { ArrowDown, Bin, IconUsers, PaperPlane, Plus } from '../../../../../components/SvgIcon'
import PrimaryButton from '../../../../../components/PrimaryButton'
import ModalUpload from '../../../../../components/Modals/ModalUpload'
import ModalConfirm from '../../../../../components/Modals/ModalConfirm'
import ModalGroupUser from '../../../../../components/Modals/ModalGroupUser'
import ModalGroupMessageSender from '../../../../../components/Modals/ModalGroupMessageSender'
import TabSettings from '../TabSettings'
import TabUsers from '../TabUsers'

import './styles.css'

const Group = ({ groupId }) => {
  const [showModalAddUsers, setShowModalAddUsers] = useState(false)
  const [showModalUserImport, setShowModalUserImport] = useState(false)
  const [showConfirmDeleteGroup, setShowConfirmDeleteGroup] = useState(false)
  const [showModalSentMultipleMessages, setShowModalSentMultipleMessages] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  return (
    <div className="innerGroup p-4 d-flex flex-column">
      <div className="d-flex align-items-center gap-3 justify-content-between mb-3">
        <p className="groupName">Pizza 1</p>
        <Dropdown
          renderToggle={(props) => (
            <PrimaryButton
              Icon={ArrowDown}
              text={'Actions'}
              iconProps={{ size: 18, className: 'order-1' }}
              {...props}
              customClass="ps-3 pe-2 pt-1 pb-1"
            ></PrimaryButton>
          )}
          placement="bottomEnd"
          style={{ zIndex: 10 }}
        >
          <Dropdown.Item
            className="d-flex align-items-center gap-2"
            icon={<Plus size={17} fill="#969898" />}
            onClick={() => setShowModalAddUsers(true)}
          >
            Add Member
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center gap-2"
            icon={<IconUsers size={17} fill="#969898" />}
            onClick={() => setShowModalUserImport(true)}
          >
            Import Users
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center gap-2"
            icon={<PaperPlane size={17} fill="#969898" />}
            onClick={() => setShowModalSentMultipleMessages(true)}
          >
            Sent message
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center gap-2"
            icon={<Bin size={17} fill="var(--persian-red)" />}
            onClick={() => setShowConfirmDeleteGroup(true)}
          >
            <p style={{ color: 'var(--persian-red)' }}>Delete Group</p>
          </Dropdown.Item>
        </Dropdown>
      </div>
      <div className="flex-grow-1 d-flex flex-column overflow-scroll">
        <div className="tabsList bottomLine">
          {['Users', 'Settings'].map((tabLabel, index) => (
            <button
              key={tabLabel}
              onClick={() => setSelectedTabIndex(index)}
              className={`tab ps-3 pe-3 fullLine ${
                selectedTabIndex === index ? 'active cursorDefault' : ''
              }`}
            >
              {tabLabel}
            </button>
          ))}
        </div>
        <TabUsers isVisible={selectedTabIndex === 0} />
        <TabSettings isVisible={selectedTabIndex === 1} />
      </div>
      <ModalUpload
        show={showModalUserImport}
        onHide={() => setShowModalUserImport(false)}
        title="Import Users"
      />
      <ModalGroupUser show={showModalAddUsers} onHide={() => setShowModalAddUsers(false)} />
      <ModalConfirm
        show={showConfirmDeleteGroup}
        onHide={() => setShowConfirmDeleteGroup(false)}
        customClassName="md"
        title="Confirm Group Deletion"
        text={
          <>
            You are about to delete <b>{'Pizza 1'}</b> group. This action is irreversible. Please
            confirm to continue.
          </>
        }
        textButton="Delete"
      />
      <ModalGroupMessageSender
        title="Sent Group message"
        description="Send a message to all group members at once. Use this feature to quickly communicate important updates, announcements, or instructions to the entire group."
        show={showModalSentMultipleMessages}
        onHide={() => setShowModalSentMultipleMessages(false)}
      />
    </div>
  )
}

export default Group
