import { useMemo } from 'react'

import { THEME_STATUSES, THEME_STATUSES_SETTINGS } from '../../../utils/constants'

const ThemeStatus = ({ status, isPublished }) => {
  const { mainColor, label } = useMemo(
    () =>
      THEME_STATUSES_SETTINGS?.[isPublished ? THEME_STATUSES.ACTIVE : THEME_STATUSES.INACTIVE] ??
      {},
    [isPublished, status]
  )
  return (
    <div style={{ backgroundColor: mainColor }} className="statusCode">
      {label}
    </div>
  )
}

export default ThemeStatus
