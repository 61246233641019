import { useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'

import ControllerMessageInput from '../../pages/Admin/Message/components/FormFields/ControllerMessageInput'
import PrimaryButton from '../PrimaryButton'
import Button from '../Button'

const ModalGroupMessageSender = ({
  title = 'Message multiple recipients',
  description,
  show,
  onHide,
  onSend,
}) => {
  const { handleSubmit, control, resetField, ...rest } = useForm()

  const sendMessage = async (data) => {
    await onSend(data)
    resetField('message')
    onHide()
  }

  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(sendMessage)}>
        <Modal.Body>
          {description && <p className="blockDescription">{description}</p>}
          <ControllerMessageInput showMessageCounter name="message" control={control} {...rest} />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end gap-3">
            <Button onClick={onHide}>Cancel</Button>
            <PrimaryButton text="Send" />
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ModalGroupMessageSender
