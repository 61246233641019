import { useContext } from 'react'
import { Accordion, AccordionContext, Card, useAccordionButton } from 'react-bootstrap'

import { ArrowDown, Pencil } from '../SvgIcon'

const QuestionContextAwareToggle = ({ eventKey, callback, title, onClickPencil }) => {
  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey))

  const isCurrentEventKey = activeEventKey === eventKey
  return (
    <Card.Header
      onClick={decoratedOnClick}
      className="d-flex align-items-center justify-content-between gap-2 cursorPointer"
    >
      <h5 className="surveyTitleAccordion">{title}</h5>
      <div className="d-flex align-items-center gap-2">
        <Pencil
          onClick={(e) => {
            e.stopPropagation()
            onClickPencil()
          }}
          className="pencilSurvey"
          size={20}
        />
        <ArrowDown
          size={30}
          className={`arrowButtonAccordion  ${
            isCurrentEventKey ? 'arrowButtonAccordionActive' : ''
          }`}
        />
      </div>
    </Card.Header>
  )
}

const QuestionAccordion = ({ children, title, ...rest }) => {
  return (
    <Accordion className="" defaultActiveKey={null}>
      <QuestionContextAwareToggle title={title} eventKey="0" {...rest} />
      <Accordion.Collapse eventKey="0">
        <Card.Body className="accordionChildren">{children}</Card.Body>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default QuestionAccordion
