import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'

import { routes } from '../../router/routes'
import { ROLES } from '../constants'
import { getInfoCurrentCompany, getMyCompanies } from '../../api/user/company'
import { getAllCompanies } from '../../api/superAdmin/companies'
import {
  getInfoCurrentCompany as getInfoCurrentCompanyAdmin,
  getMyCompanies as getMyCompaniesAdmin,
} from '../../api/admin/company'
import { setUser } from '../../redux/actions/user'
import { setCurrentCompany } from '../../redux/actions/currentCompany'
import { useWebsocketContext } from '../../components/SocketProvider'
import { updateToken } from '../auth'

import useWebSocket from './useWebSocket'

const useUserAuth = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { connect } = useWebSocket()
  const { handleWebSocketSubscription } = useWebsocketContext()
  const { userRole } = useSelector(({ user }) => ({
    userRole: user.role,
    userId: user.data.id,
  }))
  const mutationGetUserCompanies = useMutation({
    mutationKey: ['myCompanies'],
    mutationFn: () => (userRole === ROLES.ROLE_SUPER_ADMIN ? getAllCompanies() : getMyCompanies()),
    onSuccess: ({ data: companies }) => {
      return companies?.length > 1 ? navigate(routes.userSwitchAccount) : navigate(routes.userHome)
    },
  })

  const mutationGetAdminCompanies = useMutation({
    mutationKey: ['myCompanies'],
    mutationFn: getMyCompaniesAdmin,
    onSuccess: ({ data: companies }) => {
      return companies?.length > 1
        ? navigate(routes.adminSwitchAccount)
        : navigate(routes.dashboard)
    },
  })

  const authUser = async ({ userData, token, withRedirect = true }) => {
    dispatch(setUser(userData))
    updateToken(token)

    const isUser = userData?.role === ROLES.ROLE_USER
    await connect()
    await handleWebSocketSubscription()
    const { data: companyData } = isUser
      ? await getInfoCurrentCompany()
      : await getInfoCurrentCompanyAdmin()
    dispatch(setCurrentCompany(companyData))

    if (withRedirect) {
      isUser
        ? await mutationGetUserCompanies.mutateAsync()
        : await mutationGetAdminCompanies.mutateAsync()
    }
  }

  return { authUser }
}

export default useUserAuth
