import { useCallback, useEffect, useMemo, useState } from 'react'
import { TagPicker as DropdownPicker } from 'rsuite'
import { isEmpty, isNil } from 'lodash'

import './styles.css'

const CustomDropdownPicker = ({
  label,
  value,
  error,
  getData,
  inputRef,
  onChange,
  className = '',
  valueKey = '',
  classLabel = '',
  disabledKey = '',
  innerClassName = '',
  dependencies = [],
  dataDropdown = [],
  routeParams = {},
  ...rest
}) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const errorClassName = error ? 'errorCustomDropdownPicker' : ''

  const disabledValues = useMemo(
    () =>
      data?.reduce((res, value) => {
        return value[disabledKey] ? [...res, value[valueKey]] : res
      }, []),
    [data]
  )

  const addNewElement = useCallback(
    (items) => {
      onChange?.(items, data)
    },
    [data]
  )

  useEffect(() => {
    if (isEmpty(dataDropdown) && !isNil(getData)) {
      getData(routeParams)
        .then(({ data }) => {
          setData(data)
        })
        .finally(() => setLoading(false))
    }
  }, [...dependencies])

  useEffect(() => {
    if (!isEmpty(dataDropdown)) {
      setLoading(false)
      setData(dataDropdown)
    }
  }, [dataDropdown])

  return (
    <div className={`innerDropdownPicker ${innerClassName}`}>
      {!isEmpty(label) && <label className={`inputLabel ${classLabel}`}>{label}</label>}
      <DropdownPicker
        value={value}
        ref={inputRef}
        data={data}
        loading={loading}
        onChange={addNewElement}
        disabledItemValues={disabledValues}
        valueKey={valueKey}
        placement="autoVerticalEnd"
        className={`${errorClassName} ${className} dropdownPicker`}
        {...rest}
      />
    </div>
  )
}

export default CustomDropdownPicker
