import { useMemo } from 'react'
import { isEmpty } from 'lodash'

import Price from '../Price'
import ImageLoader from '../ImageLoader'

import styles from './GiftCardPreview.module.css'

const GiftCardPreview = ({
  giftImg = '',
  giftName = '',
  from = '',
  to = '',
  message = '',
  priceInfo,
  prependComponent = () => {},
  additionalInfoList = [],
  orderInfoClassName = '',
}) => {
  const sender = useMemo(() => (isEmpty(from) ? '-' : from), [from])
  const recipient = useMemo(() => (isEmpty(to) ? '-' : to), [to])
  const msg = useMemo(() => (isEmpty(message) ? '-' : message), [message])

  return (
    <div className={styles.giftCard}>
      <ImageLoader
        src={giftImg}
        alt={`${giftName} Gift Card`}
        imageClassName={styles.giftCardImage}
      />
      <div className={`${styles.innerOrderInfo} ${orderInfoClassName}`}>
        <div className={styles.innerPriceInfo}>
          <p className={styles.titlePrice}>{giftName}</p>
          <Price
            innerClassName={styles.innerPrice}
            classCurrentPrice={styles.price}
            newPrice={priceInfo?.new}
            oldPrice={priceInfo?.old}
            {...priceInfo}
          />
        </div>
        <p className={styles.orderInfo}>
          <span>From: </span>
          {sender}
        </p>
        <p className={styles.orderInfo}>
          <span>To: </span>
          {recipient}
        </p>
        {additionalInfoList.map(({ label, value }) => (
          <p key={label} className={styles.orderInfo}>
            <span>{label}: </span>
            {value}
          </p>
        ))}
        <p className={styles.orderInfo}>
          <span>Message: </span>
          <br />
          {msg}
        </p>
        {prependComponent()}
      </div>
    </div>
  )
}

export default GiftCardPreview
