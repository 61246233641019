import { camelCase } from 'lodash'

import {
  CLOSING_VARIABLE_CHARS,
  OPENING_VARIABLE_CHARS,
  DEFAULT_MESSAGE_CONSTANTS,
} from '../../constants'
import { checkClosingVariables } from './checkClosingVariables'

const useMessages = (message = '', constants = []) => {
  const replaceVariables = (message, constants = DEFAULT_MESSAGE_CONSTANTS) => {
    return message?.replace?.(
      new RegExp(`${OPENING_VARIABLE_CHARS}(.+?)${CLOSING_VARIABLE_CHARS}`, 'g'),
      (match, variable) =>
        constants[camelCase(variable)] ??
        `${OPENING_VARIABLE_CHARS}${variable}${CLOSING_VARIABLE_CHARS}`
    )
  }

  const formattedMessage = replaceVariables(message, constants)
  const maxMessageLength = formattedMessage.length >= 160 ? 153 : 160
  const messagesCount = Math.ceil(formattedMessage.length / maxMessageLength)

  return {
    message: formattedMessage,
    messagesCount,
    maxMessageLength,
    messageLimit:
      maxMessageLength - (formattedMessage.length % maxMessageLength || maxMessageLength) ||
      maxMessageLength,
    replaceVariables,
    checkClosingVariables,
  }
}

export default useMessages
