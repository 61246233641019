import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { isEmpty, isNil, uniqBy } from 'lodash'

import { SIZE_PAGINATION_SURVEYS } from '../../../utils/constants'
import { getThemeDetails } from '../../../api/admin/themes'
import { getAllSurveys } from '../../../api/survey'
import { Plus } from '../../../components/SvgIcon'
import Button from '../../../components/Button'
import SurveyItem from '../../../components/Survey/SurveyItem'
import ModalSurveyTree from '../../../components/Modals/ModalSurveyTree'
import ModalSurvey from '../../../components/Modals/ModalSurvey'

import './styles.css'

const ThemeSurveys = () => {
  const [showModalSurvey, setShowModalSurvey] = useState(false)
  const [surveyData, setSurveyData] = useState([])
  const [editableSurveyData, setEditableSurveyData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(0)
  const [treeSelectedSurveyId, setTreeSelectedSurveyId] = useState(null)
  const { themeId, programId } = useParams()

  const handleAddSurvey = ({ data }) => {
    setSurveyData((oldState) => [{ ...data, questions: [] }, ...oldState])
  }

  const handleChangeSurvey = ({ data }) => {
    setSurveyData((oldState) =>
      oldState.map((item) => (item.id === data.id ? { ...item, ...data } : item))
    )
  }

  const handleDeleteSurvey = ({ id }) => {
    setSurveyData((oldState) => oldState.filter((item) => item.id !== id))
  }

  const { data: theme } = useQuery({
    queryKey: ['theme', themeId],
    queryFn: () => getThemeDetails(themeId),
  })

  useEffect(() => {
    getAllSurveys({ id: themeId, page: currentPage, size: SIZE_PAGINATION_SURVEYS }).then(
      ({ data }) => {
        const filteredDuplicate = uniqBy([...surveyData, ...data.data], 'id')
        setLastPage(data.lastPage)
        setSurveyData(filteredDuplicate)
      }
    )
  }, [currentPage])

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <p className="blockTitle mb-0">
          <b>{theme?.data?.name}</b> surveys
        </p>
        <Button
          onClick={() => {
            setShowModalSurvey(true)
          }}
        >
          <Plus fill="var(--catalina-blue)" size={20} />
          Add survey
        </Button>
      </div>
      {surveyData?.map((itemSurvey) => (
        <SurveyItem
          key={itemSurvey.id}
          itemSurvey={itemSurvey}
          onClickEditSurvey={(data) => {
            setEditableSurveyData(data)
            setShowModalSurvey(true)
          }}
          selectShowSurveyTree={setTreeSelectedSurveyId}
          handleChangeSurvey={handleChangeSurvey}
        />
      ))}
      {isEmpty(surveyData) && <p className="emptyTable">There are no surveys</p>}
      {lastPage !== currentPage && lastPage !== 0 ? (
        <button
          onClick={() => setCurrentPage((oldState) => oldState + 1)}
          className="nextBtnPagination"
        >
          Show more
        </button>
      ) : null}
      <ModalSurveyTree
        show={!isNil(treeSelectedSurveyId)}
        surveyId={treeSelectedSurveyId}
        onHide={() => setTreeSelectedSurveyId(null)}
      />
      <ModalSurvey
        show={showModalSurvey}
        themeId={themeId}
        programId={programId}
        onCreateSurvey={handleAddSurvey}
        onChangeSurvey={handleChangeSurvey}
        onDeleteSurvey={handleDeleteSurvey}
        defaultDataSurvey={editableSurveyData}
        onHide={() => {
          setShowModalSurvey(false)
          setEditableSurveyData({})
        }}
      />
    </div>
  )
}

export default ThemeSurveys
