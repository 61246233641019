import { useRef } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Modal } from 'react-bootstrap'

import { FIELDS_INVITE_ADMIN } from '../../../utils/constantsForms'
import { sentAdminInvite } from '../../../api/admin/company'
import HookForm from '../../HookForm'
import Button from '../../Button'
import PrimaryButton from '../../PrimaryButton'

const ModalInviteAdmin = ({ show, onHide }) => {
  const formRef = useRef(null)
  const queryClient = useQueryClient()

  const mutationSentInvite = useMutation({
    mutationKey: ['sentInvite'],
    mutationFn: sentAdminInvite,
    onSuccess: () => {
      onHide()
      queryClient.invalidateQueries({ queryKey: ['companyAdmins'] })
    },
  })

  return (
    <Modal show={show} centered onHide={onHide} size="md">
      <Modal.Header closeButton>
        <Modal.Title className="blockTitle mb-0">Invite Admins</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HookForm onSubmit={mutationSentInvite.mutate} ref={formRef} fields={FIELDS_INVITE_ADMIN} />
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton
          disabled={mutationSentInvite.isPending}
          onClick={() => formRef.current.submitHandler(show)}
          text="Send invite"
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalInviteAdmin
