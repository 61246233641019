import { useCallback, useState } from 'react'
import { camelCase, snakeCase } from 'lodash'
import { Table, Tooltip, Whisper } from 'rsuite'

import { formatPhoneNumber } from '../../../../../utils/helpers'
import { Block } from '../../../../../components/SvgIcon'
import { ActionCell } from '../../../../../components/Table'
import Pagination from '../../../../../components/Pagination'
import CustomInput from '../../../../../components/CustomInput'

import './styles.css'

const TabUsers = ({ isVisible }) => {
  const [orderInfo, setOrderInfo] = useState({})
  const [search, setSearchValue] = useState('')

  const handleOrderColumn = useCallback((sortColumn, sortType) => {
    setOrderInfo({ orderBy: snakeCase(sortColumn), orderDirection: sortType })
  }, [])

  return (
    <div className={`flex-grow-1 mt-3 tabBlock ${isVisible ? 'visibleTabBlock' : ''}`}>
      <CustomInput
        defaultValue=""
        onChange={setSearchValue}
        innerClassName="innerSearchInput"
        placeholder="Search by name, email, phone"
      />
      <Table
        // loading={isLoading || isFetching}
        data={[
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: false },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: false },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: false },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: true },
          { firstName: 'John', lastName: 'Doe', phone: '+13013584770', canSendMessage: false },
        ]}
        rowHeight={60}
        autoHeight
        onSortColumn={handleOrderColumn}
        sortColumn={camelCase(orderInfo.orderBy)}
        sortType={orderInfo.orderDirection}
      >
        <Table.Column width={50} align="center">
          <Table.HeaderCell style={{ padding: 0 }} />
          <Table.Cell style={{ padding: 0 }}>
            {({ canSendMessage }) =>
              !canSendMessage && (
                <div className="h-100 d-flex align-items-center">
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        <p>Message sending forbidden</p>
                      </Tooltip>
                    }
                  >
                    <div>
                      <Block size={20} fill="var(--persian-red)" />
                    </div>
                  </Whisper>
                </div>
              )
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable verticalAlign="middle" width={240} maxWidth={300}>
          <Table.HeaderCell dataKey="name" className="headColumn">
            Name
          </Table.HeaderCell>
          <Table.Cell dataKey="name">
            {({ firstName, lastName }) => `${firstName ?? ''} ${lastName ?? ''}`}
          </Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" flexGrow={2} minWidth={170} maxWidth={300}>
          <Table.HeaderCell dataKey="phone" className="headColumn">
            Phone
          </Table.HeaderCell>
          <Table.Cell dataKey="phone">{({ phone }) => formatPhoneNumber(phone)}</Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" width={50}>
          <Table.HeaderCell className="headColumn"></Table.HeaderCell>
          <ActionCell
            actions={[
              {
                label: 'Delete',
                key: 'delete',
                function: console.log,
              },
            ]}
          />
        </Table.Column>
      </Table>
      <Pagination
        innerClassName="stickyPagination pt-2 pb-2"
        show
        total={120}
        lastPage={10}
        forcePage={1}
      />
    </div>
  )
}

export default TabUsers
