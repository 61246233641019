import r from '../utils/request'

export const getAllSurveys = (params) => {
  return r.get(`/admin/themes/${params.id}/surveys/paginate`, params)
}

export const getSurveyById = (surveyId) => {
  return r.get(`/admin/surveys/${surveyId}`)
}

export const createSurvey = (params) => {
  return r.postWithToast(`/admin/themes/${params.themeId}/surveys`, params, {
    success: 'New survey created',
  })
}

export const changeSurveyInfo = (params, tooltipMessages) => {
  return r.putWithToast(`/admin/surveys/${params.id}`, params, {
    success: 'Survey was updated',
    ...tooltipMessages,
  })
}

export const deleteSurvey = (params) => {
  return r.deleteWithToast(`/admin/surveys/${params.id}`, {}, { success: 'Survey was deleted' })
}

export const createSurveyQuestion = (params, toastMsg) => {
  return r.postWithToast(`/admin/surveys/${params.surveyId}/questions`, params, toastMsg)
}

export const activateSurvey = (surveyId) => {
  return r.postWithToast(
    `/admin/surveys/${surveyId}/publish`,
    {},
    {
      success: 'Survey is published',
      error: 'All the rules for publishing the survey have not been followed',
    }
  )
}

export const deactivateSurvey = (surveyId) => {
  return r.postWithToast(
    `/admin/surveys/${surveyId}/unpublish`,
    {},
    { success: 'Survey is unpublished' }
  )
}

export const changeSurveyQuestion = (params) => {
  return r.putWithToast(`/admin/questions/${params.id}`, params)
}

export const deleteSurveyQuestion = (params) => {
  return r.deleteWithToast(`/admin/questions/${params.id}`)
}

export const createOption = (params) => {
  return r.postWithToast(`/admin/questions/${params.questionId}/options`, params)
}

export const changeOption = (params) => {
  return r.putWithToast(`/admin/options/${params.optionId}`, params)
}

export const deleteOption = ({ optionId }) => {
  return r.deleteWithToast(`/admin/options/${optionId}`)
}

/** Routes for survey analytics */

export const getSurveyQuestions = (surveyId) => {
  return r.get(`/admin/surveys/${surveyId}/questions`)
}

export const getSurveyQuestionStatistics = (questionId) => {
  return r.get(`/admin/questions/${questionId}/statistics`)
}

export const getSurveyQuestionUserAnswers = (params) => {
  return r.get(`/admin/questions/${params.questionId}/answers`, params)
}

/** Route for get user surveys */

export const getUserSurveys = (userId) => {
  return r.get(`/admin/users/${userId}/surveys`)
}
