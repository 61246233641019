import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import { RESET_PASSWORD_EMAIL_FIELD } from '../../../utils/constantsForms'
import { sendRecoveryLink } from '../../../api/admin/account'
import { Bell } from '../../../components/SvgIcon'
import HookForm from '../../../components/HookForm'
import PrimaryButton from '../../../components/PrimaryButton'

const FormEmail = () => {
  const [showFormEnterEmail, setShowFormEnterEmail] = useState(true)
  const [recoveryEmail, setRecoveryEmail] = useState('')

  const mutationSentRecoveryLink = useMutation({
    mutationKey: ['sentRecoveryLink'],
    mutationFn: sendRecoveryLink,
    onSuccess: (_, formData) => {
      setShowFormEnterEmail(false)
      setRecoveryEmail(formData?.email)
    },
  })

  return (
    <div className="innerForm">
      {showFormEnterEmail && (
        <>
          <h1 className="blockTitle mb-2">Enter email</h1>
          <p className="blockDescription mb-0">
            Enter the email associated with your account and we'll send you link to recover password
          </p>
          <HookForm
            className="confirmInvitationForm"
            onSubmit={mutationSentRecoveryLink.mutate}
            fields={RESET_PASSWORD_EMAIL_FIELD}
            Footer={({ formState }) => (
              <PrimaryButton
                disabled={!formState.isValid || mutationSentRecoveryLink.isPending}
                customClass="big buttonSubmit"
                text="Send recovery link"
              />
            )}
          />
        </>
      )}
      {mutationSentRecoveryLink.isSuccess && (
        <div className="d-flex gap-2 align-items-center flex-column justify-content-center">
          <Bell size={50} fill="var(--swatch--cta-blue)" />
          <h1 className="blockTitle mb-2">Password Recovery Email Sent</h1>
          <p className="blockDescription mb-0 text-center">
            We've sent you an email to <b>{recoveryEmail}</b> with a link to reset your password.
            Please check your inbox and follow the instructions to regain access to your account
          </p>
        </div>
      )}
    </div>
  )
}

export default FormEmail
