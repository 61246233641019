import { useCallback, useRef } from 'react'
import { isNil } from 'lodash'

import { FORM_LOGIN_USER } from '../../../utils/constantsForms'
import useUserLogin from '../../../utils/hooks/useUserLogin'
import useTimer from '../../../utils/hooks/useTimer'
import HookFormFieldsArray from '../../../components/HookForm/HookFormFieldsArray'

import './styles.css'

const LoginUser = () => {
  const formRef = useRef(null)
  const { isLoading, handleLogin, isConfirmStep, mutationSendConfirmCode } = useUserLogin(formRef)

  const Footer = useCallback(() => {
    const { timer, onChangeTimerTime } = useTimer()

    return (
      <div className="innerFooterFormUserLogin">
        <button disabled={isLoading} className="buttonUserLogin" type="submit">
          Login
        </button>
        {isConfirmStep && (
          <p className="textResetCode text-center">
            Didn't receive code?{' '}
            {isNil(timer) ? (
              <button
                onClick={() => {
                  onChangeTimerTime(60)
                  mutationSendConfirmCode.mutate(formRef.current.watch())
                }}
                disabled={isLoading}
                type="button"
                className="resendCode d-inline"
              >
                <u>Click here</u>
              </button>
            ) : (
              <b>{timer}</b>
            )}
          </p>
        )}
      </div>
    )
  }, [isLoading])

  return (
    <section className="userLogin userPages d-flex flex-column flex-grow-1">
      <div className="innerContentUserLogin flex-grow-1">
        <img src="/images/logo.png" alt="logo" className="appLogoLogin" />
        <div className="innerFormUserLogin">
          <h1 className="titleUserLogin">Sign In</h1>
          <HookFormFieldsArray
            ref={formRef}
            Footer={Footer}
            className="formUserLogin"
            globalName="userLogin"
            onSubmit={handleLogin}
            dynamicProps={{
              code: { onSuccess: () => formRef.current?.submitHandler() },
            }}
            defaultFields={FORM_LOGIN_USER}
          />
        </div>
        <div />
      </div>
      <img src="/images/pier.webp" alt="Login" className="imgUserLogin" />
      <div className="darkerBg" />
    </section>
  )
}

export default LoginUser
