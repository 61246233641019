import { Controller } from 'react-hook-form'
import CustomDropdown from '../../../../../components/CustomDropdown'

const ControllerDelay = ({ control }) => {
  const DROPDOWN_DATA_ONBOARDING_DELAY = Array.from({ length: 12 }, (_, i) => ({
    title: `${i * 10 + 10} minutes`,
    value: i * 10 + 10,
  }))

  return (
    <Controller
      name="onboardingDelay"
      control={control}
      defaultValue=""
      rules={{ required: true }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="d-flex justify-content-between mb-3 align-items-center gap-2">
          <p className="titleCountDays">Customize message delivery time delay settings:</p>
          <CustomDropdown
            onChange={({ value }) => onChange(value)}
            customDropdownMenu="menuDelayOnboarding"
            title="Delay"
            error={error}
            value={value && `${value} minutes`}
            data={DROPDOWN_DATA_ONBOARDING_DELAY}
          />
        </div>
      )}
    />
  )
}

export default ControllerDelay
