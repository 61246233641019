import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { isNumber, some } from 'lodash'
import { Virtuoso } from 'react-virtuoso'

import {
  WEBSOCKET_EVENTS,
  WEBSOCKET_CONNECTION_CHANNEL,
  WEBSOCKET_STATES,
  CHAT_LIST_TABS,
  SIZE_PAGINATION_DIALOG_MESSAGES,
} from '../../../../../utils/constants'
import { FORM_FIELD_CHAT } from '../../../../../utils/constantsForms'
import { getUserInfoById } from '../../../../../api/admin/user'
import { getCompanyInfoById } from '../../../../../api/superAdmin/companies'
import {
  sendAdminMessage,
  sendCompanySupportMessage,
  sendSuperAdminMessage,
} from '../../../../../api/dialog'
import { formatPhoneNumber, generateJobKey } from '../../../../../utils/helpers'
import useChat from '../../../../../utils/hooks/useChat'
import useWebSocket from '../../../../../utils/hooks/useWebSocket'
import { DoubleArrowLeft, LoadingIcon } from '../../../../../components/SvgIcon'
import ChatMessage from '../../../../../components/ChatMessage'
import FormSendMessage from '../FormSendMessage'

import './styles.css'

const ChatWindow = ({ chatId, onHide, userRole, currentCompanyId, chatType }) => {
  const inputRef = useRef(null)
  const messagesListRef = useRef(null)
  const [, toggleRender] = useReducer((p) => !p, false)
  const { subscribeAdminChat } = useWebSocket()
  const webSocketSubscriptionChat = useRef(null)
  const queryClient = useQueryClient()

  const { data: user } = useQuery({
    queryKey: ['userInfo', chatId],
    queryFn: () => getUserInfoById(chatId),
    enabled:
      isNumber(chatId) &&
      [CHAT_LIST_TABS.ALL, CHAT_LIST_TABS.STARRED, CHAT_LIST_TABS.ATTENTION].includes(chatType),
  })
  const { data: company } = useQuery({
    queryKey: ['company', chatId],
    queryFn: () => getCompanyInfoById(chatId),
    enabled: isNumber(chatId) && chatType === CHAT_LIST_TABS.COMPANIES,
  })

  const getRecipientInfo = useCallback(() => {
    switch (chatType) {
      case CHAT_LIST_TABS.SUPPORT:
        return {
          name: 'Support Team',
          phone: '-',
        }
      case CHAT_LIST_TABS.ALL:
      case CHAT_LIST_TABS.STARRED:
      case CHAT_LIST_TABS.ATTENTION:
        return {
          ...user?.data,
          name: `${user?.data?.firstName || ''} ${user?.data?.lastName || ''}`,
          phone: formatPhoneNumber(user?.data?.phone),
        }
      case CHAT_LIST_TABS.COMPANIES:
        return { ...company?.data, phone: formatPhoneNumber(company?.data?.phone) }
      default:
        return {
          name: 'User',
          phone: '-',
        }
    }
  }, [chatType, user?.data, company?.data])

  const recipientInfo = useMemo(getRecipientInfo, [getRecipientInfo])

  const getRequestSendMessage = useCallback(() => {
    switch (chatType) {
      case CHAT_LIST_TABS.SUPPORT:
        return sendCompanySupportMessage
      case CHAT_LIST_TABS.ALL:
      case CHAT_LIST_TABS.STARRED:
      case CHAT_LIST_TABS.ATTENTION:
        return sendAdminMessage
      case CHAT_LIST_TABS.COMPANIES:
        return sendSuperAdminMessage
      default:
        return sendAdminMessage
    }
  }, [chatType])

  const {
    messages,
    isInitialLoading,
    updateMessageList,
    updateOldestMessage,
    lastPageReadMessages,
    lastPageUnreadMessages,
    currentPageReadMessages,
    currentPageUnreadMessages,
    updateCurrentPageReadMessages,
    updateCurrentPageUnreadMessages,
  } = useChat({ chatId, chatType, messagesListRef })
  const virtuosoFirstItemIndex = useMemo(
    () => SIZE_PAGINATION_DIALOG_MESSAGES * lastPageReadMessages - messages.length,
    [messages, lastPageReadMessages]
  )

  const onSendMessage = (data, formRef) => {
    inputRef.current.disabled = true

    const requestSendMessage = getRequestSendMessage()

    requestSendMessage({ usersIds: [chatId], companyId: chatId, ...data })
      .then(() => {
        formRef.current.resetField(FORM_FIELD_CHAT.name, { defaultValue: '' })
      })
      .then(() => {
        if (lastPageUnreadMessages === currentPageUnreadMessages || lastPageUnreadMessages < 1) {
          messagesListRef.current.scrollToIndex({
            index: messages.length - 1,
          })
        }
      })
      .finally(() => {
        inputRef.current.disabled = false
      })
  }

  const handleAddAdminMessage = (message) => {
    const isLastPage =
      currentPageUnreadMessages === lastPageUnreadMessages || lastPageUnreadMessages < 1

    if (!isLastPage) {
      updateMessageList([])
      updateOldestMessage({ createdAt: new Date() })
      return
    }

    updateMessageList((prevMessages) =>
      some(prevMessages, { id: message?.id }) ? prevMessages : [...prevMessages, message]
    )
  }

  const handleAddUserMessage = (message) => {
    const isLastPage =
      currentPageUnreadMessages === lastPageUnreadMessages || lastPageUnreadMessages < 1

    if (!isLastPage) return

    updateMessageList((prevMessages) =>
      some(prevMessages, { id: message?.id }) ? prevMessages : [...prevMessages, message]
    )
  }
  const handleMessageAdd = (message) => {
    if (message.sentBy === userRole) {
      return handleAddAdminMessage(message)
    }

    return handleAddUserMessage(message)
  }

  const onReadMessage = (externalMessageId) => {
    webSocketSubscriptionChat.current?.emit?.(WEBSOCKET_EVENTS.READ_MESSAGE, { externalMessageId })
    updateMessageList((oldState) =>
      oldState.map((item) =>
        item.externalMessageId === externalMessageId ? { ...item, isRead: true } : item
      )
    )
  }
  const getSocketChannel = useCallback(() => {
    switch (chatType) {
      case CHAT_LIST_TABS.ALL:
      case CHAT_LIST_TABS.ATTENTION:
      case CHAT_LIST_TABS.STARRED:
        return `${WEBSOCKET_CONNECTION_CHANNEL.ADMIN_CHAT}:${generateJobKey({
          companyId: currentCompanyId,
          userId: chatId,
        })}`
      case CHAT_LIST_TABS.SUPPORT:
        return `${WEBSOCKET_CONNECTION_CHANNEL.ADMIN_CHAT}:${generateJobKey({
          companyId: currentCompanyId,
        })}`
      case CHAT_LIST_TABS.COMPANIES:
        return `${WEBSOCKET_CONNECTION_CHANNEL.ADMIN_CHAT}:${generateJobKey({
          companyId: chatId,
        })}`
    }
  }, [chatType, chatId, currentCompanyId])

  const subscribeToAdminChat = async () => {
    const channel = getSocketChannel()
    webSocketSubscriptionChat.current = await subscribeAdminChat({
      channel,
      onReady: () => {
        inputRef.current.disabled = user?.data?.hasBlockedTwilio
      },
      onClose: () => {
        inputRef.current.disabled = true
      },
      handlerReceivedMessage: handleMessageAdd,
      handlerChangeTwilioStatus: (newTwilioStatus) => {
        queryClient.setQueryData(['userInfo', chatId], ({ data: oldUserData }) => {
          return {
            data: { ...oldUserData, hasBlockedTwilio: newTwilioStatus },
          }
        })
      },
    })
  }

  useEffect(() => {
    subscribeToAdminChat()

    return () => {
      if (webSocketSubscriptionChat.current?.state === WEBSOCKET_STATES.OPEN) {
        webSocketSubscriptionChat.current.close()
      }
    }
  }, [getSocketChannel])

  return (
    <div className="innerChatTab">
      <div className="chatHeaderSidebar">
        <div className="innerPreviewChat">
          <button onClick={onHide} className="btnBack position-relative top-0">
            <DoubleArrowLeft size={20} className="m-0" />
          </button>
          <p className="userIcon userPreviewMessage">{recipientInfo?.name?.trim()[0]}</p>
          <div className="infoPreviewChat">
            <p className="infoPreviewChatUserName">{recipientInfo?.name}</p>
            <p className="infoPreviewChatLastMessage">{recipientInfo?.phone}</p>
          </div>
        </div>
      </div>
      {user?.data?.hasBlockedTwilio && (
        <p className="blockedMessage">You've been blocked by the user</p>
      )}
      <div className="globalWidowChat">
        {!isInitialLoading ? (
          <Virtuoso
            data={messages}
            ref={messagesListRef}
            firstItemIndex={virtuosoFirstItemIndex}
            alignToBottom
            increaseViewportBy={200}
            itemContent={(_, rowData) => (
              <ChatMessage {...rowData} onRead={onReadMessage} userName={recipientInfo?.name} />
            )}
            startReached={() => {
              if (lastPageReadMessages > currentPageReadMessages) {
                console.log('startReached')
                toggleRender()
                updateCurrentPageReadMessages((oldCurrentPage) => oldCurrentPage + 1)
              }
            }}
            endReached={() => {
              if (lastPageUnreadMessages > currentPageUnreadMessages) {
                console.log('endReached')
                toggleRender()
                updateCurrentPageUnreadMessages((oldCurrentPage) => oldCurrentPage + 1)
              }
            }}
          />
        ) : (
          <LoadingIcon className="loadingChatMessages" />
        )}
      </div>
      <FormSendMessage onSubmit={onSendMessage} inputRef={inputRef} />
    </div>
  )
}

const mapStateToProps = ({ currentCompany, user }) => ({
  currentCompanyId: currentCompany.id,
  userRole: user.role,
})

export default connect(mapStateToProps)(ChatWindow)
