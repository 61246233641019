import { useEffect, useRef } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { routes } from '../../router/routes'
import { confirmResetPassword, getRecoveryTokenStatus } from '../../api/admin/account'
import { setLoadingApp } from '../../redux/actions/ui'
import LayoutGuest from '../../components/LayoutGuest'
import HookForm from '../../components/HookForm'
import PrimaryButton from '../../components/PrimaryButton'
import FormEmail from './components/FormEmail'
import { generateFormFields } from './generateFormFieldResetPassword'

const ResetPassword = ({ setLoadingApp }) => {
  const formRef = useRef(null)
  const navigation = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const mutationConfirmResetPassword = useMutation({
    mutationKey: ['confirmResetPassword'],
    mutationFn: (formData) => confirmResetPassword({ ...formData, token }),
    onSuccess: () => {
      navigation(routes.adminLogin)
    },
  })

  const { data: recoveryTokenStatus, isLoading: isLoadingRecoveryTokenStatus } = useQuery({
    queryKey: ['recoveryTokenStatus'],
    queryFn: () => getRecoveryTokenStatus(token),
    enabled: Boolean(token),
  })

  useEffect(() => {
    setLoadingApp(isLoadingRecoveryTokenStatus)
  }, [isLoadingRecoveryTokenStatus])

  return (
    <LayoutGuest>
      <div className="innerCenterForm container">
        {Boolean(!token) || !recoveryTokenStatus?.data ? <FormEmail /> : null}
        {Boolean(token) && recoveryTokenStatus?.data ? (
          <div className="innerForm">
            <h1 className="blockTitle mb-2">Enter new password</h1>
            <p className="blockDescription mb-0">
              Here you enter new password, after that we reset it
            </p>
            <HookForm
              className="confirmInvitationForm"
              ref={formRef}
              onSubmit={(data) => mutationConfirmResetPassword.mutate(data)}
              fields={generateFormFields(formRef)}
              Footer={({ formState }) => (
                <PrimaryButton
                  disabled={!formState.isValid || mutationConfirmResetPassword.isPending}
                  customClass="big buttonSubmit"
                  text="Save password"
                />
              )}
            />
          </div>
        ) : null}
      </div>
    </LayoutGuest>
  )
}

const mapDispatchToProps = {
  setLoadingApp,
}

export default connect(null, mapDispatchToProps)(ResetPassword)
