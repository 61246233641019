import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { getMe } from '../../../api/auth'
import { setLoadingApp } from '../../../redux/actions/ui'
import useUserAuth from '../useUserAuth'
import { updateToken } from '../../auth'

const useAutoLoginWithToken = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { authUser } = useUserAuth()

  const autoLoad = async () => {
    const token = searchParams.get('token')

    try {
      if (token) {
        dispatch(setLoadingApp(true))
        updateToken(token)
        const { data: userData } = await getMe()
        await authUser({ userData, token, withRedirect: true })
        dispatch(setLoadingApp(false))
      }
    } catch (e) {
      console.log('ERROR invalid auto verification by token ', e)
    } finally {
      dispatch(setLoadingApp(false))
    }
  }

  useEffect(() => {
    autoLoad()
  }, [])
}

export default useAutoLoginWithToken
