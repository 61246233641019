import { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import Linkify from 'react-linkify'

import { DATE_FORMAT_MDY } from '../../utils/constants'
import { FillHeart, OutlineHeart } from '../SvgIcon'
import IconButton from '../IconButton'

import './styles.css'

const ContentTextMessage = ({ message, date }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div onClick={() => setIsExpanded((oldState) => !oldState)} className="content textRow">
      <div className="message">
        <Linkify>
          <span className={`collapsibleText ${isExpanded ? 'expanded' : ''}`}>{message}</span>
        </Linkify>
        <div className="d-flex align-items-center justify-content-between mt-2">
          <p className="contentDeliveryDate mobile">{date}</p>
          <button className="toggleButton" onClick={() => setIsExpanded((oldState) => !oldState)}>
            {isExpanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
      <p className="contentDeliveryDate">{date}</p>
    </div>
  )
}

const Content = ({
  title,
  link,
  image,
  artist,
  isFavorite,
  deliveryDate,
  onClickHeart,
  message,
  currentCompanyPermissions,
}) => {
  const HeartIcon = useMemo(() => (isFavorite ? FillHeart : OutlineHeart), [isFavorite])
  const tooltipHeartIconText = useMemo(
    () => (isFavorite ? 'Favorite content' : 'Add to favorite'),
    [isFavorite]
  )
  const formattedDate = useMemo(() => moment(deliveryDate).format(DATE_FORMAT_MDY), [deliveryDate])

  if (!currentCompanyPermissions?.useContent) {
    return <ContentTextMessage message={message} date={formattedDate} />
  }

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="content">
      <img src={image} alt={`${title} content image`} loading="lazy" className="contentImg" />
      <p className="contentTitle">
        {title} <span className="contentArtist mobile">{artist}</span>
      </p>
      <p className="contentArtist">{artist}</p>
      <p className="contentDeliveryDate">{formattedDate}</p>
      <div className="innerHeartColums">
        <IconButton
          buttonClassName="cursorPointer bg-transparent p-0"
          placement="auto"
          renderTooltipContent={() => <p>{tooltipHeartIconText}</p>}
        >
          <HeartIcon
            onClick={(e) => {
              e.preventDefault()
              onClickHeart({ isFavorite })
            }}
            fill="var(--swatch--cta-blue)"
            size={25}
          />
        </IconButton>
      </div>
    </a>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})
export default connect(mapStateToProps)(Content)
