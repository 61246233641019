import { useState } from 'react'

import ModalManageGroup from '../../../components/Modals/ModalManageGroup'
import { Plus } from '../../../components/SvgIcon'
import Button from '../../../components/Button'
import Group from './components/Group'
import GroupPreview from './components/GroupPreview'

import './styles.css'

const UserGroups = () => {
  const [showModalCreateGroup, setShowModalCreateGroup] = useState(false)

  return (
    <div className="containerAdminMainInfo d-flex align-items-stretch gap-3 mw-100 h-100">
      <div className="innerGroupList pt-4">
        <div className="ps-4 pe-4">
          <p className="title">Groups</p>
          <Button
            onClick={() => setShowModalCreateGroup(true)}
            customClass="d-flex pt-2 pb-2 ps-3 pe-3 align-items-center justify-content-start gap-2 mw-100 w-100"
          >
            <Plus size={18} fill="var(--catalina-blue)" />
            Add Group
          </Button>
        </div>
        <div className="groupList">
          <GroupPreview name="Pizza 1" memberCount={3} />
          <GroupPreview isActive name="Pizza 2" memberCount={10} />
          <GroupPreview name="Pizza 3" memberCount={0} />
          <GroupPreview name="Pizza 4" memberCount={12} />
          <GroupPreview name="Pizza 5" memberCount={100} />
          <GroupPreview name="Pizza 6" memberCount={56} />
          <GroupPreview name="Pizza 7" memberCount={89} />
        </div>
      </div>
      <Group />
      <ModalManageGroup show={showModalCreateGroup} onHide={() => setShowModalCreateGroup(false)} />
    </div>
  )
}

export default UserGroups
