import r from '../../utils/request'

export const getContactMessage = () => {
  return r.get('/admin/companies/current/contact')
}

export const updateContactMessage = (params) => {
  return r.putWithToast('/admin/companies/current/contact', params, {
    error: 'Failed to update contact message.',
    pending: 'Updating...',
    success: 'Contact message updated successfully.',
  })
}
