import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Toggle, Tooltip, Whisper } from 'rsuite'

import { routes } from '../../../../router/routes'
import { CHAT_LIST_TABS, PIPELINE_TYPES, PRICE_TYPES, ROLES } from '../../../../utils/constants'
import { getMessagesCount } from '../../../../api/admin/messages'
import { publishTheme, unpublishTheme } from '../../../../api/admin/themes'
import { WarningAmber } from '../../../../components/SvgIcon'
import ModalConfirm from '../../../../components/Modals/ModalConfirm'

const ToggleActivateTheme = ({ isPublished, userRole, priceInfo }) => {
  const [isThemePublishModalVisible, setThemePublishModalVisible] = useState(false)
  const navigation = useNavigate()
  const { themeId } = useParams()
  const queryClient = useQueryClient()

  const mutationPublishTheme = useMutation({
    mutationKey: ['publishTheme', themeId],
    mutationFn: () => publishTheme(themeId, { success: 'Theme activated' }),
    onSuccess: () =>
      queryClient.setQueryData(['themeInfo', themeId], ({ data }) => {
        return {
          data: { ...data, isPublished: true },
        }
      }),
  })
  const mutationUnpublishTheme = useMutation({
    mutationKey: ['unPublishTheme', themeId],
    mutationFn: () => unpublishTheme(themeId, { success: 'Theme activated' }),
    onSuccess: () =>
      queryClient.setQueryData(['themeInfo', themeId], ({ data }) => {
        return {
          data: { ...data, isPublished: false },
        }
      }),
  })

  const { data: countContentMessages } = useQuery({
    queryKey: ['countContentMessages', themeId],
    queryFn: () => getMessagesCount({ themeId, pipelineType: PIPELINE_TYPES.CONTENT }),
    enabled: priceInfo?.type === PRICE_TYPES.ONE_TIME,
  })

  const handleClickToggle = useCallback(() => {
    if (userRole !== ROLES.ROLE_SUPER_ADMIN) {
      setThemePublishModalVisible(true)
      return
    }

    const mutation = isPublished ? mutationUnpublishTheme : mutationPublishTheme
    mutation.mutate()
  }, [userRole, isPublished, mutationPublishTheme, mutationUnpublishTheme])

  return (
    <div className="d-flex align-items-start gap-2">
      {priceInfo?.type === PRICE_TYPES.ONE_TIME &&
      countContentMessages?.data < priceInfo?.extensionDays ? (
        <Whisper
          placement="top"
          controlId={themeId}
          trigger="hover"
          speaker={
            <Tooltip>
              When you created this <b>one-time plan</b>, you specified that it would contain{' '}
              <b>{priceInfo?.extensionDays}</b> messages. Currently, the number of messages in the
              application is <b>{countContentMessages?.data}</b>, which is less than you specified.
            </Tooltip>
          }
        >
          <div className="d-inline-block me-2">
            <WarningAmber className="jumpShakingAnimation" fill="var(--catalina-blue)" size={30} />
          </div>
        </Whisper>
      ) : null}
      <Toggle
        size="lg"
        color="var(--main-active-theme-status)"
        className="toggleThemeStatus"
        loading={mutationUnpublishTheme.isPending || mutationPublishTheme.isPending}
        onChange={handleClickToggle}
        checked={isPublished}
        checkedChildren="Activated"
        unCheckedChildren="Deactivated"
      />
      <ModalConfirm
        title="Publish Theme"
        text={
          <p>
            Only a super admin can publish themes. Please connect with support to assist you with
            publishing this program. You can reach out through our chat feature or contact support
            directly.
          </p>
        }
        show={isThemePublishModalVisible}
        onHide={() => setThemePublishModalVisible(false)}
        customClassName="md"
        size="md"
        handleClickButton={() => {
          setThemePublishModalVisible(false)
          navigation(`${routes.messaging}?chatType=${CHAT_LIST_TABS.SUPPORT}`)
        }}
        textButton="Chat with support"
      />
    </div>
  )
}
const mapStateToProps = ({ user }) => ({
  userRole: user.role,
})

export default connect(mapStateToProps)(ToggleActivateTheme)
