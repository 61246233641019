import { useCallback, useEffect, useState } from 'react'

import { CHAT_LIST_TABS, SIZE_PAGINATION_DIALOG_MESSAGES } from '../constants'
import {
  getDialogMessages,
  getDialogMessagesByCompanyId,
  getDialogWithSupport,
  getOldestDateCompanyMessage,
  getOldestDateMessage,
  getOldestDateSupportMessage,
} from '../../api/dialog'

const useChat = ({ chatId, chatType, messagesListRef }) => {
  const [messages, setMessages] = useState([])
  const [oldestMessage, setOldestMessage] = useState({})
  const [loadingInitialMessages, setLoadingInitialMessages] = useState(true)

  const [currentPageReadMessages, setCurrentPageReadMessages] = useState(1)
  const [lastPageReadMessages, setLastPageReadMessages] = useState(1)

  const [currentPageUnreadMessages, setCurrentPageUnreadMessages] = useState(1)
  const [lastPageUnreadMessages, setLastPageUnreadMessages] = useState(1)

  const getMessagesRequest = useCallback(() => {
    switch (chatType) {
      case CHAT_LIST_TABS.ALL:
      case CHAT_LIST_TABS.STARRED:
      case CHAT_LIST_TABS.ATTENTION:
        return getDialogMessages
      case CHAT_LIST_TABS.SUPPORT:
        return getDialogWithSupport
      case CHAT_LIST_TABS.COMPANIES:
        return getDialogMessagesByCompanyId
      default:
        return getDialogMessages
    }
  }, [chatType])

  const getOldestDateMessageRequest = useCallback(() => {
    switch (chatType) {
      case CHAT_LIST_TABS.ALL:
      case CHAT_LIST_TABS.STARRED:
      case CHAT_LIST_TABS.ATTENTION:
        return getOldestDateMessage
      case CHAT_LIST_TABS.SUPPORT:
        return getOldestDateSupportMessage
      case CHAT_LIST_TABS.COMPANIES:
        return getOldestDateCompanyMessage
      default:
        return getOldestDateMessage
    }
  }, [chatType])

  const calculateIndexUnreadMessage = (messages, oldestMessage) => {
    const oldestMessageIndex = messages.findIndex(
      ({ createdAt }) => createdAt === oldestMessage.createdAt
    )
    const calculatedIndex = oldestMessageIndex < 5 ? oldestMessageIndex : oldestMessageIndex - 5

    return calculatedIndex < 0 ? Math.max(0, messages.length - 1) : calculatedIndex
  }

  const fetchNewReadMessages = useCallback(async () => {
    try {
      const requestGetMessages = getMessagesRequest()

      const { data: readMessages } = await requestGetMessages({
        userId: chatId,
        companyId: chatId,
        size: SIZE_PAGINATION_DIALOG_MESSAGES,
        page: currentPageReadMessages,
        createdAt: oldestMessage.createdAt,
        isRead: true,
      })
      setLastPageReadMessages(readMessages?.lastPage ?? 1)
      setMessages((oldHistory) => [...readMessages.data.reverse(), ...oldHistory])
    } catch (error) {
      console.log('Error on fetchNewReadMessages', error)
    }
  }, [currentPageReadMessages])

  const fetchNewUnreadMessages = useCallback(async () => {
    try {
      const requestGetMessages = getMessagesRequest()

      const { data: unreadMessages } = await requestGetMessages({
        userId: chatId,
        companyId: chatId,
        size: SIZE_PAGINATION_DIALOG_MESSAGES,
        page: currentPageUnreadMessages,
        createdAt: oldestMessage.createdAt,
        isRead: false,
      })
      setLastPageUnreadMessages(unreadMessages?.lastPage ?? 1)
      setMessages((oldHistory) => [...oldHistory, ...unreadMessages.data])
    } catch (error) {
      console.log('Error on fetchNewUnreadMessages', error)
    }
  }, [currentPageUnreadMessages])

  const initialMessagesLoading = async () => {
    try {
      const requestGetOldestDateMessage = getOldestDateMessageRequest()
      const requestGetMessages = getMessagesRequest()
      setLoadingInitialMessages(true)
      const { data: oldestMessage } = await requestGetOldestDateMessage(chatId)
      setOldestMessage(oldestMessage)

      const { data: readMessages } = await requestGetMessages({
        userId: chatId,
        companyId: chatId,
        size: SIZE_PAGINATION_DIALOG_MESSAGES,
        page: currentPageReadMessages,
        createdAt: oldestMessage.createdAt,
        isRead: true,
      })
      setLastPageReadMessages(readMessages?.lastPage ?? 1)

      const { data: unreadMessages } = await requestGetMessages({
        userId: chatId,
        companyId: chatId,
        size: SIZE_PAGINATION_DIALOG_MESSAGES,
        page: currentPageUnreadMessages,
        createdAt: oldestMessage.createdAt,
        isRead: false,
      })

      setLastPageUnreadMessages(unreadMessages?.lastPage ?? 1)
      const chatHistory = [...readMessages.data.reverse(), ...unreadMessages.data]

      setMessages(chatHistory)

      setTimeout(() => {
        messagesListRef.current?.scrollToIndex(
          calculateIndexUnreadMessage(chatHistory, oldestMessage)
        )
      }, 100)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingInitialMessages(false)
    }
  }

  useEffect(() => {
    initialMessagesLoading()
  }, [chatId])

  useEffect(() => {
    if (!loadingInitialMessages) {
      fetchNewReadMessages()
    }
  }, [currentPageReadMessages])

  useEffect(() => {
    if (!loadingInitialMessages) {
      fetchNewUnreadMessages()
    }
  }, [currentPageUnreadMessages])

  return {
    messages,
    updateMessageList: setMessages,
    isInitialLoading: loadingInitialMessages,
    lastPageReadMessages,
    lastPageUnreadMessages,
    currentPageReadMessages,
    currentPageUnreadMessages,
    updateOldestMessage: setOldestMessage,
    updateCurrentPageReadMessages: setCurrentPageReadMessages,
    updateCurrentPageUnreadMessages: setCurrentPageUnreadMessages,
  }
}
export default useChat
