import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

import { setShowEditorGlobalOnboardingSMS, setShowModalCreateCompany } from '../../redux/actions/ui'
import { LoadingIcon } from '../SvgIcon'
import ModalOnboardingMessage from '../Modals/ModalOnboardingMessage'
import ModalCreateCompany from '../Modals/ModalCreateCompany'
import AdminMenu from '../AdminMenu'
import Header from './components/Header'
import IncompleteSetupBanner from './components/IncompleteSetupBanner'

import './styles.css'

const LayoutAdmin = ({
  children,
  loadingInfo,
  currentCompanyName,
  showModalCreateCompany,
  setShowModalCreateCompany,
  showIncompleteSetupBanner,
  showEditorGlobalOnboardingSMS,
  setShowEditorGlobalOnboardingSMS,
}) => {
  return (
    <section data-incomplete-setup-banner={showIncompleteSetupBanner} className="adminLayout">
      <IncompleteSetupBanner show={showIncompleteSetupBanner} />
      <Helmet title={`${currentCompanyName}: Admin Panel`} />
      <AdminMenu />
      <div className="innerInfoSection flex-grow-1 position-relative mw-100">
        <Header />
        <div className="innerAdminPages">
          {children ?? <Outlet />}
          {loadingInfo && (
            <div className="innerLoadingAdminContent">
              <LoadingIcon className="loading" />
            </div>
          )}
        </div>
      </div>
      <ModalOnboardingMessage
        show={showEditorGlobalOnboardingSMS}
        onHide={() => setShowEditorGlobalOnboardingSMS(false)}
      />
      <ModalCreateCompany
        show={showModalCreateCompany}
        onHide={() => setShowModalCreateCompany(false)}
      />
    </section>
  )
}

const mapStateToProps = ({ ui, currentCompany }) => ({
  loadingInfo: ui.loadingAdminDataProject,
  showEditorGlobalOnboardingSMS: ui.showEditorGlobalOnboardingSMS,
  showIncompleteSetupBanner: currentCompany.profileProgress < 100,
  showModalCreateCompany: ui.showModalCreateCompany,
  currentCompanyName: currentCompany.name,
})
const mapDispatchToProps = {
  setShowEditorGlobalOnboardingSMS,
  setShowModalCreateCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAdmin)
