import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import ThemeTags from '../components/ThemeTags'
import UploadUserFile from '../components/UploadUserFile'
import EmergencyContentPicker from '../components/EmergencyContentPicker'
import ModalHubspotIntegration from '../../../../components/Modals/ModalHubspotIntegration'

const General = ({ isActiveTab, themeData, currentCompanyPermissions }) => {
  const [showModalHubspotIntegration, setShowModalHubspotIntegration] = useState(false)
  const { themeId } = useParams()

  return (
    <div className={`tabBlock ${isActiveTab ? 'visibleTabBlock' : ''}`}>
      <EmergencyContentPicker
        themeType={themeData?.activePrice?.type}
        defaultEmergencyContentId={themeData?.emergencyContentId}
      />
      <ThemeTags />
      <UploadUserFile isPublished={themeData?.isPublished} />
      {currentCompanyPermissions?.useContent && (
        <div>
          <p className="blockTitle underline mt-5 pb-3 mb-3">Integrations</p>
          <div>
            <button className="integrationBtn" onClick={() => setShowModalHubspotIntegration(true)}>
              <img src="/images/hubspotIcon.webp" alt="Hubspot Integration" />
              <span>HubSpot Integration</span>
            </button>
          </div>
        </div>
      )}
      <ModalHubspotIntegration
        themeId={themeId}
        show={showModalHubspotIntegration}
        onHide={() => setShowModalHubspotIntegration(false)}
      />
    </div>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})

export default connect(mapStateToProps)(General)
