import { NavLink } from 'react-router-dom'
import { Tooltip, Whisper } from 'rsuite'
import { connect } from 'react-redux'

const NavigateLink = ({
  route,
  text,
  Icon,
  hideTooltip,
  onClick,
  className = '',
  hasAccess = () => true,
  currentCompany,
  companiesLength,
  iconProps,
  showText = false,
}) => {
  if (!hasAccess({ currentCompany, companiesLength })) {
    return null
  }

  return (
    <Whisper
      disabled={hideTooltip}
      placement="autoHorizontal"
      trigger="hover"
      speaker={<Tooltip>{text}</Tooltip>}
    >
      <NavLink
        className={({ isActive }) =>
          `adminMenuLink ${className} ${isActive ? 'activeMenuAdmin' : ''}`
        }
        to={route}
        onClick={onClick}
      >
        <Icon className="iconAdminMenu" size={18} {...iconProps} />
        {showText && <span>{text}</span>}
      </NavLink>
    </Whisper>
  )
}

const mapStateToProps = ({ currentCompany }) => ({ currentCompany })

export default connect(mapStateToProps)(NavigateLink)
