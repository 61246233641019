import { useRef, useState } from 'react'
import { isEmpty, find, omitBy } from 'lodash'

import { ERROR_VERIFICATION_CODE, INPUT_TYPES } from '../../utils/constants'
import { changeOption, createOption, deleteOption } from '../../api/survey'
import { ArrowRight, Bin, Pencil } from '../SvgIcon'
import HookForm from '../HookForm'
import PrimaryButton from '../PrimaryButton'
import { isNullOrEmpty } from '../../utils/helpers'

const SurveyOption = ({
  nextQuestionId = null,
  numberOfOption,
  onDeleteOption,
  onChangeOption,
  itemQuestion,
  onAddOption,
  optionText,
  optionId,
  dropdownNextMessages,
  isNewOption = false,
}) => {
  const [isEditingMode, setIsEditingMode] = useState(false)
  const formRef = useRef(null)

  const nextQuestion = find(dropdownNextMessages, { id: nextQuestionId })

  const Footer = () => {
    const handleDeleteOption = () => {
      if (isNewOption) {
        onDeleteOption()
        return
      }

      deleteOption({ optionId }).then(onDeleteOption)
    }

    return (
      <div className="d-flex align-items-center gap-3 ms-auto">
        <Bin
          size={20}
          className="cursorPointer"
          fill="var(--persian-red)"
          onClick={handleDeleteOption}
        />
        <PrimaryButton customClass="buttonNewItemList" text="Save" />
      </div>
    )
  }

  const handleOptionError = (error) => {
    const code = error?.response?.data?.code
    if (code === ERROR_VERIFICATION_CODE) {
      formRef.current.setError('question')
    }
  }

  const handleSubmit = ({ option, question }) => {
    if (isEmpty(option) && isEmpty(question)) {
      setIsEditingMode(false)
      onDeleteOption()
      return
    }

    const optionData = omitBy(
      {
        name: option,
        type: 'redirect',
        next_question_id: question.id,
      },
      isNullOrEmpty
    )

    const handleOptionChange = () => {
      changeOption({ optionId, ...optionData })
        .then(onChangeOption)
        .then(() => setIsEditingMode(false))
        .catch(handleOptionError)
    }

    const handleOptionCreation = () => {
      createOption({ questionId: itemQuestion.id, ...optionData })
        .then(onAddOption)
        .then(onDeleteOption)
        .catch(handleOptionError)
    }

    if (isEditingMode) {
      handleOptionChange()
    } else {
      handleOptionCreation()
    }
  }

  return (
    <div className="d-flex align-items-center gap-3 optionVariant">
      <p className="questionOptionCircle">{numberOfOption}</p>
      {!isEditingMode && !isNewOption ? (
        <>
          <p className="questionOption">{optionText}</p>
          <ArrowRight size={30} />
          <p className="questionOptionCircle">{nextQuestion?.title}</p>
          <Pencil onClick={() => setIsEditingMode(true)} className="pencilSurvey" size={20} />
        </>
      ) : (
        <HookForm
          ref={formRef}
          onSubmit={handleSubmit}
          className="d-flex flex-grow-1 gap-4"
          fields={[
            {
              name: 'option',
              innerClassName: 'surveyOptionInput',
              defaultValue: optionText ?? '',
              placeholder: 'Some option...',
              rules: {
                required: true,
              },
            },
            {
              name: 'question',
              type: INPUT_TYPES.INPUT_DROPDOWN,
              title: 'Select question...',
              defaultValue: nextQuestion,
              data: dropdownNextMessages,
              customDropdownMenu: 'dropMenuInfo',
              titleClass: 'dropzoneTitle',
              innerDropdown: 'dropdownZone itemFormUserInfo dropdownSurveyQuestion',
              rules: {
                required: true,
              },
            },
          ]}
          Footer={Footer}
        />
      )}
    </div>
  )
}

export default SurveyOption
