import { useCallback, useMemo, useRef } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { Modal } from 'rsuite'

import { MANAGE_GROUP_FIELDS } from '../../../utils/constantsForms'
import { createProgram, updateProgram } from '../../../api/admin/programs'
import { BookContent } from '../../SvgIcon'
import HookForm from '../../HookForm'
import PrimaryButton from '../../PrimaryButton'

const ModalManageGroup = ({ show, onHide, onExited, selectedProgramInfo }) => {
  const formRef = useRef(null)
  const isEditableMode = useMemo(() => !isNil(selectedProgramInfo), [selectedProgramInfo])
  const queryClient = useQueryClient()

  const onSuccessSaveInfo = useCallback(
    () => queryClient.invalidateQueries({ queryKey: ['programs'] }).then(onHide),
    []
  )

  const mutationCreateProgram = useMutation({
    mutationKey: ['createProgram'],
    mutationFn: (data) => createProgram(data),
    onSuccess: onSuccessSaveInfo,
  })

  const mutationUpdateProgram = useMutation({
    mutationKey: ['updateProgram', selectedProgramInfo?.id],
    mutationFn: (data) => updateProgram({ id: selectedProgramInfo?.id, ...data }),
    onSuccess: onSuccessSaveInfo,
  })

  return (
    <Modal open={show} onClose={onHide} onExited={onExited}>
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex align-items-center gap-2">
            <BookContent size={25} fill="var(--catalina-blue)" />
            <b>{isEditableMode ? 'Edit Group' : 'New Group'}</b>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'visible' }}>
        <p>
          In this modal, the admin can manage programs by either creating new ones or editing
          existing ones. To create a new program, the admin enters the program name and saves it.
          When editing a program, the current name is displayed, allowing the admin to update it and
          save the changes.
        </p>
        <HookForm
          ref={formRef}
          defaultValues={selectedProgramInfo}
          onSubmit={isEditableMode ? mutationUpdateProgram.mutate : mutationCreateProgram.mutate}
          className="mt-3"
          fields={MANAGE_GROUP_FIELDS}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center gap-2 justify-content-end mt-1">
        <PrimaryButton
          text="Save"
          onClick={() => formRef.current?.submitHandler()}
          disabled={mutationUpdateProgram.isPending || mutationCreateProgram.isPending}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalManageGroup
