import axios from 'axios'
import { isEmpty } from 'lodash'

import { AUTH_TOKEN } from './constants'
import localStorage from './localStorage'

export const clearSession = () => {
  updateToken()
}

export const updateToken = (token = null) => {
  if (isEmpty(token)) {
    localStorage.removeItem(AUTH_TOKEN)
    delete axios.defaults.headers.common['Authorization']
    return null
  }

  localStorage.setItem(AUTH_TOKEN, token)
  axios.defaults.headers.common['Authorization'] = 'bearer ' + token
  return token
}
