import { useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer } from 'rsuite'

import HookForm from '../HookForm'

const ModalFilter = ({
  show,
  onHide,
  onSubmit,
  onReset,
  defaultValues = {},
  currentCompanyPermissions,
  formFields = [],
}) => {
  const formRef = useRef(null)

  const formattedFormFields = useMemo(
    () =>
      formFields.filter((field) => {
        // If the field has a `permissions` property, check if all required permissions exist in `currentCompanyPermissions`
        if (field.permissions) {
          return field?.permissions.every((permission) => currentCompanyPermissions?.[permission])
        }
        // Include fields without a `permissions` property
        return true
      }),
    [currentCompanyPermissions, formFields]
  )

  return (
    <Drawer placement="right" open={show} onClose={onHide}>
      <Drawer.Header closeButton>
        <Drawer.Title>Filter</Drawer.Title>
        <Drawer.Actions>
          <Button onClick={onReset}>Reset</Button>
          <Button
            appearance="primary"
            style={{ backgroundColor: 'var(--catalina-blue)' }}
            onClick={() => formRef.current.handleSubmit(onSubmit)()}
          >
            Save
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body className="p-4">
        <HookForm
          ref={formRef}
          onSubmit={onSubmit}
          className="d-flex flex-column gap-3"
          fields={formattedFormFields}
          defaultValues={defaultValues}
        />
      </Drawer.Body>
    </Drawer>
  )
}

const mapStateToProps = ({ currentCompany }) => {
  return {
    currentCompanyPermissions: currentCompany.permissions,
  }
}

export default connect(mapStateToProps)(ModalFilter)
