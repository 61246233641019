import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Tree } from 'react-d3-tree'

import { SURVEY_OPTION_KEY, SURVEY_QUESTION_TYPES } from '../../../utils/constants'
import { getSurveyById } from '../../../api/survey'
import useD3Tree from '../../../utils/hooks/useD3Tree'
import { Cross, LoadingIcon } from '../../SvgIcon'
import OptionIcon from './components/OptionIcon'
import OpenEndedIcon from './components/OpenEndedIcon'
import StarRatingIcon from './components/StarRatingIcon'
import QuestionCircleIcon from './components/QuestionCircleIcon'

const ModalSurveyTree = ({ show, onHide, surveyId }) => {
  const [dataTree, setDataTree] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { containerRef, translate, transformSurveyData } = useD3Tree({
    defaultTranslate: { x: 0, y: 0 },
  })

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
    return (
      <g>
        {nodeDatum?.attributes?.type === SURVEY_OPTION_KEY && (
          <OptionIcon size={20} x="-10" y="-5" onClick={toggleNode} />
        )}
        {nodeDatum?.attributes?.type === SURVEY_QUESTION_TYPES.RATING_SCALE && (
          <StarRatingIcon fill="var(--catalina-blue)" size="20" x="-10" y="-5" />
        )}
        {nodeDatum?.attributes?.type === SURVEY_QUESTION_TYPES.SINGlE_CHOICE_SELECT && (
          <QuestionCircleIcon size={20} x="-10" y="-5" onClick={toggleNode} />
        )}
        {nodeDatum?.attributes?.type === SURVEY_QUESTION_TYPES.OPEN_END && (
          <OpenEndedIcon size={20} x="-10" y="-5" onClick={toggleNode} />
        )}
        <text fill="black" strokeWidth="1" x="20" y="10">
          {nodeDatum.name}
        </text>
      </g>
    )
  }

  useEffect(() => {
    if (show) {
      setDataTree({})
      setIsLoading(true)
      getSurveyById(surveyId)
        .then(({ data }) => {
          const { questions, finalText } = data

          // Transform the data starting from the root question
          const transformedData = transformSurveyData(
            [...questions, { id: null, name: finalText }],
            questions[0]?.id
          )
          setDataTree(transformedData)
        })
        .finally(() => setIsLoading(false))
    }
  }, [show])

  return (
    <Modal size="xl" centered show={show} onHide={onHide}>
      <div ref={containerRef} style={{ height: '60vh' }}>
        {!isLoading ? (
          <>
            <Cross size={25} onClick={onHide} className="crossModalSupport" />
            <Tree
              data={dataTree}
              translate={translate}
              renderCustomNodeElement={renderRectSvgNode}
              orientation="vertical"
            />
          </>
        ) : (
          <div className="d-flex align-items-start justify-content-center h-100">
            <LoadingIcon className="loadingSurveyTree" />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalSurveyTree
