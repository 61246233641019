import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { isEmpty, isNil } from 'lodash'

import { HISTORY_BACK, MESSAGE_TYPES, PIPELINE_TYPES } from '../../../utils/constants'
import { setLoadingAdminProjectData } from '../../../redux/actions/ui'
import { getMessageVariables } from '../../../api/admin/messages'

import {
  ControllerMessageInput,
  ControllerTags,
  ControllerDelay,
  ControllerContent,
  ControllerWaitingDays,
} from './components/FormFields'
import Footer from './components/Footer'

import pipelineConfig from './pipelineConfig'

import './styles.css'

const Message = ({ setLoadingAdminProjectData }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { pipelineType = PIPELINE_TYPES.CONTENT, messageType = MESSAGE_TYPES.RELATIVE } =
    Object.fromEntries(searchParams)

  const hookFormMethods = useForm()
  const { reset, watch, handleSubmit } = hookFormMethods

  const { '*': messageId, themeId } = useParams()
  const isEditableMode = useMemo(() => !isEmpty(messageId), [messageId])

  const { formFields, arrayValues, changeMessage, createMessage, getMessageInfo } = useMemo(
    () => pipelineConfig[pipelineType]?.[messageType] || {},
    [pipelineType, messageType]
  )
  const manageThemeMessage = useMemo(
    () => (!isEditableMode ? createMessage : changeMessage),
    [isEditableMode]
  )

  const renderFormFields = {
    body: ControllerMessageInput,
    tagsIds: ControllerTags,
    contentsIds: ControllerContent,
    onboardingDelay: ControllerDelay,
    waitingDays: ControllerWaitingDays,
  }

  const resetMessageForm = useCallback(({ messageInfo }) => {
    const { messageType, absoluteDate, waitingDays } = messageInfo
    const isAbsoluteMessageType = messageType === MESSAGE_TYPES.ABSOLUTE

    reset({
      ...messageInfo,
      waitingDays: isAbsoluteMessageType ? absoluteDate : waitingDays,
      tagsIds: messageInfo.tags.map(({ id }) => id),
      contentsIds: messageInfo.contents.map(({ id }) => id),
    })
  }, [])

  const formatMessageData = useCallback(({ arrayValues, allParams }) => {
    return arrayValues.reduce((res, { key, value, regex }) => {
      if (isNil(allParams[value]) || !regex.test(allParams[value])) {
        return res
      }

      return { ...res, [key]: allParams[value] }
    }, {})
  }, [])

  const onSubmit = async (data) => {
    const allParams = {
      messageId,
      themeId,
      messageType,
      pipelineType,
      ...data,
    }
    const formattedData = formatMessageData({ allParams, arrayValues })

    return await manageThemeMessage(formattedData)
  }

  const {
    data: message,
    isLoading: isMessageLoading,
    isError: isFetchMessageError,
    isSuccess: isFetchMessageSuccess,
  } = useQuery({
    retry: 1,
    placeholderData: keepPreviousData,
    queryKey: ['messageInfo', messageId],
    queryFn: () => getMessageInfo(messageId),
    enabled: !isEmpty(messageId),
  })

  const { data: messageConstants, isLoading: isMessageConstantsLoading } = useQuery({
    retry: 2,
    queryKey: ['messageConstants', pipelineType, messageType],
    placeholderData: keepPreviousData,
    queryFn: () =>
      getMessageVariables({
        themeId,
        messageType: messageType === MESSAGE_TYPES.REPEAT ? MESSAGE_TYPES.RELATIVE : messageType,
        pipelineType,
      }),
  })

  useEffect(() => {
    const isLoading = isMessageLoading && isMessageConstantsLoading
    setLoadingAdminProjectData(isLoading)

    return () => setLoadingAdminProjectData(false)
  }, [isMessageLoading, isMessageConstantsLoading])

  useEffect(() => {
    if (isFetchMessageSuccess && !isEmpty(messageId)) {
      resetMessageForm({ messageInfo: message.data })
    }

    if (isFetchMessageError) {
      navigate(HISTORY_BACK)
    }
  }, [isFetchMessageSuccess, isFetchMessageError, messageId])

  return (
    <div className="containerAdminMainInfo h-100">
      <FormProvider {...hookFormMethods}>
        <form
          className="messageEditor"
          onSubmit={handleSubmit((data) => onSubmit(data).then(() => navigate(HISTORY_BACK)))}
        >
          {formFields?.map((key) => {
            const RenderField = renderFormFields[key]

            return (
              <RenderField
                key={key}
                name={key}
                defaultValue={message?.data?.[key]}
                messageId={messageId}
                themeId={themeId}
                messageType={messageType}
                pipelineType={pipelineType}
                messageConstants={messageConstants?.data}
                {...hookFormMethods}
              />
            )
          })}
          <Footer message={watch('body')} messageConstants={messageConstants?.data} />
        </form>
      </FormProvider>
    </div>
  )
}

const mapDispatchToProps = {
  setLoadingAdminProjectData,
}

export default connect(null, mapDispatchToProps)(Message)
