import r from '../utils/request'

export const loginAdmin = (params) => {
  return r.postWithToast('/admin/login', params, {
    success: 'Verification code was sent to email',
    error: 'Invalid form fields',
  })
}

export const confirmAdminLogin = (params) => {
  return r.postWithToast('/admin/confirm-login', params, { success: null })
}

export const loginUser = (params, toastMessages = {}) => {
  return r.postWithToast('/login', params, toastMessages)
}

export const confirmLogin = (params) => {
  return r.postWithToast('/confirm-login', params, { success: null })
}

export const getMe = () => {
  const randomNumber = Math.floor(Math.random() * 1000)
  return r.get(`/me?${randomNumber}`)
}
