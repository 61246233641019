import { Dropdown } from 'rsuite'
import Picker from 'emoji-picker-react'

import { EmojiSmile } from './SvgIcon'

const EmojiPicker = ({ onEmojiClick, ...rest }) => {
  return (
    <Dropdown
      noCaret
      placement="bottomStart"
      toggleClassName="emojiBtn"
      title={<EmojiSmile size={16} />}
      className="dropdownEmojiPicker"
    >
      <Picker
        theme="light"
        previewConfig={{ showPreview: false }}
        lazyLoadEmojis
        skinTonesDisabled
        onEmojiClick={({ emoji }) => {
          onEmojiClick(emoji)
        }}
        {...rest}
      />
    </Dropdown>
  )
}

export default EmojiPicker
