import { MESSAGE_TYPES, PIPELINE_TYPES } from '../../../utils/constants'
import {
  changeMessage,
  createMessage,
  getMessageById,
  changeRepeatedContentMessage,
  createRepeatedContentMessages,
  getRepeatedContentMessagesById,
} from '../../../api/admin/messages'

const commonMessageFields = [
  { key: 'messageId', value: 'messageId', regex: /.*/ },
  { key: 'themeId', value: 'themeId', regex: /.*/ },
  { key: 'body', value: 'body', regex: /.*/ },
]

const relativeMessageFields = [
  ...commonMessageFields,
  { key: 'tagsIds', value: 'tagsIds', regex: /.*/ },
  { key: 'type', value: 'pipelineType', regex: /.*/ },
  { key: 'messageType', value: 'messageType', regex: /.*/ },
  { key: 'absoluteDate', value: 'waitingDays', regex: /.*/ },
]

const pipelineConfig = {
  [PIPELINE_TYPES.CONTENT]: {
    [MESSAGE_TYPES.REPEAT]: {
      getMessageInfo: getRepeatedContentMessagesById,
      createMessage: createRepeatedContentMessages,
      changeMessage: changeRepeatedContentMessage,
      arrayValues: commonMessageFields,
      formFields: ['body'],
    },
    [MESSAGE_TYPES.FOOTER]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: [
        ...commonMessageFields,
        { key: 'type', value: 'pipelineType', regex: /.*/ },
        { key: 'messageType', value: 'messageType', regex: /.*/ },
      ],
      formFields: ['body'],
    },
    [MESSAGE_TYPES.RELATIVE]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: [
        ...relativeMessageFields,
        { key: 'contents_ids', value: 'contentsIds', regex: /.*/ },
      ],
      formFields: ['body', 'tagsIds', 'contentsIds'],
    },
    [MESSAGE_TYPES.ABSOLUTE]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: [
        ...relativeMessageFields,
        { key: 'contentsIds', value: 'contentsIds', regex: /.*/ },
      ],
      formFields: ['waitingDays', 'body', 'tagsIds', 'contentsIds'],
    },
    [MESSAGE_TYPES.ONBOARDING]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: [
        ...commonMessageFields,
        { key: 'type', value: 'pipelineType', regex: /.*/ },
        { key: 'messageType', value: 'messageType', regex: /.*/ },
        { key: 'contentsIds', value: 'contentsIds', regex: /.*/ },
      ],
      formFields: ['body', 'contentsIds'],
      showAdditionalButtons: false,
    },
  },
  [PIPELINE_TYPES.ADMIN]: {
    [MESSAGE_TYPES.RELATIVE]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: relativeMessageFields,
      formFields: ['body'],
    },
    [MESSAGE_TYPES.CONTACT]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: relativeMessageFields,
      formFields: ['body'],
    },
    [MESSAGE_TYPES.ABSOLUTE]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: [
        ...relativeMessageFields,
        { key: 'contentsIds', value: 'contentsIds', regex: /.*/ },
      ],
      formFields: ['waitingDays', 'body'],
    },
  },
  [PIPELINE_TYPES.ABANDONED]: {
    [MESSAGE_TYPES.DELAYED]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: [
        ...commonMessageFields,
        { key: 'type', value: 'pipelineType', regex: /.*/ },
        { key: 'contentsIds', value: 'contentsIds', regex: /.*/ },
        { key: 'waitingDays', value: 'waitingDays', regex: /.*/ },
        { key: 'messageType', value: 'messageType', regex: /.*/ },
      ],
      formFields: ['body', 'contentsIds'],
    },
    [MESSAGE_TYPES.ONBOARDING]: {
      getMessageInfo: getMessageById,
      createMessage,
      changeMessage,
      arrayValues: [
        ...commonMessageFields,
        { key: 'type', value: 'pipelineType', regex: /.*/ },
        { key: 'contentsIds', value: 'contentsIds', regex: /.*/ },
        { key: 'messageType', value: 'messageType', regex: /.*/ },
        { key: 'onboardingDelay', value: 'onboardingDelay', regex: /.*/ },
      ],
      formFields: ['onboardingDelay', 'body', 'contentsIds'],
      showAdditionalButtons: false,
    },
  },
}

export default pipelineConfig
