import { isEmpty } from 'lodash'
import { Modal } from 'react-bootstrap'

import Button from '../../Button'
import PrimaryButton from '../../PrimaryButton'

import './styles.css'

const ModalConfirm = ({
  show,
  onHide,
  onExited,
  handleClickButton,
  size = 'sm',
  customClassName = '',
  title = 'Are you sure?',
  textButton = 'Unsubscribe',
  text = 'You want delete this theme?',
}) => {
  return (
    <Modal
      show={!isEmpty(show) || show}
      centered
      size={size}
      onHide={onHide}
      onExited={onExited}
      className={`modalConfirm ${customClassName}`}
    >
      <Modal.Header closeButton>
        <Modal.Title className="titleModalConfirm">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bodyModalConfirm">{text}</div>
      </Modal.Body>
      <Modal.Footer className="footerModalConfirm">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton onClick={() => handleClickButton(show)} text={textButton} />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm
