import { useMemo } from 'react'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import { routes } from '../../../router/routes'
import { displayAdminMenu } from '../../../redux/actions/ui'
import { FillSetting, Messenger } from '../../SvgIcon'
import IconButton from '../../IconButton'
import BackButton from '../../BackButton'

const Header = ({ numberUnreadMessages, displayAdminMenu, superAdminNumberUnreadMessages }) => {
  const { pathname } = useLocation()

  const isBackButtonVisible = useMemo(
    () =>
      [
        routes.programs,
        routes.programThemes,
        routes.theme,
        routes.themeSettings,
        routes.users,
        routes.user,
        routes.userThemeAnalytic,
        routes.message,
        routes.themeSurveys,
      ].some((route) => matchPath(route, pathname)),
    [pathname]
  )

  return (
    <div className="adminHeaderInfoSection">
      <BackButton
        disabled={!isBackButtonVisible}
        className={`me-auto ${isBackButtonVisible ? 'opacity-100' : 'visibilityHidden opacity-0'}`}
      />

      <NavLink onClick={() => displayAdminMenu(false)} to={routes.messaging}>
        <IconButton
          aria-checked={pathname === routes.messaging}
          renderTooltipContent={() => <p>Chats</p>}
          placement="autoVerticalEnd"
          buttonClassName="btnAdminHeaderInfoSection message"
        >
          <Messenger size={20} fill="#969898" />
          {numberUnreadMessages >= 1 || superAdminNumberUnreadMessages >= 1 ? (
            <span className="indicator" />
          ) : null}
        </IconButton>
      </NavLink>

      <NavLink to={routes.accountSettings}>
        <IconButton
          aria-checked={pathname === routes.accountSettings}
          renderTooltipContent={() => <p>Company Settings</p>}
          placement="autoVerticalEnd"
          buttonClassName="btnAdminHeaderInfoSection"
        >
          <FillSetting size={22} fill="#969898" />
        </IconButton>
      </NavLink>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  numberUnreadMessages: user.data.numberUnreadMessages,
  superAdminNumberUnreadMessages: user.data.superAdminNumberUnreadMessages,
})
const mapDispatchToProps = {
  displayAdminMenu,
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
