import { INPUT_TYPES, PHONE_PATTERN } from '../../../../utils/constants'
import ControllerField from '../../../../components/HookForm/components/ControllerField'

const EnterPhoneNumberStep = ({ currentStep, control }) => {
  if (currentStep < 1) {
    return null
  }

  return (
    <div className={currentStep !== 1 ? 'd-none' : ''}>
      <ControllerField
        name="phone"
        control={control}
        type={INPUT_TYPES.INPUT_MASK}
        defaultValue=""
        rules={{
          required: true,
          pattern: {
            value: PHONE_PATTERN,
            message: 'Not valid number',
          },
        }}
        label="Phone"
        placeholder="+1 (999) 999-9999"
        innerClassName="inputLogin phoneNumberInput"
        inputType="tel"
      />
      <ControllerField
        name="acceptedTermsAndConditions"
        control={control}
        type={INPUT_TYPES.CHECKBOX}
        defaultValue={false}
        rules={{
          required: true,
        }}
        className="mt-1"
        label={
          <p className="termsAndConditions">
            I agree to the <a href="">Terms of Service</a>, <a href="">Privacy Policy</a>, and to
            receive SMS messages from LiveContact. Cancel anytime. LiveContact will never sell or
            share your information.
          </p>
        }
      />
    </div>
  )
}

export default EnterPhoneNumberStep
