import { useCallback } from 'react'
import { Whisper, Table, Popover, Dropdown } from 'rsuite'

import { ThreeDots } from '../../SvgIcon'
import Button from '../../Button'

const renderMenu = ({ onClose, left, actions, rowData, top, className }, ref) => {
  const handleSelect = useCallback(
    (eventKey) => {
      const functionEvent = actions.find((item) => item.key === eventKey)?.function
      functionEvent?.(rowData)
      onClose()
    },
    [actions]
  )

  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu onSelect={handleSelect}>
        {actions?.map((item, index) => (
          <Dropdown.Item key={index} eventKey={item.key}>
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Popover>
  )
}

const ActionCell = ({ rowData, actions, iconSize = 18, ...props }) => {
  return (
    <Table.Cell
      {...props}
      onClick={(event) => {
        event?.stopPropagation()
        props?.onClick?.(event)
      }}
    >
      <Whisper
        placement="auto"
        trigger="click"
        speaker={(props, ref) => renderMenu({ ...props, actions, rowData }, ref)}
      >
        <Button customClass="p-1">
          <ThreeDots
            className="cursorPointer threeDotsHorizontal"
            fill="var(--catalina-blue)"
            size={iconSize}
          />
        </Button>
      </Whisper>
    </Table.Cell>
  )
}

export default ActionCell
