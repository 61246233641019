import { handleActions } from 'redux-actions'

import { ROLES } from '../../utils/constants'
import {
  setUser,
  setRoleUser,
  setNewStatus,
  setNewTimezone,
  setNewDataUser,
  setNewPhoneNumber,
  setWebsocketConnected,
  setNewEnabledProviders,
  toggleManualBlockingStatus,
  setAdminNumberUnreadMessages,
  setSuperAdminNumberUnreadMessages,
} from '../actions/user'

const initialState = {
  data: null,
  role: ROLES.ROLE_GUEST,
  isConnectedWebsocket: false,
}

const actions = {
  [setUser]: (state, { payload }) => {
    return {
      ...state,
      data: payload,
      role: payload?.role,
    }
  },
  [setRoleUser]: (state, { payload }) => {
    return {
      ...state,
      role: payload,
    }
  },
  [setNewTimezone]: (state, { payload }) => {
    return {
      ...state,
      data: { ...state.data, ...payload },
    }
  },
  [setNewPhoneNumber]: (state, { payload }) => {
    return {
      ...state,
      data: { ...state.data, phone: payload },
    }
  },
  [setNewStatus]: (state, { payload }) => {
    return {
      ...state,
      data: { ...state.data, status: payload },
    }
  },
  [setNewDataUser]: (state, { payload }) => {
    return {
      ...state,
      data: { ...state.data, ...payload },
    }
  },
  [setNewEnabledProviders]: (state, { payload }) => {
    return {
      ...state,
      data: { ...state.data, tokenProviders: { ...state.data.tokenProviders, ...payload } },
    }
  },
  [toggleManualBlockingStatus]: (state) => {
    return {
      ...state,
      data: { ...state.data, hasBlockedManually: !state.data.hasBlockedManually },
    }
  },
  [setAdminNumberUnreadMessages]: (state, { payload }) => {
    return {
      ...state,
      data: { ...state.data, numberUnreadMessages: payload },
    }
  },
  [setSuperAdminNumberUnreadMessages]: (state, { payload }) => {
    return {
      ...state,
      data: { ...state.data, superAdminNumberUnreadMessages: payload },
    }
  },
  [setWebsocketConnected]: (state, { payload }) => {
    return {
      ...state,
      isConnectedWebsocket: payload,
    }
  },
}

const createReducer = (data) => {
  const { role = ROLES.ROLE_GUEST, ...userData } = data ?? {}

  return handleActions(actions, {
    ...initialState,
    role,
    data: userData,
  })
}

export default createReducer
