import { useState } from 'react'

import { Cross, Plus } from '../../../../../components/SvgIcon'
import ModalSelectThemes from '../../../../../components/Modals/ModalSelectTheme'

import './styles.css'
import Button from '../../../../../components/Button'

const TabSettings = ({ isVisible }) => {
  const [showModalSelectThemes, setShowModalSelectThemes] = useState(false)

  return (
    <div className={`flex-grow-1 mt-3 tabBlock ${isVisible ? 'visibleTabBlock' : ''}`}>
      <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
        <p className="blockTitle sm mb-0">Attached themes</p>
        <Button
          customClass="d-flex align-items-center gap-2"
          onClick={() => setShowModalSelectThemes(true)}
        >
          <Plus size={18} fill="var(--catalina-blue)" />
          Add Theme
        </Button>
      </div>
      <p>/ show when themes list is empty /</p>
      <div className="innerEmptyBlock">
        <p className="blockDescription">No themes</p>
      </div>
      <p>/ show when group has one or moore attached themes /</p>
      <div className="themeList">
        <div className="attachedTheme">
          Meditation
          <Cross className="cursorPointer" size={16} fill="var(--persian-red)" />
        </div>
        <div className="attachedTheme">
          Breathing
          <Cross className="cursorPointer" size={16} fill="var(--persian-red)" />
        </div>
        <div className="attachedTheme">
          Football news
          <Cross className="cursorPointer" size={16} fill="var(--persian-red)" />
        </div>
      </div>
      <ModalSelectThemes
        title="Attache themes"
        show={showModalSelectThemes}
        onHide={() => setShowModalSelectThemes(false)}
      />
    </div>
  )
}

export default TabSettings
