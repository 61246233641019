import { useCallback, useEffect, useMemo, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toNumber } from 'lodash'
import { Dropdown } from 'rsuite'

import { routes } from '../../../router/routes'
import { PRICE_TYPES_SETTINGS, THEME_SETTINGS_TABS } from '../../../utils/constants'
import { getThemeDetails } from '../../../api/admin/themes'
import { setLoadingAdminProjectData } from '../../../redux/actions/ui'
import {
  Settings,
  Copy,
  Analytics,
  ThreeDots,
  MessageAltDetail,
  Contact as ContactIcon,
} from '../../../components/SvgIcon'
import Button from '../../../components/Button'
import IconButton from '../../../components/IconButton'
import ModalUpdateTheme from '../../../components/Modals/ModalTheme'
import ModalContact from '../../../components/Modals/ModalContact'
import ModalDuplicateTheme from '../../../components/Modals/ModalDuplicateTheme'
import ModalHardcodeMessage from '../../../components/Modals/ModalHardcodeMessage'
import General from './tabs/General'
import Contact from './tabs/Contact'
import ToggleActivateTheme from './components/ToggleActivateTheme'

import './styles.css'

const ThemeSettings = ({ currentCompanyPermissions, setLoadingAdminProjectData }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [showModalDuplicate, setShowModalDuplicate] = useState(false)
  const [showModalUpdateTheme, setShowModalUpdateTheme] = useState(false)
  const [showHardcodeMessages, setShowHardcodeMessages] = useState(false)
  const [showModalContact, setShowModalContact] = useState(false)
  const navigation = useNavigate()
  const { themeId } = useParams()
  const queryClient = useQueryClient()

  const tabs = useMemo(
    () =>
      THEME_SETTINGS_TABS.filter((tab) =>
        tab.requiredPermissions.every((perm) => currentCompanyPermissions[perm])
      ),
    [currentCompanyPermissions]
  )

  const navigateToThemeAnalytic = useCallback(() => {
    const searchParams = { filterData: JSON.stringify({ themeId: toNumber(themeId) }) }
    navigation({
      pathname: routes.dashboard,
      search: '?' + createSearchParams(searchParams).toString(),
    })
  }, [themeId])

  const handleSuccessUpdate = useCallback((newData) => {
    queryClient.setQueryData(['themeInfo', themeId], ({ data }) => {
      return {
        data: {
          ...data,
          name: newData.name,
          activePrice: {
            ...data.activePrice,
            price: newData.priceAmount,
            type: newData.priceType,
            extensionDays: newData.priceExtensionDays,
          },
        },
      }
    })
    setShowModalUpdateTheme(false)
  }, [])

  const { data: theme, isLoading: isLoadingThemeData } = useQuery({
    queryKey: ['themeInfo', themeId],
    queryFn: () => getThemeDetails(themeId),
  })
  const price = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(theme?.data?.activePrice?.price || ''),
    [theme]
  )

  useEffect(() => {
    setLoadingAdminProjectData(isLoadingThemeData)
  }, [isLoadingThemeData])

  return (
    <div className="themeSettings containerAdminMainInfo">
      <div className="d-flex gap-4 align-items-center justify-content-between mb-3">
        <div className="d-flex gap-3 align-items-center">
          <div className="iconSettings">
            <Settings size={25} />
          </div>
          <div>
            <h1 className="titleAdminPage mb-1 fw-bold">{theme?.data?.name}</h1>
            {currentCompanyPermissions?.setupPaidPrograms && (
              <p className="subscriptionPrice">
                {price} USD •{' '}
                <span>{PRICE_TYPES_SETTINGS[theme?.data?.activePrice?.type]?.label}</span>
              </p>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <ToggleActivateTheme
            isPublished={theme?.data?.isPublished}
            priceInfo={theme?.data?.activePrice?.price}
          />
          <Button onClick={() => setShowModalUpdateTheme(true)} customClass="border-1">
            Edit theme
          </Button>
          <Dropdown
            renderToggle={(props, ref) => (
              <IconButton
                renderTooltipContent={() => <p>Settings</p>}
                placement="autoVerticalEnd"
                buttonClassName="border-1 btnThemeSettingsDropdownMenu defaultButton"
                ref={ref}
                disabledTooltip
                {...props}
              >
                <ThreeDots className="threeDotsHorizontal" size={18} fill="var(--catalina-blue)" />
              </IconButton>
            )}
            placement="bottomEnd"
          >
            <Dropdown.Item
              icon={<Copy size={17} fill="#969898" />}
              className="d-flex align-items-center gap-2"
              onClick={() => setShowModalDuplicate(true)}
            >
              Duplicate
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center gap-2"
              onClick={navigateToThemeAnalytic}
              icon={<Analytics size={20} color="#969898" />}
            >
              Statistics
            </Dropdown.Item>
            {currentCompanyPermissions?.useContent && (
              <Dropdown.Item
                className="d-flex align-items-center gap-2"
                onClick={() => setShowHardcodeMessages(true)}
                icon={<MessageAltDetail size={17} fill="#969898" />}
              >
                Hardcoded Messages
              </Dropdown.Item>
            )}
            {currentCompanyPermissions?.setupProgramContactMessage && (
              <Dropdown.Item
                className="d-flex align-items-center gap-2"
                onClick={() => setShowModalContact(true)}
                icon={<ContactIcon size={17} fill="#969898" />}
              >
                Contact
              </Dropdown.Item>
            )}
          </Dropdown>
        </div>
      </div>

      <div
        className={`d-flex align-items-center tabsList bottomLine mb-4 ${
          tabs.length <= 1 ? 'd-none' : ''
        }`}
      >
        {tabs.map(({ name }, index) => (
          <button
            key={name}
            onClick={() => setActiveTab(index)}
            className={`tab fullLine ${index === activeTab ? 'active cursorDefault' : ''}`}
          >
            {name}
          </button>
        ))}
      </div>
      <General isActiveTab={activeTab === 0} themeData={theme?.data} />
      <Contact isActiveTab={activeTab === 1} themeData={theme?.data} />
      <ModalHardcodeMessage
        show={showHardcodeMessages}
        onHide={() => setShowHardcodeMessages(false)}
      />
      <ModalDuplicateTheme
        show={showModalDuplicate}
        onHide={() => setShowModalDuplicate(false)}
        {...theme?.data}
      />
      <ModalUpdateTheme
        themeId={themeId}
        title="Update theme"
        show={showModalUpdateTheme}
        defaultValues={{
          name: theme?.data?.name,
          priceType: theme?.data?.activePrice?.type,
          priceAmount: theme?.data?.activePrice?.price,
          priceExtensionDays: theme?.data?.activePrice?.extensionDays,
        }}
        onSuccessUpdate={(_, newData) => handleSuccessUpdate(newData)}
        onHide={() => setShowModalUpdateTheme(false)}
      />
      <ModalContact
        themeId={themeId}
        onHide={() => setShowModalContact(false)}
        show={showModalContact}
      />
    </div>
  )
}
const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})
const mapDispatchToProps = { setLoadingAdminProjectData }

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSettings)
