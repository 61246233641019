import { useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { Modal } from 'rsuite'

import { TAG_FIELD } from '../../../utils/constantsForms'
import { createTag, updateTag } from '../../../api/admin/tags'
import { IconTags } from '../../SvgIcon'
import PrimaryButton from '../../PrimaryButton'
import HookForm from '../../HookForm'
import Button from '../../Button'

const ModalManageTag = ({ show, onHide, updateQueryKey }) => {
  const formRef = useRef(null)
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const editableTagId = searchParams.get('id')
  const tagColor = searchParams.get('color')
  const tagName = searchParams.get('name')
  const tagIsVisibleToUser = searchParams.get('isVisibleToUser')
  const isEditMode = useMemo(() => !isNil(editableTagId), [editableTagId, show])

  const mutationUpdateTag = useMutation({
    mutationKey: ['updateTagInfo', editableTagId],
    mutationFn: updateTag,
    onSuccess: ({ data: newTagInfo }) => {
      queryClient.setQueryData(updateQueryKey, ({ data: oldInfo }) => {
        const newData = oldInfo?.data.map((tagInfo) =>
          tagInfo?.id === newTagInfo.id ? newTagInfo : tagInfo
        )
        onHide()
        return { data: { ...oldInfo, data: newData } }
      })
    },
  })
  const mutationCreateTag = useMutation({
    mutationKey: ['createTag'],
    mutationFn: createTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] })
      onHide()
    },
  })

  const submitFunction = ({ tagInfo }) => {
    isEditMode
      ? mutationUpdateTag.mutate({ ...tagInfo, tagId: editableTagId })
      : mutationCreateTag.mutate(tagInfo)
  }

  useEffect(() => {
    if (isEditMode) {
      formRef.current?.reset({
        tagInfo: { name: tagName, color: tagColor, isVisibleToUser: tagIsVisibleToUser },
      })
    }
  }, [isEditMode, show])

  return (
    <Modal open={show} onExited={() => setSearchParams({})} onClose={onHide}>
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex align-items-center gap-2">
            <IconTags size={20} fill="var(--catalina-blue)" />
            {isEditMode ? 'Manage Tag' : 'Create Tag'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ps-2 pe-2">
        <HookForm
          ref={formRef}
          onSubmit={submitFunction}
          className="formTag"
          fields={[TAG_FIELD]}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center gap-2 justify-content-end mt-1">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton
          text="Save"
          onClick={() => formRef.current?.submitHandler()}
          disabled={mutationUpdateTag.isPending || mutationCreateTag.isPending}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalManageTag
