import ImageLoader from '../ImageLoader'

import './styles.css'

const LayoutGuest = ({ children }) => {
  return (
    <div className="layoutGuest userPages">
      <header className="guestHeader container">
        <ImageLoader
          alt="Logo"
          loadingProps={{ size: 13 }}
          src="/images/logo.png"
          imageClassName="logo object-fit-contain"
          innerClassName="logo"
        />
      </header>
      {children}
      <div className="bg">
        <div className="bgFirst" />
        <div className="bgFirstLeftThinkShare" />
        <div className="bgSecondLeftThinkShare" />
        <div className="bgFirstRightThinkShare" />
      </div>
    </div>
  )
}

export default LayoutGuest
