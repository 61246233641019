import { useState } from 'react'
import { Drawer } from 'rsuite'

import Button from '../../Button'
import Pagination from '../../Pagination'
import CustomInput from '../../CustomInput'
import PrimaryButton from '../../PrimaryButton'
import UserRow from './components/UserRow'

import './styles.css'

const ModalGroupUser = ({ show, onHide }) => {
  const [selectedUsers, setSelectedUsers] = useState([])

  const toggleUserSelect = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId))
    } else {
      setSelectedUsers([...selectedUsers, userId])
    }
  }

  return (
    <Drawer placement="right" className="modalGroupUsers" open={show} onClose={onHide}>
      <Drawer.Header closeButton>
        <Drawer.Title className="blockTitle mb-0 mt-1">Add Members</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="p-3 position-relative">
        <CustomInput placeholder="Search by name, phone, email" />

        <div className="usersList">
          <UserRow
            toggleUserSelect={toggleUserSelect}
            userId={1}
            isSelected={selectedUsers.includes(1)}
            phone="+13013584770"
            email="fasulyak03@gmail.com"
          />
          <UserRow
            toggleUserSelect={toggleUserSelect}
            userId={2}
            isSelected={selectedUsers.includes(2)}
            phone="+13013584770"
            email="fasulyak03@gmail.com"
          />
          <UserRow
            toggleUserSelect={toggleUserSelect}
            userId={3}
            isSelected={selectedUsers.includes(3)}
            phone="+13013584770"
            email="fasulyak03@gmail.com"
          />
          {/*{isFetchingContent && (*/}
          {/*<div className="innerLoader">*/}
          {/*  <LoadingIcon size={50} className="loaderIcon" />*/}
          {/*</div>*/}
          {/*)}*/}
        </div>

        <Pagination innerClassName="usersPagination" show total={120} lastPage={10} forcePage={1} />
      </Drawer.Body>
      <Drawer.Actions className="drawerActionModalFooter">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton text="Add Users" />
      </Drawer.Actions>
    </Drawer>
  )
}

export default ModalGroupUser
