import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { connect } from 'react-redux'

import { getThemeContent } from '../../../../api/admin/themeContent'
import { updateThemeInfo } from '../../../../api/admin/themeSettings'
import CustomDropdown from '../../../../components/CustomDropdown'

const EmergencyContentPicker = ({
  themeType,
  defaultEmergencyContentId,
  currentCompanyPermissions,
}) => {
  const { themeId } = useParams()
  const queryClient = useQueryClient()

  const mutationUpdateEmergencyContent = useMutation({
    mutationKey: ['updateEmergencyContent', themeId],
    mutationFn: updateThemeInfo,
    onSuccess: (_, { emergencyContentId, themeId }) => {
      queryClient.setQueryData(['themeInfo', themeId], ({ data }) => {
        return {
          data: { ...data, emergencyContentId },
        }
      })
    },
  })

  const { data: themeContents } = useQuery({
    queryKey: ['themeContent', themeId],
    queryFn: () => getThemeContent(themeId),
    enabled: Boolean(currentCompanyPermissions?.useContent),
  })

  if (!currentCompanyPermissions?.useContent) {
    return null
  }

  return (
    <div className="d-flex align-items-center gap-4 mb-5 justify-content-between underline pb-2">
      <div className="innerBlockTextInfo mw-600">
        <p className="blockTitle mb-2">Emergency content</p>
        <p className="blockDescription">
          Specifies content that is sent when a user sends the command "favorite" and they don't
          have any content to select from
        </p>
      </div>
      <CustomDropdown
        title="Emergency content"
        titleKey="subtitle"
        valueKey="id"
        titleClass="emergencyContentTitle"
        value={defaultEmergencyContentId}
        onChange={(id) => {
          return mutationUpdateEmergencyContent.mutate({
            priceType: themeType,
            themeId,
            emergencyContentId: id,
          })
        }}
        data={themeContents?.data}
      />
    </div>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})

export default connect(mapStateToProps)(EmergencyContentPicker)
