import { useMemo } from 'react'
import { connect } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { NavLink, useNavigate } from 'react-router-dom'

import { routes } from '../../../../router/routes'
import { switchAccountCompany } from '../../../../api/user/company'
import { toggleDisplayUserMenu } from '../../../../redux/actions/ui'
import { updateToken } from '../../../../utils/auth'
import { IconProfile } from '../../../SvgIcon'
import OrganizationSwitcher from '../../../OrganizationSwitcher'

import './styles.css'

const Header = ({ firstName, lastName, toggleDisplayUserMenu, listCompanies, currentCompany }) => {
  const navigation = useNavigate()
  const userName = useMemo(
    () => `${firstName || ''} ${lastName || ''}`.trim(),
    [firstName, lastName]
  )

  const mutationSwitchAdminCompany = useMutation({
    mutationKey: ['switchAdminCompany'],
    mutationFn: switchAccountCompany,
    onSuccess: async ({ data }) => {
      await updateToken(data?.newToken)
      navigation(routes.userHome)
      setTimeout(() => window.location.reload(), 0)
    },
  })

  return (
    <header className="header">
      <button onClick={() => toggleDisplayUserMenu()} className="btnToggleMenu">
        <span />
        <span />
        <span />
      </button>
      <OrganizationSwitcher
        orgList={listCompanies}
        selectedOrg={currentCompany}
        innerClassName="userOrgSwitcher"
        onSwitchOrg={mutationSwitchAdminCompany.mutate}
      />
      <NavLink to={routes.accountInformation} className="innerHeaderUserName">
        <IconProfile size={30} fill="var(--swatch--cta-blue)" />
        <span>{userName}</span>
      </NavLink>
    </header>
  )
}

const mapStateToProps = ({ user, currentCompany }) => ({
  firstName: user.data.firstName,
  lastName: user.data.lastName,
  currentCompany,
})
const mapDispatchToProps = {
  toggleDisplayUserMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
