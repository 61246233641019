import './styles.css'

const Shop = () => {
  return (
    <div className="innerComingSoon">
      <img className="w-25 m-auto d-block" src="/images/smallLogo.png" alt="logo" />
      <p className="comingSoonText">Store Coming Soon</p>
      <p className="comingSoonSupText">We are currently working on this page</p>
    </div>
  )
}

export default Shop
