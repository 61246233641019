import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { map } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import TagManager from 'react-gtm-module'
import { Elements } from '@stripe/react-stripe-js'

import {
  ROLES,
  DELIVERY_METHODS,
  SUBSCRIPTION_STATUSES,
  APPEARANCE_STRIPE_FORM_REGISTRATION,
  REGISTRATION_GTM_EVENTS,
  REGISTRATION_STEPS,
} from '../../../utils/constants'
import { routes } from '../../../router/routes'
import { getMe } from '../../../api/auth'
import {
  confirmSubscription,
  getThemeConfigs,
  sentConfirmationCode,
} from '../../../api/registration'
import { updateToken } from '../../../utils/auth'
import { setNewDataUser, setRoleUser } from '../../../redux/actions/user'
import { camelize, formatPhoneNumber } from '../../../utils/helpers'
import {
  configDeliveryConfigs,
  determineDeliveryMethod,
} from '../../../utils/functions/deliveryConfigs'
import { updateThemeDeliveryConfigsWithToast } from '../../../api/user/theme'
import { getCurrentTimeZone } from '../../../utils/functions/time'
import { shouldSubscribe } from '../../../utils/functions/registration'
import RegistrationSteps from '../../../components/RegistrationSteps'
import Spinner from '../../../components/Spinner'
import FormFooter from './components/FormFooter'
import EnterPhoneNumberStep from './components/EnterPhoneNumberStep'
import PaymentDetailsStep from './components/PaymentDetailsStep'
import ConfirmStep from './components/ConfirmStep'
import DeliveryPreferences from './components/DeliveryPreferences'
import SuccessScreen from './components/SuccessScreen'

import './styles.css'

const RegistrationUser = ({ userRole }) => {
  const [isLoadingForm, setIsLoadingForm] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)
  const { control, handleSubmit, watch, setValue, getValues, resetField, formState } = useForm({
    defaultValues: {
      deliveryMethod: DELIVERY_METHODS.DAILY,
      promotionCode: '',
      firstName: '',
      lastName: '',
      deliveryTime: '21:00',
      timezone: getCurrentTimeZone(),
    },
  })
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)

  const innerFormRegistrationRef = useRef(null)
  const multiStepFormRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  const [searchParams] = useSearchParams()
  const registrationToken = searchParams.get('encrypted_registration_token')

  const { title: formTitle } = useMemo(
    () => REGISTRATION_STEPS.find(({ step }) => currentStep === step),
    [currentStep]
  )

  const handleSentConfirmCode = async (data, nextStep) => {
    const { subscriptionStatus, token } = data

    map(data, (value, key) => setValue(key.toString(), value))

    if (token) updateToken(token)

    const promotionCode = data?.price?.name
    if (promotionCode) {
      setValue('promotionCode', promotionCode, { shouldDirty: false })
    }

    let userData = {}
    const shouldFetchUserData = token && subscriptionStatus
    if (shouldFetchUserData) {
      try {
        const { data: fetchedUserData } = await getMe()
        userData = fetchedUserData

        map(userData, (value, key) => setValue(key.toString(), value))
        dispatch(setNewDataUser(userData))
        dispatch(setRoleUser(userData?.role))
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }

    const isInactiveSubscription = shouldSubscribe(subscriptionStatus)

    if (subscriptionStatus && !isInactiveSubscription && currentStep === 1) {
      const redirectUrl =
        userRole === ROLES.ROLE_USER
          ? `/account-home/theme-${getValues('themeId')}`
          : `${routes.userLogin}?phone=${formatPhoneNumber(watch('phone'))}`
      navigate(redirectUrl)
      return
    }

    if (subscriptionStatus === SUBSCRIPTION_STATUSES.ABANDONED_CART) {
      setCurrentStep(3)
      return
    }

    setCurrentStep(nextStep)
  }

  const displaySuccessScreen = () => {
    innerFormRegistrationRef.current.style.display = 'none'
    setShowSuccessScreen(true)
  }

  const mutationSentConfirmationCode = useMutation({
    mutationKey: ['sentConfirmationCode'],
    mutationFn: sentConfirmationCode,
    onSuccess: ({ data }, formData) => {
      if (typeof data === 'boolean' && data) {
        TagManager.dataLayer({
          dataLayer: {
            event: REGISTRATION_GTM_EVENTS.PHONE_INPUT_STARTED,
            themeId: formData.themeId,
            phone_number: formData.phone,
          },
        })
      }

      handleSentConfirmCode(data, 2)
      setInitialLoading(false)
    },
    onError: () => {
      navigate(routes.userLogin)
    },
  })
  const mutationConfirmSubscription = useMutation({
    mutationKey: ['confirmSubscription'],
    mutationFn: ({ confirmationCode, timezone, ...data }) =>
      confirmSubscription({
        ...data,
        code: confirmationCode,
        timezoneName: timezone?.name,
        timezoneOffset: timezone?.timezoneOffset,
      }),
    onSuccess: ({ data }, formData) => {
      TagManager.dataLayer({
        dataLayer: {
          event: REGISTRATION_GTM_EVENTS.PHONE_CONFIRMATION,
          themeId: formData.themeId,
          phone_number: formData.phone,
        },
      })
      return handleSentConfirmCode(data, 3)
    },
  })

  const mutationUpdateThemeDeliveryConfigs = useMutation({
    mutationKey: ['updateThemeDeliveryConfigs'],
    mutationFn: (data) =>
      updateThemeDeliveryConfigsWithToast(data, { success: 'Meditation time saved' }),
    onSuccess: () => {
      setCurrentStep(4)
    },
  })
  const multipleLoading =
    isLoadingForm || mutationSentConfirmationCode.isPending || mutationConfirmSubscription.isPending
  const handleSubmitForm = async (data) => {
    switch (currentStep) {
      case 1:
        await mutationSentConfirmationCode.mutate(data)
        break
      case 2:
        await mutationConfirmSubscription.mutate(data)
        break
      case 3: {
        const { method, configs } = configDeliveryConfigs(data.deliveryMethod, data.configs)
        mutationUpdateThemeDeliveryConfigs.mutate({ type: method, configs, themeId: data.themeId })
        setIsLoadingForm(true)
        break
      }
      default:
        return null
    }
  }

  const initRegistrationPage = async () => {
    setInitialLoading(true)
    try {
      if (!registrationToken) throw new Error('Missing Registration token')
      const decryptedData = camelize(JSON.parse(atob(registrationToken)))
      if (!decryptedData.themeId) throw new Error('Missing Theme ID')

      const { data: themeData } = await getThemeConfigs(decryptedData.themeId)
      const { method: deliveryMethod, configs } = determineDeliveryMethod(
        themeData.type,
        themeData.configs
      )

      map({ ...decryptedData, deliveryMethod, configs, themeData }, (value, key) =>
        setValue(key.toString(), value)
      )
      setValue('phone', formatPhoneNumber(decryptedData.phone), { shouldDirty: true })

      if (decryptedData?.acceptedTermsAndConditions) {
        return mutationSentConfirmationCode.mutate(watch())
      }
      setInitialLoading(false)
    } catch (error) {
      navigate(routes.userLogin)
    }
  }

  useEffect(() => {
    if (currentStep === 1) {
      initRegistrationPage()
    }
  }, [registrationToken])

  useEffect(() => {
    if (currentStep === 4) {
      multiStepFormRef.current.style.display = 'none'
    } else {
      multiStepFormRef.current.style.display = 'block'
    }
  }, [currentStep])

  return (
    <>
      <div className="registration userPages">
        <header className="registrationHeader">
          <h1 className="titleRegistration">Register</h1>
          <p className="titleDescriptionRegistration">
            Follow the simple 3 steps to access <b>{watch('themeData.themeName')}</b>
          </p>
        </header>
        <section ref={innerFormRegistrationRef} className="innerRegistrationFlow">
          <RegistrationSteps currentStep={currentStep} />
          <div className="innerForms">
            <form ref={multiStepFormRef} onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="innerHeaderFormRegistration">
                <p className="titleForm">{formTitle}</p>
              </div>
              <div className="innerStepsRegistration">
                <EnterPhoneNumberStep currentStep={currentStep} control={control} />
                <ConfirmStep
                  control={control}
                  currentStep={currentStep}
                  onSuccess={() => mutationConfirmSubscription.mutate(watch())}
                />
                <DeliveryPreferences
                  currentStep={currentStep}
                  control={control}
                  updateFormValue={setValue}
                  trackDeliveryMethod={watch('deliveryMethod')}
                />
              </div>
              <FormFooter
                currentStep={currentStep}
                isLoading={multipleLoading}
                onClickBack={() => setCurrentStep(currentStep - 1)}
              />
            </form>
            {watch('clientSecret') && currentStep === 4 ? (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: watch('clientSecret'),
                  appearance: APPEARANCE_STRIPE_FORM_REGISTRATION,
                }}
              >
                <PaymentDetailsStep
                  control={control}
                  themeId={watch('themeId')}
                  getFormValues={watch}
                  formState={formState}
                  currentStep={currentStep}
                  isLoading={multipleLoading}
                  isLoadingForm={isLoadingForm}
                  updateCurrentStep={setCurrentStep}
                  changeLoadingFormState={setIsLoadingForm}
                  updateFormValueState={resetField}
                  setFormValue={setValue}
                  onSuccess={displaySuccessScreen}
                  getCouponValue={() => getValues('promotionCode')}
                />
              </Elements>
            ) : null}
          </div>
        </section>
        {showSuccessScreen && (
          <SuccessScreen themeName={watch('themeData.themeName')} themeId={watch('themeId')} />
        )}
      </div>
      <Spinner show={initialLoading} />
    </>
  )
}

export default connect(({ user }) => ({ userRole: user.role }))(RegistrationUser)
