import { useMemo } from 'react'

const Status = ({ settingsStatus, status }) => {
  const { label, mainColor } = useMemo(
    () => settingsStatus?.[status] ?? {},
    [status, settingsStatus]
  )

  return (
    <div style={{ backgroundColor: mainColor }} className="statusCode">
      {label}
    </div>
  )
}

export default Status
