import {
  ERROR_CODE_FORBIDDEN,
  ERROR_CODE_NOT_FOUND,
  ERROR_CODE_SERVER_ERROR,
  ERROR_CODE_THROTTLING,
  ERROR_CODE_VALIDATION,
} from '../../constants'

const getConfirmationCodeErrorMessage = ({ status }) => {
  switch (status) {
    case ERROR_CODE_THROTTLING:
      return 'Too many requests!'
    case ERROR_CODE_NOT_FOUND:
      return 'User not found!'
    case ERROR_CODE_VALIDATION:
      return 'Invalid phone number'
    case ERROR_CODE_FORBIDDEN:
      return 'You have stopped all SMS messages from us. In order to logic, please text START to the number you received the message from.'
    default:
      return status >= ERROR_CODE_SERVER_ERROR ? 'Server error!' : 'Unexpected error'
  }
}

export const loginToastMessages = {
  success: 'Verification code was sent to your phone',
  pending: 'Sending code...',
  error: {
    render({ data }) {
      return getConfirmationCodeErrorMessage(data?.response)
    },
  },
}
