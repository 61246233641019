import Mask from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { PHONE_MASK } from '../utils/constants'
import CustomInput from './CustomInput'

const InputMask = ({ isNumberMask = false, defaultMaskOptions, mask = PHONE_MASK, ...rest }) => {
  const generateNumberMask = createNumberMask(defaultMaskOptions)

  return (
    <Mask
      guide={false}
      mask={isNumberMask ? generateNumberMask : mask}
      {...rest}
      render={(ref, props) => <CustomInput {...props} inputRef={ref} />}
    />
  )
}

export default InputMask
